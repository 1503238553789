import { FC, useCallback, useEffect } from 'react';
import { SnackBarProps, useUtilities } from '@faxi/web-component-library';
import { Outlet } from 'react-router-dom';

import Sidebar from '../Sidebar';
import authBus from '../../modules/authBus';

import { StyledMainLayout } from './MainLayout.styled';

const MainLayout: FC = () => {
  const { showSnackBar } = useUtilities();

  const handleShowSnackBar = useCallback(
    (payload: {
      props: SnackBarProps;
      config?: {
        constant?: boolean;
        disappearAfter?: number;
      };
    }) => {
      showSnackBar(payload.props, payload.config);
    },
    [showSnackBar]
  );

  useEffect(() => {
    authBus.addEventListener('show_snackbar', handleShowSnackBar);

    return () => {
      authBus.removeEventListener('show_snackbar', handleShowSnackBar);
    };
  }, [handleShowSnackBar]);

  return (
    <StyledMainLayout>
      <Sidebar />
      <Outlet />
    </StyledMainLayout>
  );
};

export default MainLayout;
