import {
  Breadcrumbs,
  Button,
  Divider,
  Heading,
} from '@faxi/web-component-library';
import { useGetSessionTree } from 'api/hooks/sessions';
import { ApprovalProgressBar, ErrorFallback, SearchTreeView } from 'components';
import Icon from 'components/Icon';
import { APP_URI } from 'config';
import dayjs from 'dayjs';
import { BlockUI } from 'helpers';
import { SessionsPageParams } from 'models/Session';
import { FC, PropsWithChildren, useMemo } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { useSelectedSession } from '../../SessionLayout';
import { StyledSessionSidebar } from './SessionSidebar.styled';
import SessionSidebarLink, {
  SessionSidebarLinkProps,
} from './components/SessionSidebarLink/SessionSidebarLink.component';
import { SessionTreeNodeElement, mapAPISessionToTreeNode } from './utils';

export type SessionSidebarProps = PropsWithChildren<{}>;

const SESSION_SIDEBAR_LINKS: SessionSidebarLinkProps[] = [
  {
    url: APP_URI.SESSION_DASHBOARD,
    text: 'Dashboard',
    leftIcon: 'grid',
  },
  {
    url: APP_URI.SESSION_REPORT,
    text: 'Report',
    leftIcon: 'file-regular',
    rightIcon: 'chevron-right',
  },
];

const SessionSidebar: FC<SessionSidebarProps> = (props) => {
  const selectedSession = useSelectedSession();

  const { deadline, startedAt } = selectedSession || {};

  const location = useLocation();

  const { sessionId = '' } = useParams<SessionsPageParams>();

  const { sessionTreeWithOrganisationRoot, isLoading, error } =
    useGetSessionTree(sessionId);

  const isReportSelected = location.pathname.includes(APP_URI.SESSION_REPORT);

  const formattedDeadline = useMemo(() => {
    if (deadline) {
      return dayjs(deadline).format('DD - MM - YYYY');
    }
  }, [deadline]);

  const formattedSessionStartDate = useMemo(() => {
    if (startedAt) {
      return dayjs(startedAt).format('DD - MM - YYYY');
    }
  }, [startedAt]);

  const tree = useMemo(() => {
    if (!error && sessionTreeWithOrganisationRoot) {
      const basePath = `/sessions/${sessionId}/report`;

      return mapAPISessionToTreeNode(
        sessionTreeWithOrganisationRoot as unknown as SessionTreeNodeElement,
        basePath,
        location
      );
    }
    return undefined;
  }, [error, sessionTreeWithOrganisationRoot, sessionId, location]);

  return (
    <StyledSessionSidebar
      className="esg-session-sidebar"
      expandDirection="right"
    >
      <div className="esg-session-sidebar__top">
        <Breadcrumbs
          splitter="/"
          crumbs={[
            { text: 'Sessions', href: APP_URI.SESSIONS },
            { text: selectedSession!.name, href: location.pathname },
          ]}
        />

        <Heading level="1">{selectedSession!.name}</Heading>
        <Divider />
        <BlockUI
          loading={isLoading}
          fallbackCondition={!!error}
          fallback={<ErrorFallback />}
        >
          {!isReportSelected ? (
            <>
              <div className="esg-session-sidebar__top__details">
                {formattedSessionStartDate && (
                  <div className="esg-session-sidebar__top__details__date">
                    <p>Session started</p>
                    <p>{formattedSessionStartDate}</p>
                  </div>
                )}
                {formattedDeadline && (
                  <div className="esg-session-sidebar__top__details__date">
                    <p>Deadline</p>
                    <p>{formattedDeadline}</p>
                  </div>
                )}
                <ApprovalProgressBar
                  title="Progress"
                  {...selectedSession!.progress}
                />
                <ApprovalProgressBar
                  title="Approval"
                  {...selectedSession!.approval}
                />
              </div>
              <Divider />

              <p className="esg-session-sidebar__top__subtitle">Browser</p>

              <div className="esg-session-sidebar__top__links">
                {SESSION_SIDEBAR_LINKS.map((link) => (
                  <SessionSidebarLink key={link.url} {...link} />
                ))}
              </div>
            </>
          ) : (
            <BlockUI loading={isLoading}>
              <div className="esg-session-sidebar__top__report">
                <NavLink
                  to={`${APP_URI.SESSIONS}/${sessionId}/${APP_URI.SESSION_DASHBOARD}`}
                >
                  <Icon name="chevron-left" />
                  Back
                </NavLink>
                <BlockUI fallbackCondition={!tree}>
                  <SearchTreeView
                    data={tree}
                    className="esg-session-sidebar__top__report__tree"
                    noCheck
                  />
                </BlockUI>
              </div>
            </BlockUI>
          )}
        </BlockUI>
      </div>

      {!isReportSelected && (
        <div className="esg-session-sidebar__bottom">
          <Button
            className="esg-session-sidebar__bottom__download"
            variant="outline"
            icon={<Icon name="download" />}
            disabled
          >
            Download Report
          </Button>
        </div>
      )}
    </StyledSessionSidebar>
  );
};

export default SessionSidebar;
