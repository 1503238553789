import { FC } from 'react';
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
  redirect,
} from 'react-router-dom';

import routes, { ExtendRouteObject } from '../Routes';
import storageService from 'services/storageService';
import { UserRole } from '../../models';

export const rolePermission = (
  userRoles: UserRole[],
  routeRoles: UserRole[]
) => {
  if (!userRoles) return;
  return userRoles.some((role) => routeRoles.includes(role));
};

/**Function will reset ls state for now, in future
 * will reset state for roles and isLoggedIn provided
 * from auth provider
 */
export const globalLogout = async () => {
  storageService.removeItem('ROLE');
  storageService.removeItem('TOKEN');
};

const protectRoutes = (routes: ExtendRouteObject[]): RouteObject[] => {
  return routes.map(
    ({
      children,
      fallbackUrl = '/auth',
      authorized = false,
      onlyPublic,
      roles,
      loader: routeLoader,
      ...route
    }) => ({
      ...route,
      children: children
        ? protectRoutes(children as ExtendRouteObject[])
        : null,
      loader: async (args) => {
        const isLoggedIn = !!storageService.getItem('ROLE');

        const userRoles = storageService.getItem('ROLE') as UserRole[];

        //throw - conceptual similar to server returning 400
        //level error, user is not authenticated

        const finalFallback =
          typeof fallbackUrl === 'function' ? fallbackUrl() : fallbackUrl;

        if (authorized && !isLoggedIn) throw redirect(finalFallback);

        //Check if user has a specific role,
        //if not call global logout and redirect user on auth
        if (isLoggedIn && !userRoles) {
          await globalLogout();
          throw redirect('/auth');
        }

        if (roles && userRoles && !rolePermission(userRoles, roles)) {
          throw redirect(finalFallback);
        }

        if (onlyPublic && isLoggedIn) {
          throw redirect('/');
        }

        return {
          ...(typeof routeLoader === 'boolean'
            ? routeLoader
            : routeLoader?.(args)),
        };
      },
    })
  ) as RouteObject[];
};

const Router: FC = () => {
  const router = createBrowserRouter(protectRoutes(routes));
  return <RouterProvider router={router} />;
};

export default Router;
