import { FC } from 'react';

import { ChoiceDataModule } from 'models';
import { StyledModuleContainer } from '../../styles/ModuleContainer.styled';
import InlineEditable from '../../../../_molecules/InlineEditable';
import { InlineModuleElement } from '../ModuleElement/ModuleElement.component';

export type ChoiceCanvasModuleProps = InlineModuleElement<ChoiceDataModule>;

const ChoiceCanvasModule: FC<ChoiceCanvasModuleProps> = (props) => {
  const { config, ...rest } = props;
  const { options } = config || {};

  return (
    <StyledModuleContainer className="esg-choice-canvas-module">
      <InlineEditable
        data-no-dnd="true"
        placeholderText={'Click to enter/edit choice label'}
        {...rest}
      />
      <p>{`(${options?.length ?? 0} elements)`}</p>
    </StyledModuleContainer>
  );
};

export default ChoiceCanvasModule;
