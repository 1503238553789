import { BaseCampaign, TreeNodeElement } from 'models';
import { FC, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import api from 'api';

import { campaignPathCheckpoints } from '../../pages/Campaigns/utils';
import CampaignProviderContext, {
  CampaignProviderContextProps,
} from './Campaign.context';

type CampaignProviderProps = {
  children?: ReactNode;
};

export const mapBaseCampaignToTreeNodeElement = (
  { id, name, created, modified, children }: BaseCampaign,
  navigate: NavigateFunction,
  location: Location,
  depth = 0,
  path = ''
): TreeNodeElement => ({
  id,
  name,
  created,
  modified,
  children:
    depth < 3
      ? children?.map((c) =>
          mapBaseCampaignToTreeNodeElement(
            c,
            navigate,
            location,
            depth + 1,
            `${path}/${campaignPathCheckpoints[depth]}/${id}`
          )
        )
      : undefined,
  iconName: depth === 3 ? 'folder' : 'file',
  to: `${path}/${campaignPathCheckpoints[depth]}/${id}`,
});

const CampaignProvider: FC<CampaignProviderProps> = (props) => {
  const { children } = props;

  const { campaign = '' } = useParams<{
    campaign: string;
  }>();

  const deletedCampaign = useRef(false);

  const navigate = useNavigate();
  const location = useLocation();

  const [tree, setTree] = useState<TreeNodeElement>();

  const { campaign: campaignRoot, mutate: mutateTree } = api.useGetCampaignTree(
    campaign,
    !!campaign && !deletedCampaign.current
  );

  // MUTATION WILL BE TRIGGERED IN A COMPONENT WHICH EXTENDS THIS ONE
  const { request: campaignItemsMutationRequest } = api.useMutation(
    '/campaign-items',
    {
      revalidate: false,
      // THIS HAS AN EDGE CASE AND THAT IS WHEN A CAMPAIGN IS DELETED (ROOT), THEN MUTATE TREE SHOULD NOT BE CALLED
      // BUT IT IS, AND IT IS AVOIDED WITH deletedCampaign REF
      onSuccess: () => {
        mutateTree();
      },
    }
  );

  useEffect(() => {
    if (campaignRoot)
      setTree(
        mapBaseCampaignToTreeNodeElement(campaignRoot, navigate, location)
      );
  }, [campaignRoot, navigate, location]);

  return (
    <CampaignProviderContext.Provider
      value={{
        deletedCampaign,
        tree,
        setTree,
        mutateTree,
        campaignItemsMutationRequest,
      }}
    >
      {children}
    </CampaignProviderContext.Provider>
  );
};

const useCampaignProvider = (): CampaignProviderContextProps =>
  useContext(CampaignProviderContext);

export { CampaignProvider, useCampaignProvider };
