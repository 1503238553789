import {
  FC,
  FocusEvent,
  LegacyRef,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { StyledInlineEditable } from './InlineEditable.styled';
import { Button, Input, InputProps } from '@faxi/web-component-library';
import Icon from '../../Icon';

export type InlineEditableProps = PropsWithChildren<{
  onSave: (text?: string) => void;
  onDiscard?: () => void;
  placeholderText: string;
}> &
  InputProps;

const InlineEditable: FC<InlineEditableProps> = ({
  value,
  placeholderText,
  onBlur,
  onSave,
  onChange,
  onDiscard,
  ...rest
}) => {
  const inputContainerRef = useRef<HTMLDivElement>(null);

  const [isEditing, setIsEditing] = useState(false);

  const initialValue = useRef(value);

  const handleDiscard = useCallback(() => {
    onChange?.(initialValue.current || '');
    onDiscard?.();
    setIsEditing(false);
  }, [onChange, onDiscard]);

  const handleInputBlur = useCallback(
    (e: FocusEvent<HTMLInputElement, Element>) => {
      if (
        !e.relatedTarget?.classList?.contains(
          'esg-inline-editable__input__actions__action'
        )
      )
        handleDiscard();

      onBlur?.(e);
    },
    [handleDiscard, onBlur]
  );

  const handleSave = useCallback(() => {
    onSave(
      inputContainerRef?.current?.getElementsByTagName('input')?.[0].value
    );
    setIsEditing(false);
  }, [onSave]);

  useEffect(() => {
    const input =
      inputContainerRef?.current?.getElementsByTagName('input')?.[0];

    if (isEditing && input) {
      input.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    initialValue.current =
      inputContainerRef?.current?.getElementsByTagName('input')?.[0].value;
  }, [isEditing]);

  return (
    <StyledInlineEditable
      className="esg-inline-editable"
      isEditing={isEditing}
      {...rest}
    >
      <div className="esg-inline-editable__input">
        <Input
          tabIndex={0}
          ref={inputContainerRef as LegacyRef<HTMLInputElement>}
          type="text"
          onBlur={handleInputBlur}
          value={value}
          onChange={onChange}
        />
        <div className="esg-inline-editable__input__actions">
          <Button
            onClick={handleSave}
            icon={<Icon name="check" />}
            className="esg-inline-editable__input__actions__action"
            tabIndex={0}
          />
          <Button
            onClick={handleDiscard}
            icon={<Icon name="xmark" />}
            className="esg-inline-editable__input__actions__action"
            tabIndex={0}
          />
        </div>
      </div>

      <p onClick={() => setIsEditing(true)}>{value || placeholderText}</p>
    </StyledInlineEditable>
  );
};

export default InlineEditable;
