import { FC, useMemo } from 'react';

import { FieldProps, FormFieldProps, FormField } from '@faxi/web-form';

export type ConditionalFieldProps<T extends Record<string, any>> =
  FormFieldProps<T> &
    FieldProps & {
      condition: boolean;
    };

function ConditionalField<T extends Record<string, any> = any>(
  props: ConditionalFieldProps<T>
) {
  const { condition, component: Component, ...rest } = props;

  const ConditionalComponent = useMemo(
    () => (condition ? FormField : Component) as FC<FieldProps<T>>,
    [Component, condition]
  );

  return (
    <ConditionalComponent
      {...(condition && { component: Component })}
      {...rest}
    />
  );
}

export default ConditionalField;
