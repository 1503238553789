import {
  ReactNode,
  FC,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import FormBuilderContext, {
  FormBuilderContextProps,
} from './FormBuilder.context';
import {
  CanvasModuleType,
  DataModuleEnum,
  ModuleConfig,
  ModuleConfigType,
} from 'models';
import CampaignItemContext from '../CampaignItem/CampaignItem.context';
import { prepareCampaignFormObject } from '../../utils';
import { useUtilities } from '@faxi/web-component-library';
import { dataModulesTextMapper } from '../../components/BuilderTools/components/DataModulesTool/constants';

type FormBuilderProps = {
  children?: ReactNode;
};

const FormBuilderProvider: FC<FormBuilderProps> = (props) => {
  const { children } = props;

  const { campaignItem, editCampaignItem, mutating } =
    useContext(CampaignItemContext);

  const { showOverlay, hideOverlay, showSnackBar } = useUtilities();

  const [selectedDataModule, setSelectedDataModule] = useState<string>();

  const [modules, setModules] = useState<
    ModuleConfig<ModuleConfigType, DataModuleEnum>[]
  >([]);

  const selectDataModule = (dataModule: string) =>
    setSelectedDataModule((prev) =>
      prev === dataModule ? undefined : dataModule
    );

  const addModule = useCallback(
    async (type: DataModuleEnum) => {
      showOverlay('.esg-sub-sub-topic');
      const newModules = [
        ...modules,
        {
          created: '',
          id: `${modules.length + 1}`,
          index: modules.length,
          type,
          title: '',
          modified: '',
        },
      ];
      try {
        await editCampaignItem?.(prepareCampaignFormObject(newModules));
        setModules(newModules);
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('.esg-sub-sub-topic');
      }
    },
    [editCampaignItem, hideOverlay, modules, showOverlay]
  );

  const duplicateModule = useCallback(
    async (module: CanvasModuleType) => {
      showOverlay('.esg-sub-sub-topic');
      const newModules = [
        ...modules,
        { ...module, id: `${modules.length + 1}`, index: modules.length },
      ];
      try {
        await editCampaignItem?.(prepareCampaignFormObject(newModules));
        setModules(newModules);

        showSnackBar({
          text: `Successfully duplicated ${dataModulesTextMapper[module.type].toLowerCase()} ${module.title}.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('.esg-sub-sub-topic');
      }
    },
    [editCampaignItem, hideOverlay, modules, showOverlay, showSnackBar]
  );

  useEffect(() => {
    if (!campaignItem) return;

    setModules(
      campaignItem.form?.elements
        .map(({ index, type, title = '', id, ...rest }) => ({
          id,
          index,
          type,
          title,
          ...rest,
        }))
        .sort(({ index: index1 }, { index: index2 }) =>
          index1 < index2 ? -1 : 1
        ) || []
    );
  }, [campaignItem]);

  return (
    <FormBuilderContext.Provider
      value={{
        loadingNewModule: mutating,
        selectedDataModule,
        selectDataModule,
        modules,
        setModules,
        addModule,
        duplicateModule,
      }}
    >
      {children}
    </FormBuilderContext.Provider>
  );
};

const useFormBuilder = (): FormBuilderContextProps =>
  useContext(FormBuilderContext);

export { FormBuilderProvider, useFormBuilder };
