import styled from 'styled-components';

import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import { BaseLayout } from 'layouts';

export const StyledSessions = styled(BaseLayout)`
  .esg-sessions {
    &__search-input {
      margin-top: ${theme.sizes.SIZE_32};
      max-width: ${theme.sizes.SIZE_320};
    }

    &__container {
      ${flex('column')};
      padding: ${theme.sizes.SIZE_32};
      overflow: auto;
      height: 100%;

      &__list {
        display: flex;
        flex-direction: column;
        gap: ${theme.sizes.SIZE_20};
        margin-top: ${theme.sizes.SIZE_48};

        &--no-data {
          align-items: center;
          justify-content: center;
          height: 100%;
          margin-top: 0;
        }
      }
    }
  }
`;
