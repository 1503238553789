import {
  Button,
  Select,
  SelectOption,
  useUtilities,
} from '@faxi/web-component-library';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import api from 'api';
import useGetCampaigns from 'api/hooks/useGetCampaigns';
import { CheckboxTreeView } from 'components';
import Icon from 'components/Icon';
import { BlockUI } from 'helpers';
import { createTreeWithParentReferences } from 'mock/treeViewData';
import { TreeNodeElement } from 'models';
import NoData from 'styles/NoData';
import { toggleCheckByIds } from '../../../../../components/_organisms/CheckboxTreeView/utils';
import { useOrganisationProvider } from '../../providers/Organisation';
import {
  compareArrays,
  findCheckedNodes,
  mapCampaignToTreeNode,
  mapTreeCheckedToArray,
} from './utils';

import cloneDeep from 'lodash.clonedeep';
import { StyledCompany } from './Company.styled';

const Company: FC = () => {
  const { company } = useParams() as { company: string };

  const [campaign, setCampaign] = useState<SelectOption>();
  const [campaignTree, setCampaignTree] = useState<TreeNodeElement>();
  const [isTreeChanged, setIsTreeChanged] = useState(false);

  const initialCampaignTree = useRef<TreeNodeElement>();

  const removeBtnRef = useRef<HTMLButtonElement>(null);

  const { prompts, showSnackBar, showOverlay, hideOverlay } = useUtilities();
  const { activeOrganisation } = useOrganisationProvider();

  const { request: detachCampaign } = api.useMutation(
    `company-campaigns/${company}`,
    {
      onSuccess: () => {
        initialCampaignTree.current = undefined;
        setCampaign(undefined);
        setCampaignTree(undefined);
        showSnackBar({
          variant: 'success',
          text: `${campaign?.label} has been detached.`,
          actionButtonText: 'Dismiss',
        });
      },
      onError: () => {
        showSnackBar({
          variant: 'error',
          text: `An error occurred while detaching ${campaign?.label}.`,
          actionButtonText: 'Dismiss',
        });
      },
    }
  );

  const { campaigns, isLoading: loadingCampaigns } = useGetCampaigns();

  const { data, isLoading: loadingCompany } = api.useCompanyCampaign(company, {
    errorRetryCount: 0,
  });

  const { isLoading: loadingTree, campaign: campaignTreeData } =
    api.useGetCampaignTree(campaign?.value, !!campaign?.value);

  const loading = loadingTree || loadingCompany;

  const { request: attachCampaign } = api.useMutation(`/company-campaigns`, {
    onSuccess: () => {
      initialCampaignTree.current = cloneDeep(campaignTree);
      setIsTreeChanged(false);
      showSnackBar({
        variant: 'success',
        text: `${campaign?.label} has been attached to ${activeOrganisation?.name}.`,
        actionButtonText: 'Dismiss',
      });
    },
  });

  const selectOptions = useMemo(
    () =>
      campaigns?.map(({ id, name }) => ({
        value: id,
        label: name,
      })) as SelectOption<string>[],
    [campaigns]
  );

  const handleDelete = useCallback(async () => {
    const proceed = await prompts.delete({
      type: 'delete',
      title: `Remove ${campaign?.label}`,
      content: `Are you sure you want to remove ${campaign?.label} from ${activeOrganisation?.name}?`,
      submitBtnText: 'Remove',
      cancelBtnText: 'Do not remove',
      btnIcon: 'trash-can',
      iconPosition: 'left',
      submitBtnVariant: 'delete-ghost',
      triggerRef: removeBtnRef.current as HTMLButtonElement,
      titleIcon: <Icon name="triangle-exclamation" />,
    });

    if (!proceed) return;
    await detachCampaign('DELETE', {});
  }, [prompts, campaign, activeOrganisation, detachCampaign]);

  const handleSaveChanges = useCallback(async () => {
    const checkedNodes = findCheckedNodes(campaignTree);

    if (!checkedNodes.length) {
      showSnackBar({
        variant: 'error',
        text: 'You need to attach campaign items.',
        actionButtonText: 'Dismiss',
      });
      return;
    }

    showOverlay('body');
    await attachCampaign('POST', {
      data: {
        campaignId: campaign?.value,
        companyId: activeOrganisation?.id,
        selectedElementsIds: checkedNodes,
      },
    });
    hideOverlay('body');
  }, [
    attachCampaign,
    showOverlay,
    hideOverlay,
    showSnackBar,
    activeOrganisation,
    campaign,
    campaignTree,
  ]);

  useEffect(() => {
    if (!campaignTree || !data?.campaign) return;

    if (!initialCampaignTree.current) {
      toggleCheckByIds(
        campaignTree,
        data.campaign.dataCollectionElements.map((el) => el.id)
      );

      initialCampaignTree.current = cloneDeep(campaignTree);
    }
  }, [campaignTree, data]);

  useEffect(() => {
    initialCampaignTree.current = undefined;
    setCampaign(undefined);
    setCampaignTree(undefined);
  }, [company]);

  useEffect(() => {
    setCampaignTree(
      campaignTreeData &&
        createTreeWithParentReferences(
          mapCampaignToTreeNode(campaignTreeData),
          null,
          0
        )
    );
  }, [campaignTreeData]);

  useEffect(() => {
    setCampaign(
      data?.campaign && { value: data.campaign.id, label: data.campaign.name }
    );
  }, [data]);

  return (
    <StyledCompany className="esg-company">
      <BlockUI loading={loading}>
        {!campaign ? (
          <Select
            async
            value={campaign}
            loading={loadingCampaigns}
            className="esg-company__select"
            placeholder="Attach campaign"
            options={selectOptions}
            onChange={setCampaign}
          />
        ) : (
          <div className="esg-company__selected-campaign">
            <p className="esg-company__selected-campaign__title">Campaign</p>
            <div className="esg-company__selected-campaign__discard">
              <Icon name="leaf" />
              <p>{campaign.label}</p>
              <Button
                ref={removeBtnRef}
                variant="ghost"
                icon={<Icon name="xmark" />}
                onClick={handleDelete}
              />
            </div>
          </div>
        )}

        {campaignTree ? (
          <CheckboxTreeView
            filteredData={campaignTree}
            onCheck={() => {
              const initialTreeCheckedStates = mapTreeCheckedToArray(
                initialCampaignTree.current!
              );
              const treeCheckedStates = mapTreeCheckedToArray(campaignTree);

              setIsTreeChanged(
                !compareArrays(initialTreeCheckedStates, treeCheckedStates)
              );

              setCampaignTree(cloneDeep(campaignTree));
            }}
          />
        ) : (
          <NoData>No attached campaigns.</NoData>
        )}

        {campaign && (
          <Button
            variant="outline"
            onClick={handleSaveChanges}
            disabled={!isTreeChanged}
          >
            Save
          </Button>
        )}
      </BlockUI>
    </StyledCompany>
  );
};

export default Company;
