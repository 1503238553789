import { useModalUtilities } from '@faxi/web-component-library';
import { FC, useMemo } from 'react';

import FoldersLayout from 'layouts/FoldersLayout';
import { Organisation } from 'models';
import { useNavigateUpOnDelete } from '../../../../../hooks';
import CompanyModal from '../../modals/CompanyModal';
import { useOrganisationProvider } from '../../providers/Organisation';
import { generateOrganisationItemCrumbs } from '../../utils';

const FolderWrapper: FC = () => {
  const {
    tree,
    activeOrganisation,
    loading,
    mutateOrganisations,
    deleteOrganisation,
  } = useOrganisationProvider();

  const { open, openModal, closeModal } = useModalUtilities();

  const { id, name, path } = activeOrganisation ?? ({} as Organisation);

  const crumbs = useMemo(
    () => generateOrganisationItemCrumbs(path?.names, path?.ids, id, name),
    [id, name, path]
  );

  const { handleNavigate } = useNavigateUpOnDelete();

  return (
    <>
      <FoldersLayout
        data={tree}
        crumbs={crumbs}
        loadingTree={loading}
        pageTitle="Organisations"
        treeViewTitle="Organisation browser"
        activeNodeId={activeOrganisation?.id}
        treeNodeTitle={activeOrganisation?.name}
        description={activeOrganisation?.description}
        onChange={() => openModal()}
        onDelete={(org) =>
          deleteOrganisation(org as Organisation, undefined, () =>
            handleNavigate(org.id)
          )
        }
      />

      {open && (
        <CompanyModal
          conditionallyControlled
          initialData={activeOrganisation}
          mutator={mutateOrganisations}
          onClose={closeModal}
          fieldsConfiguration={{
            NAME: true,
            DESCRIPTION: true,
            EMAIL: false,
            ROLE: false,
            TYPE: false,
          }}
        />
      )}
    </>
  );
};

export default FolderWrapper;
