import { FC } from 'react';
import { Select } from '@faxi/web-component-library';

import { DropdownDataModule } from 'models';
import InlineEditable from 'components/_molecules/InlineEditable';
import { StyledModuleContainer } from '../../styles/ModuleContainer.styled';
import { InlineModuleElement } from '../ModuleElement/ModuleElement.component';

export type DropdownCanvasModuleProps = InlineModuleElement<DropdownDataModule>;

const DropdownCanvasModule: FC<DropdownCanvasModuleProps> = (props) => {
  return (
    <StyledModuleContainer className="esg-dropdown-canvas-module">
      <InlineEditable
        data-no-dnd="true"
        placeholderText={'Click to enter/edit dropdown description'}
        {...props}
      />
      <Select options={[]} disabled placeholder="Select Option" />
    </StyledModuleContainer>
  );
};

export default DropdownCanvasModule;
