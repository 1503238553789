import { FormField } from '@faxi/web-form';
import { FC } from 'react';

import { SwitchField } from 'components';
import { DataModuleEnum, SwitchDataModule } from 'models';
import { generatePreviewFieldName } from 'utils';

import { StyledSwitchPreview } from './SwitchPreview.styled';

export type SwitchPreviewProps = SwitchDataModule;

const SwitchPreview: FC<SwitchPreviewProps> = ({ title, id }) => {
  return (
    <StyledSwitchPreview className="esg-switch-preview">
      <p>{title || 'Switch'}</p>
      <FormField
        name={generatePreviewFieldName(id, DataModuleEnum.SWITCH)}
        component={SwitchField}
      />
    </StyledSwitchPreview>
  );
};

export default SwitchPreview;
