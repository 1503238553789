import { Breadcrumb } from '@faxi/web-component-library';
import { FC, PropsWithChildren, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ReportStatus } from 'components';
import { APP_URI } from 'config';
import { BlockUI } from 'helpers';
import { useFormModules } from 'hooks';
import TasksContext from '../../providers/Tasks/Tasks.context';

import { StyledTasksLayout } from './TasksLayout.styled';

export type TasksLayoutProps = PropsWithChildren<{}>;

const TasksLayout: FC<TasksLayoutProps> = () => {
  const { session, tree, loading, activeReportNode } = useContext(TasksContext);
  const params = useParams<{ companyId: string; collectionId: string }>();

  const {
    loadingForm,
    loadingStatus,
    progress,
    formStatus,
    formApproval,
    updateFormStatus,
  } = useFormModules({
    asAdmin: false,
    sessionId: params.companyId,
    collectionId: params.collectionId,
  });

  const crumbs = useMemo(
    () =>
      [
        {
          text: 'Tasks',
          href: `${APP_URI.TASKS}`,
        },
        {
          text: session?.name,
          href: `${APP_URI.TASKS}/${session?.id}`,
        },
      ] as Breadcrumb[],
    [session]
  );

  return (
    <StyledTasksLayout
      data={tree}
      crumbs={crumbs}
      loadingTree={loading}
      pageTitle="Tasks"
      sessionTitle={session?.name}
      treeViewTitle="Tasks browser"
      activeNodeId={activeReportNode?.id}
      treeNodeTitle={activeReportNode?.name}
      showContributors={!!activeReportNode}
      contributor={{
        isAdmin: false,
        name: 'Jovan Aritonovic',
        src: '/assets/images/avatars/joca.png',
      }}
    >
      <BlockUI loading={loadingForm}>
        {activeReportNode && (
          <ReportStatus
            asAdmin={false}
            progress={progress}
            formStatus={formStatus}
            formApproval={formApproval}
            loading={loadingForm || loadingStatus}
            onStatusChange={updateFormStatus}
          />
        )}
      </BlockUI>
    </StyledTasksLayout>
  );
};

export default TasksLayout;
