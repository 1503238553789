import { Tooltip, getColor } from '@faxi/web-component-library';
import { FormField, useFormContextValues } from '@faxi/web-form';
import { FC, useMemo } from 'react';

import { InputField, SelectField } from 'components';
import Icon from 'components/Icon';
import { DataModuleEnum, DropdownDataModule } from 'models';
import { generateOptionsByLabels, generatePreviewFieldName } from 'utils';

import { StyledDropdownPreview } from './DropdownPreview.styled';

export type DropdownPreviewProps = DropdownDataModule;

const DropdownPreview: FC<DropdownPreviewProps> = ({ title, id, config }) => {
  const finalOptions = useMemo(() => {
    if (!config) return [];

    const shouldAddOtherOption = config.other;
    const options = [
      ...config.options,
      ...(shouldAddOtherOption ? [{ label: 'Other' }] : []),
    ];

    return generateOptionsByLabels(options);
  }, [config]);

  const dropdownName = generatePreviewFieldName(id, DataModuleEnum.DROPDOWN);
  const value = useFormContextValues(dropdownName);

  const shouldRenderOtherInput = value?.[dropdownName] === 'Other';

  return (
    <StyledDropdownPreview className="esg-dropdown-preview">
      <div className="esg-dropdown-preview__title">
        <p>{title || 'Click to enter/edit dropdown description'}</p>
        {config?.helperText && (
          <Tooltip content={config.helperText}>
            <span tabIndex={0} role="tooltip" aria-label={config.helperText}>
              <Icon name="circle-info" color={getColor('--GRAY-40')} />
            </span>
          </Tooltip>
        )}
      </div>

      <div className="esg-dropdown-preview__fields">
        <FormField
          name={dropdownName}
          component={SelectField}
          options={finalOptions}
          placeholder={config?.placeholder}
          className="esg-dropdown-preview__dropdown"
          renderAsPortal
          searchable
        />

        {shouldRenderOtherInput && (
          <InputField
            name={`${dropdownName}Other`}
            placeholder="Specify here"
          />
        )}
      </div>
    </StyledDropdownPreview>
  );
};

export default DropdownPreview;
