import { ReactNode } from 'react';
import { INameExtended } from '../components/Icon';
import { BaseModelExtended } from './BaseModel';

export enum CheckStatus {
  Checked = 2,
  Indeterminate = 1,
  Unchecked = 0,
}

export type TreeNodeElement = BaseModelExtended & {
  color?: string;
  uniqName?: string;
  iconName?: INameExtended;
  children?: TreeNodeElement[];
  parent?: TreeNodeElement | null;
  checked?: CheckStatus;
  element?: ReactNode;
  to?: string;
};
