import useSWR, { SWRConfiguration } from 'swr';
import { Campaign } from 'models';

const useGetCampaignTree = (
  id?: string,
  shouldFetch?: boolean,
  config?: SWRConfiguration
) => {
  const { data, error, isLoading, mutate } = useSWR<Campaign>(
    shouldFetch ? `/campaigns/${id}/tree` : null,
    config
  );

  return {
    campaign: data,
    isLoading,
    isError: error,
    mutate,
  };
};

export default useGetCampaignTree;
