import { FC } from 'react';

import { TextDataModule } from 'models';

import { StyledTextPreview } from './TextPreview.styled';

export type TextPreviewProps = TextDataModule;

const TextPreview: FC<TextPreviewProps> = ({ title }) => (
  <StyledTextPreview className="esg-text-preview">
    {title || 'Free text description / intro / subtitle'}
  </StyledTextPreview>
);

export default TextPreview;
