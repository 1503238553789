import { Loading } from 'components';
import { FC, PropsWithChildren, ReactNode } from 'react';
import NoData from '../styles/NoData';

const BlockUI: FC<
  PropsWithChildren & {
    loading?: boolean;
    fallbackCondition?: boolean;
    fallback?: ReactNode;
  }
> = ({
  children,
  loading,
  fallbackCondition,
  fallback = <NoData>No data.</NoData>,
}) => {
  if (loading) return <Loading />;

  return fallbackCondition ? fallback : children;
};

export default BlockUI;
