import { FC, PropsWithChildren, memo } from 'react';

import Icon, { INameExtended } from 'components/Icon';

import { APP_URI } from 'config';
import { StyledCampaignChip } from './CampaignChip.styled';

export type CampaignChipProps = PropsWithChildren<{
  id: string;
  name: string;
  iconName?: INameExtended;
}>;

const CampaignChip: FC<CampaignChipProps> = ({
  iconName = 'leaf',
  name,
  id,
}) => (
  <StyledCampaignChip
    to={`${APP_URI.CAMPAIGNS}/${id}`}
    className="esg-report-chip"
  >
    <Icon name={iconName} />
    {name}
  </StyledCampaignChip>
);

export default memo(CampaignChip);
