import { Checkbox, CheckboxProps } from '@faxi/web-component-library';
import { FieldProps, useFieldError } from '@faxi/web-form';
import { FC, ReactNode, useCallback } from 'react';

import { StyledCheckboxListField } from './CheckboxListField.styled';

export type CheckboxListFieldProps = FieldProps<string[]> & {
  options: Array<
    Pick<CheckboxProps, 'label' | 'value'> & { customLabel?: ReactNode }
  >;
} & Omit<CheckboxProps, 'ref'>;

const toggleArrayValue = <T,>(array: T[], value: T): T[] =>
  array.includes(value) ? array.filter((v) => v !== value) : [...array, value];

const CheckboxListField: FC<CheckboxListFieldProps> = (props): JSX.Element => {
  const {
    value = [],
    onChange,
    error,
    label,
    touched,
    dirty,
    options,
    className,
    ...rest
  } = props;

  const showError = useFieldError(props);

  const handleCheckboxChange = useCallback(
    (optionValue: string) => {
      const newValue = toggleArrayValue(value, optionValue);

      onChange?.(newValue);
    },
    [onChange, value]
  );

  return (
    <StyledCheckboxListField className={className}>
      {options.map((option) => (
        <div key={option.label}>
          <Checkbox
            {...rest}
            label={option.customLabel ? undefined : option.label}
            aria-label={option.label}
            checked={value?.includes(option.value as string)}
            onChange={() => handleCheckboxChange(option.value as string)}
          />
          {option.customLabel}
        </div>
      ))}
      {showError && <small>{error}</small>}
    </StyledCheckboxListField>
  );
};

export default CheckboxListField;
