import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';

import Icon, { INameExtended } from 'components/Icon';
import { ApprovalStatus } from 'models';

import { StyledApprovalChip } from './ApprovalChip.styled';
import { FORM_APPROVAL, FORM_STATUS } from '../../../../../models/Session';

type ChipProperties = {
  label: string;
  backgroundColor: string;
  color: string;
  iconName: INameExtended | null;
};
const getChipProperties = (status: ApprovalStatus): ChipProperties => {
  switch (status) {
    case FORM_APPROVAL.APPROVED:
      return {
        label: 'Approved',
        backgroundColor: 'var(--GREEN-12)',
        color: 'var(--WHITE-FF)',
        iconName: 'thumbs-up-solid',
      };
    case FORM_APPROVAL.TO_REVIEW:
      return {
        label: 'To review',
        backgroundColor: 'var(--ORANGE-EE)',
        color: 'var(--WHITE-FF)',
        iconName: 'eye-solid',
      };
    case FORM_STATUS.COMPLETED:
      return {
        label: 'Completed',
        backgroundColor: 'var(--GREEN-E3)',
        color: 'var(--GREEN-12)',
        iconName: 'circle-check',
      };
    case FORM_STATUS.IN_REVIEW:
      return {
        label: 'Ready for approval',
        backgroundColor: 'var(--KHAKI-FF)',
        color: 'var(--KHAKI-81)',
        iconName: null,
      };
    case FORM_STATUS.IN_PROGRESS:
      return {
        label: 'Wait for completion',
        backgroundColor: 'var(--GRAY-EF)',
        color: 'var(--GRAY-54)',
        iconName: null,
      };

    default:
      return { label: '', backgroundColor: '', color: '', iconName: null };
  }
};

export type ApprovalChipProps = PropsWithChildren<{
  status?: ApprovalStatus;
}>;

const ApprovalChip: FC<ApprovalChipProps> = ({
  status = FORM_STATUS.IN_PROGRESS,
}) => {
  const { label, backgroundColor, color, iconName } = getChipProperties(status);

  return (
    <StyledApprovalChip
      className="esg-approval-chip"
      style={{ backgroundColor, color }}
    >
      {iconName && <Icon name={iconName} color={color} />}

      <label
        className={classNames('esg-approval-chip__label', {
          'esg-approval-chip__label--full-width': !iconName,
        })}
      >
        {label || 'Unknown'}
      </label>
    </StyledApprovalChip>
  );
};

export default ApprovalChip;
