import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, FormField } from '@faxi/web-form';

import { APP_URI } from 'config';
import { User } from 'models';
import { useCallbackAsync, useValidations } from 'hooks';
import { FormFooter, InputField } from 'components';
import storageService from 'services/storageService';
import { useUtilities } from '@faxi/web-component-library';

const wait = async (delay: number) =>
  new Promise<void>((resolve) => {
    setTimeout(() => resolve(), delay);
  });

type LoginFormType = {
  email: string;
  password: string;
};

const Login: FC = () => {
  const navigate = useNavigate();
  const { showSnackBar } = useUtilities();

  const { validations } = useValidations();

  const [handleSubmit] = useCallbackAsync({
    showSpinner: true,
    callback: async (values: LoginFormType) => {
      const { email } = values;

      const usersParsed = JSON.parse(
        storageService.getItem('USERS_MOCK')
      ) as User[];

      const user = usersParsed.find((user) => email === user.email);

      if (!user) {
        showSnackBar({
          variant: 'error',
          text: 'You have entered an invalid email or password.',
          actionButtonText: 'Dismiss',
        });
        return;
      }

      await wait(300);
      //token should be encrypted and stored in a cookie
      storageService.setItem('TOKEN', user.id);
      storageService.setItem('ROLE', [user.role]);

      navigate('/');
    },
  });

  return (
    <Form onSubmit={handleSubmit} className="">
      <FormField
        name="email"
        type="text"
        component={InputField}
        autoComplete="off"
        placeholder="Email"
        validate={validations.email}
      />

      <FormField
        name="password"
        type="password"
        component={InputField}
        autoComplete="off"
        placeholder="Password"
        validate={validations.password}
      />

      <Link to={`../${APP_URI.FORGOT_PASSWORD}`}>Forgot password?</Link>
      <FormFooter submitLabel="Login" />

      <p className="auth-text">
        Do not have an account?
        <Link to={`../${APP_URI.SIGN_UP}`}> Sign Up</Link>
      </p>
    </Form>
  );
};

export default Login;
