import { FC } from 'react';
import { Heading } from '@faxi/web-component-library';
import classNames from 'classnames';

import { StyledHeader } from './Header.styled';
import Icon, { INameExtended } from '../../components/Icon';

export type HeaderProps = { title: string; className?: string };

const mapIconByPage: Record<string, INameExtended> = {
  'Sessions': 'chart-bar',
  'Organisations': 'shield',
  'Campaigns': 'leaf',
  'Users': 'users',
  'Dashboard': 'gauge',
  'Account settings': 'user',
  'Tasks': 'chart-bar',
};

const Header: FC<HeaderProps> = ({ title, className }) => (
  <StyledHeader className={classNames('esg-header', className)}>
    <div
      className={classNames(
        'esg-header__badge',
        `esg-header__badge--${title.toLowerCase()}`
      )}
    >
      <Icon name={mapIconByPage[title]} />
    </div>
    <Heading level="1">{title}</Heading>
  </StyledHeader>
);

export default Header;
