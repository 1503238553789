import { elevate_l, theme } from '@faxi/web-component-library';
import { flex, position, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledCollapsibleSidePanel = styled.div.withConfig({
  shouldForwardProp: (prop) => !['expandDirection', 'expanded'].includes(prop),
})<{
  expandDirection: 'left' | 'right';
  expanded: boolean;
}>`
  ${flex('column')}
  gap: ${theme.sizes.SIZE_40};
  ${({ expandDirection }) =>
    expandDirection === 'left'
      ? `border-left: ${theme.sizes.SIZE_1} solid var(--GRAY-EF);`
      : `border-right: ${theme.sizes.SIZE_1} solid var(--GRAY-EF);`}

  position: relative;
  transition: width 350ms ease-in-out;
  transition-delay: ${({ expanded }) => (!expanded ? '50ms' : '')};
  width: ${({ expanded }) => (expanded ? '100%' : theme.sizes.SIZE_40)};
  max-width: ${theme.sizes.SIZE_400};

  .esg-collapsible-side-panel {
    width: 100%;
    transition: opacity 250ms ease-in-out;
    transition-delay: ${({ expanded }) => (!expanded ? '' : '150ms')};
    opacity: ${({ expanded }) => (expanded ? '1' : '0')};
    padding: ${theme.sizes.SIZE_46} ${theme.sizes.SIZE_24};
    overflow: auto;

    &__collapse {
      ${size(theme.sizes.SIZE_16)};
      ${(props) =>
        position(
          'absolute',
          `top ${theme.sizes.SIZE_136} ${props.expandDirection === 'left' ? `left -${theme.sizes.SIZE_8}` : `right -${theme.sizes.SIZE_8}`}`
        )};
      border-radius: 50%;
      ${elevate_l};
      padding: unset;
      z-index: 1;
      min-height: unset;
      transition: transform 100ms ease-in-out;

      &:hover {
        transform: scale(1.6);
      }

      > .button__icon {
        ${flex('row', 'center', 'center')};

        svg {
          font-size: ${theme.sizes.SIZE_10};
        }
      }
    }
  }
`;

export { StyledCollapsibleSidePanel };
