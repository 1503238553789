/* eslint-disable no-loop-func */
import { FC, useCallback } from 'react';
import classNames from 'classnames';

import { TreeNodeElement } from 'models';

import { BasicTreeView } from 'components';

import { StyledCheckboxTreeView } from './CheckboxTreeView.styled';
import { toggleCheck } from './utils';

export type CheckboxTreeViewProps = {
  filteredData: TreeNodeElement;
  className?: string;
  onCheck?: () => void;
};

const CheckboxTreeView: FC<CheckboxTreeViewProps> = ({
  filteredData,
  className,
  onCheck,
}) => {
  const handleCheck = useCallback(
    (node: TreeNodeElement, checked: TreeNodeElement['checked']) => {
      toggleCheck(node, checked);

      onCheck?.();
    },
    [onCheck]
  );

  return (
    <StyledCheckboxTreeView
      className={classNames('esg-checkbox-tree-view', className)}
    >
      <BasicTreeView
        data={filteredData}
        onCheck={onCheck ? handleCheck : undefined}
      />
    </StyledCheckboxTreeView>
  );
};

export default CheckboxTreeView;
