import useSWR from 'swr';
import { Campaign } from 'models';

const useGetCampaigns = () => {
  const { data, error, isLoading, mutate } =
    useSWR<Campaign[]>(`/campaign-items`);

  return {
    campaigns: data,
    isLoading,
    isError: error,
    mutate,
  };
};

export default useGetCampaigns;
