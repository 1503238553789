import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSwitchPreview = styled.div`
  ${flex('row', 'space-between', 'center')};
  ${fontSize(theme.fontSizes.FONT_14)};
`;

export { StyledSwitchPreview };
