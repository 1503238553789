import { FC } from 'react';

import InlineEditable from 'components/_molecules/InlineEditable';
import { InputDataModule } from 'models';
import { Input } from '@faxi/web-component-library';
import { StyledModuleContainer } from '../../styles/ModuleContainer.styled';
import { InlineModuleElement } from '../ModuleElement/ModuleElement.component';

export type InputCanvasModuleProps = InlineModuleElement<InputDataModule>;

const InputCanvasModule: FC<InputCanvasModuleProps> = (props) => {
  return (
    <StyledModuleContainer className="esg-input-canvas-module">
      <InlineEditable
        data-no-dnd="true"
        placeholderText={'Click to enter/edit field label'}
        {...props}
      />
      <Input disabled placeholder="Text" />
    </StyledModuleContainer>
  );
};

export default InputCanvasModule;
