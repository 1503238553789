import { FC, useCallback } from 'react';

import Icon, { INameExtended } from 'components/Icon';

import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { StyledSessionSidebarLink } from './SessionSidebarLink.styled';

export type SessionSidebarLinkProps = {
  url: string;
  text: string;
  leftIcon: INameExtended;
  rightIcon?: INameExtended;
};

const SessionSidebarLink: FC<SessionSidebarLinkProps> = ({
  url,
  text,
  leftIcon,
  rightIcon,
}) => {
  const location = useLocation();

  const isPathActive = useCallback(
    (url: string, completely?: boolean) =>
      completely ? location.pathname === url : location.pathname.includes(url),
    [location.pathname]
  );

  return (
    <StyledSessionSidebarLink
      to={url}
      className={classNames('esg-session-sidebar-link', {
        'esg-session-sidebar-link--active': isPathActive(url),
      })}
    >
      <div className="esg-session-sidebar-link__main">
        <Icon name={leftIcon} />
        {text}
      </div>

      {rightIcon && <Icon name={rightIcon} />}
    </StyledSessionSidebarLink>
  );
};

export default SessionSidebarLink;
