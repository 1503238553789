import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

import { CollapsibleSidePanel } from 'components';

const StyledSessionSidebar = styled(CollapsibleSidePanel)`
  hr {
    margin: ${theme.sizes.SIZE_20} 0;
  }

  h1 {
    margin-top: ${theme.sizes.SIZE_20};
    font-weight: 400;
  }

  .esg-tree-node-component .esg-tree-node-component {
    padding-left: ${theme.sizes.SIZE_24};
  }

  .esg-collapsible-side-panel {
    ${flex('column', 'space-between', 'center')};
    ${fontSize(theme.fontSizes.FONT_14)};
    padding: ${theme.sizes.SIZE_32} ${theme.sizes.SIZE_24};

    height: 100%;
  }

  .esg-session-sidebar {
    &__top {
      ${flex('column')};
      height: 100%;
      width: 100%;

      &__report {
        a {
          ${flex('row', 'flex-start', 'center')};
          gap: ${theme.sizes.SIZE_8};

          color: var(--BLACK-00);
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        &__tree {
          width: 100%;
          resize: none;
          border: none;
          padding: ${theme.sizes.SIZE_16} 0;
        }
      }

      &__links {
        ${flex('column', 'space-between', 'normal')};
        gap: ${theme.sizes.SIZE_16};
      }

      &__subtitle {
        margin-bottom: ${theme.sizes.SIZE_16};
      }

      &__details {
        ${flex('column', 'space-between', 'normal')};
        gap: ${theme.sizes.SIZE_16};

        > * {
          height: ${theme.sizes.SIZE_24};
        }

        p {
          ${fontSize(theme.fontSizes.FONT_16)};
        }

        &__date {
          ${flex('row', 'space-between', 'center')};

          p {
            margin: 0;
          }

          &:not(:first-of-type) {
            margin-bottom: ${theme.sizes.SIZE_20};
          }
        }
      }

      .esg-approval-chip {
        max-width: ${theme.sizes.SIZE_208};
      }

      .esg-approval-progress-bar {
        justify-content: space-between;

        .kinto-progress-bar {
          max-width: ${theme.sizes.SIZE_208};
        }
      }
    }

    &__bottom {
      width: 100%;

      &__download {
        width: 100%;
      }
    }
  }
`;

export { StyledSessionSidebar };
