import { theme } from '@faxi/web-component-library';
import { flex, flexGap, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledFoldersPreview = styled.div`
  ${flex('column')};
  ${flexGap(theme.sizes.SIZE_24, 'column')};

  .esg-folders-preview {
    &__tabs {
      .wcl-tabs__option__icon {
        margin: 0;
      }
    }

    &__empty-folder {
      ${size('100%')};
      ${flex('column', 'center', 'center')};

      background-color: red;
    }

    &__container {
      gap: ${theme.sizes.SIZE_20};

      &[data-view='list'] {
        ${flex('column')};
      }

      &[data-view='grid'] {
        display: grid;
        grid-template-columns: repeat(auto-fill, ${theme.sizes.SIZE_400});
      }
    }
  }
`;

export { StyledFoldersPreview };
