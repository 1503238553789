import classNames from 'classnames';
import { FC } from 'react';

import { TreeNodeElement } from 'models';

import { TreeNode, TreeNodeProps } from './components';

import { StyledBasicTreeView } from './BasicTreeView.styled';

export type BasicTreeViewProps = {
  data?: (TreeNodeElement & { canDelete?: boolean }) | null;
  className?: string;
  activeNodeId?: string;
  onCheck?: (
    node: TreeNodeElement,
    checked: TreeNodeElement['checked']
  ) => void;
} & Pick<TreeNodeProps, 'onDelete' | 'onDuplicate' | 'withMenu'>;

/**
 * A basic tree view component that displays a hierarchical tree structure.
 */
const BasicTreeView: FC<BasicTreeViewProps> = ({
  data,
  className,
  activeNodeId,
  ...rest
}) => (
  <StyledBasicTreeView className={classNames('esg-basic-tree-view', className)}>
    <TreeNode level={1} activeNodeId={activeNodeId} node={data!} {...rest} />
  </StyledBasicTreeView>
);

export default BasicTreeView;
