import { Method } from 'axios';
import { Campaign, TreeNodeElement } from 'models';
import {
  createContext,
  Dispatch,
  MutableRefObject,
  SetStateAction,
} from 'react';
import { KeyedMutator } from 'swr';
import { RequestArgs } from '../../api/defaultMutation';

export type CampaignProviderContextProps = {
  tree?: TreeNodeElement;
  deletedCampaign: MutableRefObject<boolean>;
  setTree: Dispatch<SetStateAction<TreeNodeElement | undefined>>;
  mutateTree: KeyedMutator<Campaign>;
  campaignItemsMutationRequest: (
    method: Method,
    args: RequestArgs
  ) => Promise<any>;
};

export default createContext<CampaignProviderContextProps>({
  deletedCampaign: { current: false },
  setTree: () => {},
  mutateTree: () => new Promise(() => {}),
  campaignItemsMutationRequest: () => new Promise(() => {}),
});
