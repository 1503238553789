import { FC } from 'react';

import { HeadingDataModule } from 'models';

import { StyledHeadingPreview } from './HeadingPreview.styled';

export type HeadingPreviewProps = HeadingDataModule;

const HeadingPreview: FC<HeadingPreviewProps> = ({ title }) => {
  return (
    <StyledHeadingPreview className="esg-header-preview">
      {title}
    </StyledHeadingPreview>
  );
};

export default HeadingPreview;
