import { elevate_l, theme } from '@faxi/web-component-library';
import { flex, fontSize, position } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSectionCard = styled.div`
  ${flex('column', 'space-between', 'flex-start')};
  ${elevate_l};

  width: 100%;
  cursor: pointer;
  position: relative;
  border: ${theme.sizes.SIZE_1} solid var(--SHADE_1_8);
  padding: ${theme.sizes.SIZE_12};
  border-radius: ${theme.sizes.SIZE_8};
  gap: ${theme.sizes.SIZE_8};
  min-height: ${theme.sizes.SIZE_112};

  .esg-section-card {
    &__header {
      ${flex('row', 'flex-start', 'flex-start')};

      width: 100%;
      position: relative;
      gap: ${theme.sizes.SIZE_10};

      .wcl-icon {
        height: ${theme.sizes.SIZE_32};
        font-size: ${theme.sizes.SIZE_24};
      }

      &__title {
        ${fontSize(theme.fontSizes.FONT_24, theme.sizes.SIZE_32)};
        font-weight: 600;
      }
    }

    &__content {
      ${flex('row', 'space-between', 'center')};
      width: 100%;
      flex-wrap: wrap;
      gap: ${theme.sizes.SIZE_12};
    }

    &__delete-btn {
      height: fit-content;
      min-height: unset;
      ${position(
        'absolute',
        `bottom ${theme.sizes.SIZE_12} right ${theme.sizes.SIZE_12}`
      )}
    }

    &__menu {
      ${position(
        'absolute',
        `top ${theme.sizes.SIZE_12} right ${theme.sizes.SIZE_12}`
      )}
    }
  }
`;

export { StyledSectionCard };
