import { FC } from 'react';

import InlineEditable from '../../../../_molecules/InlineEditable';
import { HeadingDataModule } from 'models';
import { StyledModuleContainer } from '../../styles/ModuleContainer.styled';
import { InlineModuleElement } from '../ModuleElement/ModuleElement.component';

export type HeadingCanvasModuleProps = InlineModuleElement<HeadingDataModule>;

const HeadingCanvasModule: FC<HeadingCanvasModuleProps> = (props) => {
  return (
    <StyledModuleContainer className="esg-heading-canvas-module">
      <InlineEditable
        data-no-dnd="true"
        placeholderText={'Click to enter/edit heading'}
        {...props}
      />
    </StyledModuleContainer>
  );
};

export default HeadingCanvasModule;
