import { KeyboardSensor } from '@dnd-kit/core';
import { handler } from '../utils';

export class SmartKeyboardSensor extends KeyboardSensor {
  static activators = [
    {
      eventName: 'onKeyDown',
      handler,
    },
  ] as (typeof KeyboardSensor)['activators'];
}
