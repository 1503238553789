import { Input } from '@faxi/web-component-library';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetSessions } from 'api';
import { SessionCard } from 'components';
import Icon from 'components/Icon';
import { BlockUI } from 'helpers';
import { useDebouncedValue } from 'hooks';

import { StyledTasks } from './Tasks.styled';

export type TasksProps = {};

const Tasks: FC<TasksProps> = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebouncedValue(search, 500);

  const { sessions, isLoading } = useGetSessions({
    search: debouncedSearch,
  });

  return (
    <StyledTasks className="esg-tasks" title="Tasks" direction="column">
      <Input
        className="esg-tasks__search-input"
        prefixIcon={<Icon name="magnifying-glass" />}
        placeholder="Search"
        value={search}
        onChange={setSearch}
      />

      <BlockUI loading={isLoading} fallbackCondition={!sessions?.length}>
        {sessions?.map((session) => (
          <SessionCard
            key={session.name}
            session={session}
            onSelect={() => navigate(session.id)}
          />
        ))}
      </BlockUI>
    </StyledTasks>
  );
};

export default Tasks;
