const STORAGE_KEYS = {
  USER: 'ESG_USER',
  ROLE: 'ESG_ROLE',
  TOKEN: 'ESG_TOKEN',
  USERS_MOCK: 'USERS_MOCK',

  SESSION_TOKEN: 'SESSION_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
};

export default {
  ram: {} as Record<string, any>,
  setItem(key: keyof typeof STORAGE_KEYS, value: Record<string, any> | string) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      this.ram[key] = JSON.stringify(value);
    }
  },
  removeItem(key: keyof typeof STORAGE_KEYS): void {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      this.ram[key] = undefined;
    }
  },
  getItem<T>(key: keyof typeof STORAGE_KEYS): T {
    try {
      return JSON.parse(localStorage.getItem(key) as string);
    } catch (e) {
      return JSON.parse(this.ram[key]);
    }
  },
};
