import {
  Button,
  GlowScroll,
  Input,
  ModalRef,
  useModalUtilities,
} from '@faxi/web-component-library';
import { FC, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import { EntityFormModal, SessionCard } from 'components';
import Icon from 'components/Icon';
import { APP_URI } from 'config';
import { BlockUI } from 'helpers';
import { SessionData } from 'models';
import { useSessionsData } from 'store/SessionsData';
import { useGetSessionsProgress } from '../../api/hooks/sessions';
import { CampaignSessionStatus, FORM_STATUS } from '../../models/Session';
import { StyledSessions } from './Sessions.styled';
import { mockProgressAndApproval } from './utils';

export type NewSessionForm = {
  name: string;
  description: string;
};

const Sessions: FC = () => {
  const { open, openModal, closeModal } = useModalUtilities();
  const {
    isLoading,
    sessions,
    search,
    setSearch,
    addSession,
    isAddSessionMutating,
  } = useSessionsData();

  const { data: progressArray, isLoading: isProgressLoading } =
    useGetSessionsProgress();

  const modalRef = useRef<ModalRef>(null);
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (data: NewSessionForm) => {
      await addSession(data);
      modalRef?.current?.close();
    },
    [addSession]
  );

  const onSelectCard = useCallback(
    (session: SessionData) => {
      const isSessionActive = session.status === CampaignSessionStatus.Active;
      const basePage = !isSessionActive ? 'SESSION_RUN' : 'SESSION_DASHBOARD';

      navigate(`${session.id}/${APP_URI[basePage]}`);
    },
    [navigate]
  );

  return (
    <StyledSessions
      title="Sessions"
      direction="column"
      className="esg-sessions"
      padding={0}
    >
      <GlowScroll variant="gray">
        <div className="esg-sessions__container">
          <Button
            icon={<Icon name="plus" />}
            variant="outline"
            onClick={openModal}
          >
            Create a new session
          </Button>

          <Input
            className="esg-sessions__search-input"
            prefixIcon={<Icon name="magnifying-glass" />}
            placeholder="Search"
            value={search}
            onChange={setSearch}
          />

          {open && (
            <EntityFormModal
              ref={modalRef}
              renderAsPortal
              title="New session"
              conditionallyControlled
              onClose={closeModal}
              onSubmit={onSubmit}
              loading={isAddSessionMutating}
              fieldsConfiguration={{
                NAME: true,
                DESCRIPTION: true,
                EMAIL: false,
                ROLE: false,
                TYPE: false,
              }}
            />
          )}
          <div
            className={classNames('esg-sessions__container__list', {
              'esg-sessions__container__list--no-data': !sessions.length,
            })}
          >
            <BlockUI
              loading={isLoading || isProgressLoading}
              fallbackCondition={!sessions.length}
            >
              {sessions.map((session) => {
                const progress = progressArray?.find(
                  (progress) => progress.id === session.id
                );

                const sessionProgress = {
                  currentStep: progress ? progress.completedElements : 0,
                  numberOfSteps: progress ? progress.totalElements : 1,
                  status:
                    progress?.progress === 100
                      ? FORM_STATUS.COMPLETED
                      : FORM_STATUS.IN_PROGRESS,
                };

                return (
                  <SessionCard
                    onSelect={() => onSelectCard(session)}
                    key={session.id}
                    session={{
                      ...session,
                      progress: sessionProgress,
                      ...(session.status === CampaignSessionStatus.Active && {
                        approval: mockProgressAndApproval(false),
                      }),
                    }}
                  />
                );
              })}
            </BlockUI>
          </div>
        </div>
      </GlowScroll>
    </StyledSessions>
  );
};

export default Sessions;
