import { FC, PropsWithChildren } from 'react';
import { FormField } from '@faxi/web-form';
import { SwitchField } from '../../../../components';
import { SwitchDataModule } from '../../../../models';

import { StyledSwitchFieldConfiguration } from './SwitchFieldConfiguration.styled';

export type SwitchFieldConfigurationProps = PropsWithChildren<SwitchDataModule>;

const SwitchFieldConfiguration: FC<SwitchFieldConfigurationProps> = (props) => {
  return (
    <StyledSwitchFieldConfiguration className="esg-switch-field-configuration">
      <p>Here you can configure default value for switch</p>
      <FormField name="defaultValue" component={SwitchField} />
    </StyledSwitchFieldConfiguration>
  );
};

export default SwitchFieldConfiguration;
