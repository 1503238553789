import { FC } from 'react';
import { DividerDataModule } from 'models';
import { StyledModuleContainer } from '../../styles/ModuleContainer.styled';
import { Divider } from '@faxi/web-component-library';

export type DividerCanvasModuleProps = DividerDataModule;

const DividerCanvasModule: FC<DividerCanvasModuleProps> = () => {
  return (
    <StyledModuleContainer className="esg-divider-canvas-module">
      <Divider />
    </StyledModuleContainer>
  );
};

export default DividerCanvasModule;
