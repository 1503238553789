import { ReactNode, FC, useContext, useState, useCallback } from 'react';
import CampaignItemContext, {
  CampaignItemContextProps,
} from './CampaignItem.context';
import api from 'api';
import { CampaignItem } from 'models';

type CampaignItemProviderProps = {
  children?: ReactNode;
};

export type CampaignItemNameDesc = {
  name: string;
  description?: string;
  type?: string;
};

const CampaignItemProvider: FC<CampaignItemProviderProps> = (props) => {
  const { children } = props;

  const [campaignItem, setCampaignItem] = useState<CampaignItem>();

  const { request, isMutating: mutating } = api.useMutation(
    `/campaign-items/${campaignItem?.id}`
  );

  const editCampaignItem = useCallback(
    async (data: Partial<CampaignItem>) => {
      if (data.name) {
        delete data.name;
        delete data.type;
      }

      await request('PATCH', { data });
    },
    [request]
  );

  const removeActiveCampaignItem = useCallback(() => {
    setCampaignItem(undefined);
  }, []);

  return (
    <CampaignItemContext.Provider
      value={{
        mutating,
        campaignItem,
        setCampaignItem,
        removeActiveCampaignItem,
        editCampaignItem,
      }}
    >
      {children}
    </CampaignItemContext.Provider>
  );
};

const useCampaignItem = (): CampaignItemContextProps =>
  useContext(CampaignItemContext);

export { CampaignItemProvider, useCampaignItem };
