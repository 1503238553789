import { fontSize } from '@faxi/web-css-utilities';
import { theme } from '@faxi/web-component-library';
import styled from 'styled-components';

import BaseLayout from '../BaseLayout';

const StyledSessionLayout = styled(BaseLayout)`
  .wcl-divider {
    margin: ${theme.sizes.SIZE_24} 0;
  }

  .esg-session-layout {
    &__session-title {
      ${fontSize(theme.fontSizes.FONT_34, theme.sizes.SIZE_48)};

      font-weight: 400;
      margin: ${theme.sizes.SIZE_20} 0 0 0;
    }

    &__tree-view {
      .esg-collapsible-side-panel {
        height: 100%;
        padding: ${theme.sizes.SIZE_32};
      }
    }

    &__content {
      flex: 1;
      display: grid;
      overflow: auto;
      padding: ${theme.sizes.SIZE_32} ${theme.sizes.SIZE_48};
    }

    &__assigned-to {
      ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};

      font-weight: 600;
      margin: 0 0 ${theme.sizes.SIZE_20};
    }
  }
`;

export { StyledSessionLayout };
