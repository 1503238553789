import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledUploadPreview = styled.div`
  ${flex('row', 'space-between', 'center')};
  ${fontSize(theme.fontSizes.FONT_14)};
  padding: 2px 0;

  .esg-upload-preview {
    &__file-input {
      width: ${theme.sizes.SIZE_256};

      label {
        justify-content: center;
      }
    }
  }
`;

export { StyledUploadPreview };
