import {
  DndContext,
  DragEndEvent,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useModalUtilities, useUtilities } from '@faxi/web-component-library';
import { FC, useCallback, useContext, useMemo, useState } from 'react';

import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  CanvasModuleType,
  DataModuleEnum,
  IDataModule,
  ModuleConfig,
  ModuleConfigType,
} from 'models';
import ModuleConfiguration from '../../../pages/Campaigns/components/ModuleConfiguration';
import CampaignItemContext from '../../../pages/Campaigns/context/CampaignItem/CampaignItem.context';
import { useFormBuilder } from '../../../pages/Campaigns/context/FormBuilder';
import { prepareCampaignFormObject } from '../../../pages/Campaigns/utils';
import { SmartKeyboardSensor } from './classes/SmartKeyboardSensor/SmartKeyboardSensor.class';
import { SmartPointerSensor } from './classes/SmartPointerSensor/SmartPointerSensor.class';
import { SmartTouchSensor } from './classes/SmartTouchSensor/SmartTouchSensor.class';
import CanvasModule from './components/CanvasModule';
import ModuleElement from './components/ModuleElement';

import { dataModulesTextMapper } from '../../../pages/Campaigns/components/BuilderTools/components/DataModulesTool/constants';
import { StyledBuilderCanvas } from './BuilderCanvas.styled';

const BuilderCanvas: FC = () => {
  const { modules, setModules, duplicateModule } = useFormBuilder();
  const { open, openModal, closeModal } = useModalUtilities();

  const [activeModuleConfig, setActiveModuleConfig] =
    useState<CanvasModuleType>();

  const sensors = useSensors(
    useSensor(SmartPointerSensor),
    useSensor(SmartTouchSensor),
    useSensor(SmartKeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const { showOverlay, hideOverlay, showSnackBar } = useUtilities();

  const { editCampaignItem } = useContext(CampaignItemContext);

  const itemIds = useMemo(() => modules.map(({ id }) => id), [modules]);

  const handleDragEnd = useCallback(
    async ({ active, over }: DragEndEvent) => {
      if (!(active && over)) return;

      if (active.id !== over.id) {
        showOverlay('.esg-sub-sub-topic');
        try {
          const oldIndex = modules.findIndex(({ id }) => id === active.id);
          const newIndex = modules.findIndex(({ id }) => id === over.id);
          const newArr = arrayMove(modules, oldIndex, newIndex);

          setModules(newArr);

          await editCampaignItem?.(prepareCampaignFormObject(newArr));

          showSnackBar({
            text: 'Successfully reordered elements.',
            variant: 'success',
            actionButtonText: 'Dismiss',
          });
        } catch (e) {
          console.error(e);
        } finally {
          hideOverlay('.esg-sub-sub-topic');
        }
      }
    },
    [
      editCampaignItem,
      hideOverlay,
      modules,
      setModules,
      showOverlay,
      showSnackBar,
    ]
  );

  const handleDeleteModule = useCallback(
    async (module: Omit<IDataModule, 'created_by'>) => {
      showOverlay('.esg-sub-sub-topic');

      const newArr = modules.filter((el) => el.id !== module.id);

      try {
        await editCampaignItem?.(prepareCampaignFormObject(newArr));
        setModules(newArr);

        showSnackBar({
          text: `Successfully deleted ${dataModulesTextMapper[module.type].toLowerCase()} ${module.title}.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('.esg-sub-sub-topic');
      }
    },
    [
      editCampaignItem,
      hideOverlay,
      modules,
      setModules,
      showOverlay,
      showSnackBar,
    ]
  );

  const handleUpdateModule = useCallback(
    (module: ModuleConfig<ModuleConfigType, DataModuleEnum>) => {
      setModules((old) => {
        const updatedModules = old.map((m) =>
          m.id === module.id ? { ...m, ...module } : m
        );

        editCampaignItem?.(prepareCampaignFormObject(updatedModules));
        return updatedModules;
      });
    },
    [editCampaignItem, setModules]
  );

  return (
    <StyledBuilderCanvas className="esg-builder-canvas">
      {!modules.length ? (
        <p>
          There are no modules, add your first module from the menu on the
          right.
        </p>
      ) : (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
        >
          <SortableContext
            items={itemIds}
            strategy={verticalListSortingStrategy}
          >
            {modules.map((module) => (
              <CanvasModule
                module={module}
                key={module.id}
                id={module.id}
                onConfig={(module) => {
                  setActiveModuleConfig(module);
                  openModal();
                }}
                onClickDelete={() => {
                  handleDeleteModule(module);
                }}
                onDuplicate={duplicateModule}
              >
                <ModuleElement
                  type={module.type}
                  module={module}
                  onTitleSave={handleUpdateModule}
                />
              </CanvasModule>
            ))}
          </SortableContext>

          {open && activeModuleConfig && (
            <ModuleConfiguration
              moduleConfig={activeModuleConfig}
              onSubmit={handleUpdateModule}
              onClose={closeModal}
            />
          )}
        </DndContext>
      )}
    </StyledBuilderCanvas>
  );
};

export default BuilderCanvas;
