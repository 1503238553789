import { FC, PropsWithChildren } from 'react';
import { GlowScroll } from '@faxi/web-component-library';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames';

import Header from '../Header';

import { StyledBaseLayout } from './BaseLayout.styled';

const DEFAULT_PADDING = 32;
const DEFAULT_DIRECTION = 'row';

export type BaseLayoutProps = PropsWithChildren<{
  title: string;
  className?: string;
  direction?: 'row' | 'column';
  padding?: number;
}>;

const BaseLayout: FC<BaseLayoutProps> = (props) => {
  const {
    children,
    title,
    className,
    padding = DEFAULT_PADDING,
    direction = DEFAULT_DIRECTION,
  } = props;

  return (
    <StyledBaseLayout
      id="main"
      className={classNames('esg-base-layout', className)}
    >
      <Header title={title} />

      <GlowScroll variant="gray">
        <div
          className="esg-base-layout__content"
          style={{ flexDirection: direction, padding }}
        >
          {children ?? <Outlet />}
        </div>
      </GlowScroll>
    </StyledBaseLayout>
  );
};

export default BaseLayout;
