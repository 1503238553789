import useSWR, { SWRConfiguration } from 'swr';

import { CompanyCampaignsAPI } from 'models';

const useCompanyCampaigns = (shouldFetch = true, config?: SWRConfiguration) =>
  useSWR<CompanyCampaignsAPI, Error>(
    shouldFetch && '/company-campaigns',
    config
  );

export default useCompanyCampaigns;
