import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledBuilderCanvas = styled.div`
  ${flex('column')};
  padding: ${theme.sizes.SIZE_32} 0;
  gap: ${theme.sizes.SIZE_12};

  .esg-builder-canvas {
  }
`;

export { StyledBuilderCanvas };
