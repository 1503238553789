import classNames from 'classnames';
import { FC, memo } from 'react';

import Icon from 'components/Icon';

export type FolderIconProps = { isOpen: boolean; onClick?: () => void };

const FolderIcon: FC<FolderIconProps> = ({ isOpen, onClick }) => (
  <>
    <Icon
      className={classNames('esg-chavron-icon', {
        'esg-chavron-icon--open': isOpen,
      })}
      name="chevron-right"
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
      }}
    />
    <Icon className="esg-folder-icon" name={`folder${isOpen ? '-open' : ''}`} />
  </>
);

export default memo(FolderIcon);
