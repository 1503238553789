import { FormField, useFormContextValues } from '@faxi/web-form';
import dayjs from 'dayjs';
import { FC } from 'react';

import { CalendarField } from 'components';
import { InputDataModule, InputModuleConfig } from 'models';
import QuickConfigPreview from '../QuickConfigPreview';
import { SimpleInputField } from '../SimpleInput/SimpleInput.component';

import { StyledDateInput } from './DateInput.styled';

export type DateInputProps = InputDataModule;

const DateInput: FC<DateInputProps> = (props) => {
  const values = useFormContextValues(
    'minDate',
    'maxDate'
  ) as InputModuleConfig;

  return (
    <StyledDateInput className="esg-date-input">
      <QuickConfigPreview {...props} />

      <SimpleInputField
        switchLabel="Tooltip helper text"
        configKey="helperText"
        placeholder="Helper text"
        config={props.config as InputModuleConfig}
      />

      <SimpleInputField
        switchLabel="Custom placeholder"
        configKey="placeholder"
        placeholder="Placeholder"
        config={props.config as InputModuleConfig}
      />

      <div className="esg-date-input__dates">
        <p className="esg-date-input__dates__title">Date range</p>

        <div className="esg-date-input__dates__date-range">
          <FormField
            name="minDate"
            component={CalendarField}
            placeholder="Min date"
            rightLimitDate={dayjs(values.maxDate)}
            openAtToday
          />
          <span>-</span>
          <FormField
            name="maxDate"
            component={CalendarField}
            placeholder="Max date"
            leftLimitDate={dayjs(values.minDate)}
            openAtToday
          />
        </div>
      </div>
    </StyledDateInput>
  );
};

export default DateInput;
