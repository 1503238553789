import { FormField } from '@faxi/web-form';
import { FC, useMemo } from 'react';

import { CheckboxListField } from 'components';
import { CheckListDataModule, DataModuleEnum } from 'models';
import { generateOptionsByLabels, generatePreviewFieldName } from 'utils';

import { StyledChecklistPreview } from './ChecklistPreview.styled';

export type ChecklistPreviewProps = CheckListDataModule;

const ChecklistPreview: FC<ChecklistPreviewProps> = ({ title, id, config }) => {
  const finalOptions = useMemo(
    () => generateOptionsByLabels(config?.options as { label: string }[]),
    [config]
  );

  return (
    <StyledChecklistPreview className="esg-checklist-preview">
      <p>{title || 'Checklist'}</p>
      <div>
        <FormField
          name={generatePreviewFieldName(id, DataModuleEnum.CHECKLIST)}
          component={CheckboxListField}
          options={finalOptions}
        />
      </div>
    </StyledChecklistPreview>
  );
};

export default ChecklistPreview;
