import { theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledCampaignsHeader = styled.div`
  border-bottom: ${theme.sizes.SIZE_1} solid var(--GRAY-EF);

  .esg-campaigns-header {
    &__breadcrumbs {
      a {
        ${fontSize(theme.fontSizes.FONT_14)};
        text-decoration: none;
      }
    }

    &__header {
      ${flex('row', 'flex-start', 'center')};

      gap: ${theme.sizes.SIZE_16};
      margin-top: ${theme.sizes.SIZE_12};

      .wcl-icon {
        > svg {
          ${size(theme.sizes.SIZE_40)};
        }
      }

      h2 {
        ${fontSize(theme.fontSizes.FONT_34)};
        font-weight: 400;
        margin: 0;
      }
    }

    &__description {
      margin-bottom: ${theme.sizes.SIZE_24};
    }
  }

  .esg-folder-description {
    margin-bottom: ${theme.sizes.SIZE_12};
  }
`;

export { StyledCampaignsHeader };
