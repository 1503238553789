import { FC } from 'react';
import { DataModuleEnum } from 'models';

import { ModuleConfig } from '../../../models/DataModule';
import InputFieldConfiguration from './InputFieldConfiguration';
import DropdownFieldConfiguration from './DropdownFieldConfiguration';
import ChoiceFieldConfiguration from './ChoiceFieldConfiguration';
import CheckListFieldConfiguration from './CheckListFieldConfiguration';
import SwitchFieldConfiguration from './SwitchFieldConfiguration';
import IntegrationFieldConfiguration from './IntegrationFieldConfiguration';

type ModuleMapperType<T = any> = Partial<{
  [K in DataModuleEnum]: FC<ModuleConfig<T, K>> | null;
}>;

export const configurableModules: ModuleMapperType = {
  [DataModuleEnum.INPUT]: InputFieldConfiguration,
  [DataModuleEnum.CHOICE]: ChoiceFieldConfiguration,
  [DataModuleEnum.CHECKLIST]: CheckListFieldConfiguration,
  [DataModuleEnum.DROPDOWN]: DropdownFieldConfiguration,
  [DataModuleEnum.SWITCH]: SwitchFieldConfiguration,
  [DataModuleEnum.INTEGRATION]: IntegrationFieldConfiguration,
};
