import { FC, PropsWithChildren } from 'react';
import { ProgressBar } from '@faxi/web-component-library';
import classNames from 'classnames';

import { ApprovalStatus } from 'models';
import { FORM_STATUS } from '../../../models/Session';

import { StyledApprovalProgressBar } from './ApprovalProgressBar.styled';
import { ApprovalChip } from './components';

export type ApprovalProgressBarProps = PropsWithChildren<{
  status: ApprovalStatus;
  title?: string;
  progress?: number;
  className?: string;
}>;

const ApprovalProgressBar: FC<ApprovalProgressBarProps> = ({
  status,
  title,
  className,
  progress,
  ...rest
}) => {
  const progressBarBgColor = `var(--WHITE-FF${status === FORM_STATUS.IN_PROGRESS ? '-NOT-STARTED' : ''})`;

  const shouldShowProgressBar = FORM_STATUS.IN_PROGRESS === status;

  return (
    <StyledApprovalProgressBar
      className={classNames('esg-approval-progress-bar', className)}
    >
      {title && <p className="esg-approval-progress-bar__title">{title}</p>}

      {shouldShowProgressBar ? (
        <ProgressBar
          color="var(--BLUE-C3)"
          variant="rich"
          currentStep={progress || 0}
          numberOfSteps={100}
          bgColor={progressBarBgColor}
          {...rest}
        />
      ) : (
        <ApprovalChip status={status} />
      )}
    </StyledApprovalProgressBar>
  );
};

export default ApprovalProgressBar;
