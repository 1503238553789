import { Navigate, RouteObject } from 'react-router-dom';

import { ErrorBoundary } from 'components';
import { APP_URI } from 'config';
import { BaseLayout, CreateCampaignLayout, MainLayout } from 'layouts';

import {
  campaignsRoutes,
  sessionsRoutes,
  authRoutes,
  orgRoutes,
  tasksRoutes,
} from './subrouters';

import { AuthRouter } from './subrouters';

import SessionsLayout from '../layouts/SessionsLayout';
import { FormBuilderProvider } from '../pages/Campaigns/context/FormBuilder';
import { UserRole } from '../models';
import { CampaignProvider } from '../store/CampaignProvider';
import { CampaignItemProvider } from '../pages/Campaigns/context/CampaignItem';
import { OrganisationProvider } from './subrouters/Organisations/providers/Organisation';
import { Users } from '../pages';
import storageService from '../services/storageService';
import { USER_ROLE } from '../models/User';

const checkFallbackUrl = () => {
  const userRoles = storageService.getItem<UserRole[]>('ROLE');

  return userRoles.includes(USER_ROLE.REGULAR_USER)
    ? APP_URI.TASKS
    : APP_URI.SESSIONS;
};

export type ExtendRouteObject = RouteObject & {
  authorized?: boolean;
  onlyPublic?: boolean;
  roles?: UserRole[]; // define user roles
  fallbackUrl?: () => string | string;
  children?: ExtendRouteObject[];
};

export default [
  {
    path: APP_URI.AUTH,
    onlyPublic: true,
    authorized: false,
    element: <AuthRouter />,
    children: authRoutes,
  },
  {
    path: APP_URI.BASE,
    authorized: true,
    element: <MainLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: APP_URI.ACCOUNT_SETTINGS,
        element: <BaseLayout title="Account settings" />,
      },
      {
        roles: ['admin', 'super-admin'],
        path: APP_URI.SESSIONS,
        fallbackUrl: APP_URI.SESSIONS,
        element: <SessionsLayout />,
        children: sessionsRoutes,
      },
      {
        roles: ['super-admin'],
        path: APP_URI.ORGANISATIONS,
        fallbackUrl: APP_URI.SESSIONS,
        children: orgRoutes,
        element: <OrganisationProvider />,
      },
      {
        roles: ['super-admin'],
        path: APP_URI.CAMPAIGNS,
        fallbackUrl: checkFallbackUrl,
        element: (
          <CampaignProvider>
            <CampaignItemProvider>
              <FormBuilderProvider>
                <CreateCampaignLayout />
              </FormBuilderProvider>
            </CampaignItemProvider>
          </CampaignProvider>
        ),
        children: campaignsRoutes,
      },
      {
        roles: ['super-admin', 'admin'],
        path: APP_URI.USERS,
        element: <Users />,
      },
      {
        roles: ['user'],
        path: APP_URI.TASKS,
        children: tasksRoutes,
      },
      {
        index: true,
        element: <Navigate to={APP_URI.CAMPAIGNS} />,
      },
      {
        path: '*',
        element: <Navigate to="/" replace />,
      },
    ],
  },
] as ExtendRouteObject[];
