import {
  ButtonProps,
  IName,
  StatusElement,
  StatusElementProps,
} from '@faxi/web-component-library';
import classNames from 'classnames';
import { FC, memo, ReactNode, useMemo, MouseEvent } from 'react';

import Icon, { INameExtended } from '../../Icon';
import { FolderDescription, ThreeDotMenu } from 'components';

import { StyledSectionCard } from './SectionCard.styled';

type EventCardType = (event: MouseEvent) => void;

export type SectionCardProps = {
  className?: string;
  prefixElement?: ReactNode;
  status?: StatusElementProps<INameExtended>;
  title: string;
  type?: string;
  description?: string;
  withMenu?: boolean;
  menuItems?: ButtonProps[];
  hasActions?: boolean;
  onSelect?: () => void;
  onEdit?: EventCardType;
  onDelete?: EventCardType;
  onDuplicate?: EventCardType;
  onAddDescriptionCallback?: EventCardType;
};

const SectionCard: FC<SectionCardProps> = (props) => {
  const {
    prefixElement = <Icon name="file" />,
    title,
    description,
    status,
    withMenu = false,
    menuItems: pMenuItems,
    hasActions = true,
    className,
    onEdit,
    onDelete,
    onSelect,
    onAddDescriptionCallback,
  } = props;

  const menuItems = useMemo(
    () =>
      pMenuItems ||
      ([
        {
          children: 'Edit',
          icon: <Icon name="pen" />,
          variant: 'ghost',
          iconPosition: 'left',
          onClick: (e) => {
            e.stopPropagation();
            onEdit?.(e);
          },
        },
        {
          children: 'Delete',
          icon: <Icon name="trash-can" className="color-secondary" />,
          iconPosition: 'left',
          variant: 'delete-ghost',
          onClick: (e) => {
            e.stopPropagation();
            onDelete?.(e);
          },
        },
      ] as ButtonProps[]),
    [pMenuItems, onDelete, onEdit]
  );

  return (
    <StyledSectionCard
      onClick={onSelect}
      className={classNames('esg-section-card', 'hover-effect', className)}
    >
      <div className="esg-section-card__header">
        {prefixElement}
        <div className="esg-section-card__header__title">{title}</div>
      </div>

      <div className="esg-section-card__content">
        <FolderDescription
          description={description}
          hasActions={hasActions}
          onChange={onAddDescriptionCallback}
        />

        {status && (
          <StatusElement
            className="esg-section-card__status"
            {...(status as StatusElementProps<IName>)}
          />
        )}
      </div>

      {withMenu && (
        <ThreeDotMenu
          className="esg-section-card__menu"
          menuItems={menuItems}
        />
      )}
    </StyledSectionCard>
  );
};

export default memo(SectionCard);
