import { FC, LegacyRef, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { ButtonProps, Image, MenuRef } from '@faxi/web-component-library';

import Icon from '../../Icon';
import ThreeDotMenu from '../ThreeDotMenu';
import { Contributor } from '../AssignContributors/AssignContributors.component';

import { StyledAssignedContributor } from './AssignedContributor.styled';

export type AssignedContributorProps = PropsWithChildren<
  Contributor & {
    isAdmin: boolean;
    menuItems?: ButtonProps[];
    menuRef?: LegacyRef<MenuRef>;
    onClick?: () => void;
  }
>;

const AssignedContributor: FC<AssignedContributorProps> = (props) => {
  const { menuRef, src, isAdmin, name, menuItems, onClick } = props;

  return (
    <StyledAssignedContributor
      role="button"
      className={classNames('esg-assigned-contributor', {
        'selectable': !!onClick,
      })}
      onClick={onClick}
    >
      <div>
        <Image src={src} alt={`Contributor ${name}`} />
        <p>{name}</p>
        {isAdmin && <Icon color="var(--BLUE-06)" name="crown" />}
      </div>

      {menuItems && (
        <ThreeDotMenu
          ref={menuRef}
          className="esg-assigned-contributor__menu"
          menuItems={menuItems}
        />
      )}
    </StyledAssignedContributor>
  );
};

export default AssignedContributor;
