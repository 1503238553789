import { FC, PropsWithChildren, useState, MouseEvent } from 'react';
import { TabOption, Tabs } from '@faxi/web-component-library';

import { Each } from 'helpers';
import { TreeNodeElement } from 'models';
import { EmptyFolder } from 'components';
import { INameExtended } from 'components/Icon';
import Icon from 'components/Icon';
import SectionCard from '../SectionCard';

import { StyledFoldersPreview } from './FoldersPreview.styled';

type FoldersView = 'list' | 'grid';

const previewTabs = [
  { iconName: 'grip', value: 'grid' },
  { iconName: 'bars-progress', value: 'list' },
] as TabOption<FoldersView, INameExtended>[];

export type FoldersPreviewProps = PropsWithChildren<{
  folder?: TreeNodeElement;
  onSelect?: (folder: TreeNodeElement) => void;
  onEdit?: (folder: TreeNodeElement, event: MouseEvent) => void;
  onDelete?: (folder: TreeNodeElement, event: MouseEvent) => void;
}>;

const FoldersPreview: FC<FoldersPreviewProps> = (props) => {
  const { folder, onSelect, onEdit, onDelete } = props;

  const [view, setView] = useState<FoldersView>('grid');
  if (!folder?.children?.length) return <EmptyFolder />;

  return (
    <StyledFoldersPreview className="esg-folders-preview">
      {folder.children.length > 1 && (
        <Tabs<FoldersView>
          value={view}
          className="esg-folders-preview__tabs"
          label="Choose view "
          labelPosition="right"
          tabs={previewTabs}
          onChange={setView}
        />
      )}

      <Each
        containerAs="div"
        className="esg-folders-preview__container"
        data-view={view}
        of={folder.children || []}
        render={(folder) => (
          <SectionCard
            withMenu
            title={folder.name!}
            description={folder.description}
            prefixElement={<Icon name="folder" />}
            onSelect={() => onSelect?.(folder)}
            onEdit={(e) => onEdit?.(folder, e)}
            onDelete={(e) => onDelete?.(folder, e)}
            onAddDescriptionCallback={(e) => onEdit?.(folder, e)}
          />
        )}
      />
    </StyledFoldersPreview>
  );
};

export default FoldersPreview;
