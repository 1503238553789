import classNames from 'classnames';
import { FC, PropsWithChildren, memo } from 'react';

import Icon from 'components/Icon';

import { StyledErrorFallback } from './ErrorFallback.styled';

export type ErrorFallbackProps = PropsWithChildren<{
  title?: string;
  className?: string;
}>;

const ErrorFallback: FC<ErrorFallbackProps> = ({
  title = 'No data.',
  className,
}) => (
  <StyledErrorFallback className={classNames('esg-error-fallback', className)}>
    <Icon name="folder-open" />
    <p>{title}</p>
  </StyledErrorFallback>
);

export default memo(ErrorFallback);
