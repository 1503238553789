import { FC, useCallback, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { CollapsibleSidePanel } from 'components';
import { BasicTreeView, Loading } from '../../components';
import { APP_URI } from '../../config';
import { useDeleteCampaignItem, useNavigateUpOnDelete } from '../../hooks';
import authBus from '../../modules/authBus';
import BuilderTools from '../../pages/Campaigns/components/BuilderTools';
import { useCampaignProvider } from '../../store/CampaignProvider';
import BaseLayout from '../BaseLayout';

const CreateCampaignLayout: FC = () => {
  const { pathname } = useLocation();

  const needsTools = useMemo(
    () => pathname.includes('subsubtopics'),
    [pathname]
  );

  const needsTree = useMemo(() => pathname !== APP_URI.CAMPAIGNS, [pathname]);

  const { tree: root } = useCampaignProvider();

  const { campaignItemsMutationRequest, deletedCampaign } =
    useCampaignProvider();

  const onDeleteSubmit = useCallback(
    async (id: string) => {
      deletedCampaign.current = true;
      await campaignItemsMutationRequest('DELETE', {
        params: { id },
      });
      authBus.broadcastEvent('delete_campaign_entity', { id });
    },
    [campaignItemsMutationRequest, deletedCampaign]
  );

  const { onDelete } = useDeleteCampaignItem(onDeleteSubmit);

  const { handleNavigate } = useNavigateUpOnDelete();

  return (
    <BaseLayout direction="row" padding={0} title="Campaigns">
      {needsTree && (
        <CollapsibleSidePanel expandDirection="right">
          {root ? (
            <BasicTreeView
              withMenu
              onDelete={async ({ id, name }) => {
                await onDelete(id, name, () => {
                  handleNavigate(id);
                  deletedCampaign.current = false;
                })();
              }}
              data={root}
            />
          ) : (
            <Loading />
          )}
        </CollapsibleSidePanel>
      )}

      <Outlet />

      {needsTools && <BuilderTools className="esg-campaigns__tools" />}
    </BaseLayout>
  );
};

export default CreateCampaignLayout;
