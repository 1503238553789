import { theme } from '@faxi/web-component-library';
import { fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const NoData = styled.p`
  ${fontSize(theme.fontSizes.FONT_18)};
  color: var(--GRAY-54);
`;

export default NoData;
