import { focusStyles, theme } from '@faxi/web-component-library';
import { flex, fontSize, phablet, size } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

const StyledAuth = styled.div`
  ${flex('column', 'center', 'center')}
  ${size('100%')};

  gap: ${theme.sizes.SIZE_10};

  width: fit-content;
  margin: auto;
  border: ${theme.sizes.SIZE_1} solid var(--PRIMARY_2_1);
  height: fit-content;
  border-radius: ${theme.sizes.SIZE_8};
  padding: ${theme.sizes.SIZE_32};

  form {
    ${flex('column')};

    min-width: ${theme.sizes.SIZE_400};

    .input:not(:first-of-type) {
      margin-top: ${theme.sizes.SIZE_24};
    }

    ${phablet(css`
      min-width: 100%;
      padding: 0 ${theme.sizes.SIZE_24};
    `)}
  }

  a {
    ${focusStyles};

    text-align: end;
    text-decoration: none;
    color: var(--PRIMARY_1_1);
  }

  .app-name {
    ${flex('row')};

    gap: ${theme.sizes.SIZE_10};
    margin: 0 0 ${theme.sizes.SIZE_48};
    transform: translateX(-6px);

    img {
      object-fit: contain;
      height: ${theme.sizes.SIZE_64};
    }

    h1 {
      ${fontSize(theme.fontSizes.FONT_30, theme.sizes.SIZE_64)};
      margin: 0 auto;
    }
  }

  .auth-text {
    text-align: center;
    margin-top: ${theme.sizes.SIZE_12};
  }

  .button[type='submit'] {
    width: 100%;
    margin-top: ${theme.sizes.SIZE_48};
  }
`;

export { StyledAuth };
