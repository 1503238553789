import { FC } from 'react';
import { FieldProps, useFieldError } from '@faxi/web-form';
import { Textarea, TextareaProps } from '@faxi/web-component-library';

type ValueOnChange = (event: string) => void;

export type TextareaFieldProps = FieldProps<string, ValueOnChange> &
  TextareaProps;

const TextareaField: FC<TextareaFieldProps> = (props) => {
  const { error, dirty, touched, ...rest } = props;
  const showError = useFieldError(props);

  return (
    <Textarea
      error={showError}
      errorText={String(error)}
      className="esg-textarea-field"
      {...rest}
    />
  );
};

export default TextareaField;
