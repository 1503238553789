import { Navigate, RouteObject } from 'react-router-dom';

import { APP_URI } from 'config';
import {
  RunSession,
  SessionDashboard,
  SessionDashboardCompany,
  SessionLayout,
  SessionReportsEntry,
  SessionReportsReport,
  Sessions,
} from 'pages';

export default [
  { path: '', element: <Sessions /> },
  {
    path: `${APP_URI.SESSIONS_NAME}/${APP_URI.SESSION_RUN}`,
    element: <RunSession />,
  },
  {
    path: APP_URI.SESSIONS_NAME,
    element: <SessionLayout />,
    children: [
      {
        path: '',
        element: <Navigate to={APP_URI.SESSION_DASHBOARD} replace />,
      },
      {
        path: APP_URI.SESSION_DASHBOARD,
        children: [
          {
            path: '',
            element: <SessionDashboard />,
          },
          {
            path: APP_URI.SESSIONS_COMPANY,
            element: <SessionDashboardCompany />,
          },
        ],
      },
      {
        path: APP_URI.SESSION_REPORT,
        children: [
          {
            path: '',
            element: <SessionReportsEntry name="organisation" />,
          },
          {
            path: APP_URI.SESSION_REPORT_COMPANY,
            element: <SessionReportsEntry name="company" />,
          },
          {
            path: APP_URI.SESSION_REPORT_COMPANY_TOPICS_TOPIC,
            element: <SessionReportsEntry name="topic" />,
          },
          {
            path: APP_URI.SESSION_REPORT_COMPANY_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC,
            element: <SessionReportsEntry name="subtopic" />,
          },
          {
            path: APP_URI.SESSION_REPORT_COMPANY_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC,
            element: <SessionReportsReport />,
          },
        ],
      },
    ],
  },
] as RouteObject[];
