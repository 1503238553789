import { FC, useMemo } from 'react';
import { Button } from '@faxi/web-component-library';
import { StyledDataModulesTool } from './DataModulesTool.styled';
import { useFormBuilder } from '../../../../context/FormBuilder';
import classNames from 'classnames';
import { dataModulesTextMapper } from './constants';
import { mapTypeToIcon } from '../../../../utils';
import Icon from 'components/Icon';
import { DataModuleEnum } from 'models';

const DataModulesTool: FC = () => {
  const { selectDataModule, selectedDataModule, addModule, loadingNewModule } =
    useFormBuilder();

  const isAtLeastOneSelected = !!selectedDataModule;

  const dataModuleLi = useMemo(
    () =>
      Object.keys(dataModulesTextMapper).map((el) => {
        const type = el as DataModuleEnum;
        return (
          <li
            key={type}
            tabIndex={0}
            onClick={() => selectDataModule?.(type)}
            onKeyDown={(e) => {
              if (e.code === 'Space') {
                e.preventDefault();
                selectDataModule?.(type);
              }
            }}
            className={classNames('esg-data-modules-tool__list__li', {
              'esg-data-modules-tool__list__li--active':
                selectedDataModule === type,
            })}
          >
            <Icon name={mapTypeToIcon[type]} />
            <p>{dataModulesTextMapper[type]}</p>
          </li>
        );
      }),
    [selectDataModule, selectedDataModule]
  );

  return (
    <StyledDataModulesTool
      className="esg-data-modules-tool--wrapper"
      open
      body={
        <>
          <Button
            icon={<Icon name="plus" />}
            variant="outline"
            disabled={!isAtLeastOneSelected || loadingNewModule}
            onClick={() => addModule(selectedDataModule as DataModuleEnum)}
          >
            Add module
          </Button>
          <ul className="esg-data-modules-tool__list">{dataModuleLi}</ul>
        </>
      }
      icon={<Icon name="chevron-down" />}
      title="Data module"
      bodyAs="div"
      bodyClassName="esg-data-modules-tool"
    />
  );
};

export default DataModulesTool;
