import { theme } from '@faxi/web-component-library';
import { flex, fontSize, position } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledTreeNode = styled.div<{ $level: number }>`
  ${flex('column', 'flex-start', 'baseline')};
  ${fontSize(theme.fontSizes.FONT_14)};

  width: 100%;
  padding-left: ${({ $level }) => ($level - 1) * 8}px;

  .wcl-checkbox {
    transform: scale(0.7);
  }

  .esg-tree-node-component {
    &__base-node {
      ${flex('row', 'flex-start', 'center')};

      border-radius: 6px;
      width: 100%;
      border-radius: ${theme.sizes.SIZE_8};

      .wcl-checkbox {
        padding-left: ${theme.sizes.SIZE_8};
      }

      &--active {
        background-color: var(--GRAY-EF);
      }
    }

    &__sub-folders {
      width: 100%;
      overflow: hidden;
    }

    &__icon {
      ${flex('row', 'flex-start', 'center')};
      ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_24)};

      gap: ${theme.sizes.SIZE_8};
      position: relative;
      cursor: pointer;
      width: 100%;
      border-radius: 6px;
      padding: ${theme.sizes.SIZE_8};
      padding-right: ${theme.sizes.SIZE_40};

      i {
        padding: ${theme.sizes.SIZE_4};
      }

      &__menu {
        ${position('absolute', 'top 50% right 0')};
        transform: translate3d(0, -50%, 0);
      }

      &--active {
        background-color: var(--SHADE_1_7);
      }
    }
  }
`;

export { StyledTreeNode };
