import { FC, memo, PropsWithChildren, useMemo } from 'react';
import { Button, ButtonProps } from '@faxi/web-component-library';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';

import Icon from 'components/Icon';
import { CanvasModuleType, DataModuleEnum } from 'models';
import { ThreeDotMenu } from 'components/_molecules';
import { mapTypeToIcon } from 'pages/Campaigns/utils';

import { StyledCanvasModule } from './CanvasModule.styled';

const CONFIGURABLE_MODULES = [
  DataModuleEnum.INPUT,
  DataModuleEnum.CHECKLIST,
  DataModuleEnum.CHOICE,
  DataModuleEnum.DROPDOWN,
  DataModuleEnum.SWITCH,
  DataModuleEnum.INTEGRATION,
] as Array<DataModuleEnum>;

export type CanvasModuleProps = PropsWithChildren<{
  module: CanvasModuleType;
  id: string;
  onClickDelete: (id: string) => void;
  onConfig?: (module: CanvasModuleType) => void;
  onDuplicate?: (module: CanvasModuleType) => void;
}>;

const CanvasModule: FC<CanvasModuleProps> = (props) => {
  const { module, id, children, onClickDelete, onConfig, onDuplicate } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: module.id,
    transition: {
      duration: 250,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  });

  const isConfigurable = useMemo(
    () => CONFIGURABLE_MODULES.includes(module.type),
    [module.type]
  );

  const nodeMenuItems = useMemo(
    () =>
      [
        ...(isConfigurable
          ? [
              {
                children: 'Configure',
                icon: <Icon name="wrench" />,
                variant: 'ghost',
                onClick: (e) => {
                  e.stopPropagation();
                  onConfig?.(module);
                },
              } as ButtonProps,
            ]
          : []),

        {
          children: 'Duplicate',
          icon: <Icon name="copy" />,
          variant: 'ghost',
          onClick: (e) => {
            e.stopPropagation();
            onDuplicate?.(module);
          },
        },
        {
          children: 'Delete',
          icon: <Icon name="trash-can" className="color-secondary" />,
          variant: 'delete-ghost',
          onClick: () => onClickDelete(module.id),
        },
      ] as ButtonProps[],
    [isConfigurable, onDuplicate, module, onConfig, onClickDelete]
  );

  return (
    <StyledCanvasModule
      className={classNames('esg-canvas-module', {
        'esg-canvas-module--is-dragging': isDragging,
      })}
      ref={setNodeRef}
      id={id}
      style={{
        transform: transform
          ? CSS.Transform.toString({
              ...transform,
              scaleX: isDragging ? 1.005 : 1,
              scaleY: isDragging ? 1.005 : 1,
            })
          : '',
        transition,
      }}
      {...attributes}
      {...listeners}
    >
      <Button
        icon={<Icon name="grip-dots-vertical" />}
        variant="ghost"
        className="esg-canvas-module__grip"
      />

      <div className="esg-canvas-module__type">
        <Icon name={mapTypeToIcon[module.type]} />
      </div>

      {children}

      <ThreeDotMenu
        data-no-dnd="true"
        className="esg-canvas-module__menu"
        menuItems={nodeMenuItems}
        renderAsPortal
      />
    </StyledCanvasModule>
  );
};

export default memo(CanvasModule);
