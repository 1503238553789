import { FormField } from '@faxi/web-form';
import { FC } from 'react';

import { DataModuleEnum, UploadDataModule } from 'models';
import { generatePreviewFieldName } from 'utils';

import { FileInput } from '../../../../../_atoms';
import { StyledUploadPreview } from './UploadPreview.styled';

export type UploadPreviewProps = UploadDataModule;

const UploadPreview: FC<UploadPreviewProps> = ({ title, id }) => (
  <StyledUploadPreview className="esg-upload-preview">
    <p className="esg-upload-preview__title">{title || 'Upload'}</p>
    <FormField
      name={generatePreviewFieldName(id, DataModuleEnum.UPLOAD)}
      component={FileInput}
      className="esg-upload-preview__file-input"
    />
  </StyledUploadPreview>
);

export default UploadPreview;
