import { Method } from 'axios';
import defaultMutation, {
  MutationArgs,
  RequestArgs,
} from '../../defaultMutation';
import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation';
import { useCallback } from 'react';

const useMutation = <TData = any, TError = Error>(
  url: string,
  swrMutationConfigOptions?: SWRMutationConfiguration<
    TData,
    TError,
    string,
    MutationArgs
  >
) => {
  const { trigger, ...rest } = useSWRMutation<
    TData,
    TError,
    string,
    MutationArgs
  >(url, defaultMutation, swrMutationConfigOptions);

  const request = useCallback(
    async (method: Method, args: RequestArgs): Promise<TData> => {
      try {
        return await trigger({ method, args });
      } catch (err) {
        console.error('Error making request:', err);
        throw err;
      }
    },
    [trigger]
  );

  return {
    request,
    ...rest,
  };
};

export default useMutation;
