import { useMemo } from 'react';
import { useGetSessionProgress } from '../../../api';
import { useParams } from 'react-router-dom';
import {
  convertProgressApiToProgress,
  mockProgressAndApproval,
} from '../utils';
import { FORM_APPROVAL, FORM_STATUS } from '../../../models/Session';

const DEFAULT_SESSION_REPORT_PROGRESS_DATA = {
  icon: 'bars-progress-solid',
  name: 'Report Progress',
  description: 'by Company',
};

const DEFAULT_SESSION_REPORT_PROGRESS_COMPANY_DATA = {
  icon: '/assets/images/kinto-join.png',
};

const DEFAULT_SESSION_REPORT_APPROVAL_DATA = {
  icon: 'bars-progress-solid',
  name: 'Approval Status',
  description: 'by Company',
};

const DEFAULT_TOPIC_ITEMS = {
  items: [
    {
      icon: 'folder',
      name: 'Environment',
      ...mockProgressAndApproval(),
    },
    {
      icon: 'folder',
      name: 'Social',
      ...mockProgressAndApproval(),
    },
    {
      icon: 'folder',
      name: 'Governance',
      ...mockProgressAndApproval(),
    },
  ],
};

const DEFAULT_ORGANISATION_GRID_ELEMENT = {
  icon: '/assets/svg/toyota_dark.svg',
  name: 'Toyota Belgium',
  description: '',
  items: [
    {
      icon: 'folder',
      name: 'Environment',
      currentStep: 17,
      numberOfSteps: 100,
      status: FORM_APPROVAL.APPROVED,
    },
    {
      icon: 'folder',
      name: 'Social',
      currentStep: 87,
      numberOfSteps: 100,
      status: FORM_STATUS.IN_PROGRESS,
    },
    {
      icon: 'folder',
      name: 'Governance',
      currentStep: 0,
      numberOfSteps: 1,
      status: FORM_APPROVAL.TO_REVIEW,
    },
  ],
};

const useSessionDashboardGridCards = () => {
  const { sessionId = '' } = useParams();
  const { data = [], isLoading } = useGetSessionProgress(sessionId);

  const generateApprovalGridElement = useMemo(() => {
    return {
      ...DEFAULT_SESSION_REPORT_APPROVAL_DATA,
      items: [
        ...data.map((companyProgress) => ({
          ...DEFAULT_SESSION_REPORT_PROGRESS_COMPANY_DATA,
          ...mockProgressAndApproval(),
          name: companyProgress.companyName,
        })),
        {
          ...mockProgressAndApproval(),
          icon: '/assets/svg/toyota_dark.svg',
          name: 'Toyota Belgium',
        },
      ],
    };
  }, [data]);

  const generateCompanyCards = useMemo(() => {
    return data.map((companyProgress) => ({
      ...DEFAULT_SESSION_REPORT_PROGRESS_COMPANY_DATA,
      name: companyProgress.companyName,
      description: '',
      ...DEFAULT_TOPIC_ITEMS,
    }));
  }, [data]);

  const generateProgressReportGridElement = useMemo(() => {
    const allSteps = data.reduce(
      (prev, curr) => ({
        completedElements: prev.completedElements + curr.completedElements,
        totalElements: prev.totalElements + curr.totalElements,
      }),
      {
        completedElements: 0,
        totalElements: data?.length ? 0 : 1,
      }
    );

    const toyotaStatus = {
      ...allSteps,
      progress: (allSteps.completedElements * 100) / allSteps.totalElements,
    };
    return {
      ...DEFAULT_SESSION_REPORT_PROGRESS_DATA,
      items: [
        ...data.map((companyProgress) => ({
          ...DEFAULT_SESSION_REPORT_PROGRESS_COMPANY_DATA,
          ...convertProgressApiToProgress(companyProgress),
          name: companyProgress.companyName,
        })),
        {
          ...convertProgressApiToProgress(toyotaStatus),
          icon: '/assets/svg/toyota_dark.svg',
          name: 'Toyota Belgium',
        },
      ],
    };
  }, [data]);

  return {
    data: [
      generateProgressReportGridElement,
      generateApprovalGridElement,
      ...generateCompanyCards,
      DEFAULT_ORGANISATION_GRID_ELEMENT,
    ],
    isLoading,
  };
};

export default useSessionDashboardGridCards;
