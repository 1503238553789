import { createContext, Dispatch, SetStateAction } from 'react';
import { CanvasModuleType, DataModuleEnum, IDataModule } from 'models';

export type FormBuilderContextProps = {
  selectedDataModule?: string;
  selectDataModule?: (dataModule: string) => void;
  modules: Omit<IDataModule, 'created_by'>[];
  setModules: Dispatch<SetStateAction<Omit<IDataModule, 'created_by'>[]>>;
  addModule: (type: DataModuleEnum) => void;
  duplicateModule: (module: CanvasModuleType) => void;
  loadingNewModule?: boolean;
};

export default createContext<FormBuilderContextProps>({
  modules: [],
  setModules: () => {},
  addModule: () => {},
  duplicateModule: () => {},
});
