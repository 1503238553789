import { useUtilities } from '@faxi/web-component-library';
import { FC, useContext } from 'react';
import { useParams } from 'react-router-dom';

import api from 'api';
import { FormFooter } from 'components';
import ModuleElement from 'components/_organisms/BuilderCanvas/components/ModuleElement';
import { BlockUI, Each } from 'helpers';
import { useCallbackAsync, useFormModules } from 'hooks';
import { DataCollectionForm, SessionTree } from 'models';
import { moduleMapper } from 'pages/Campaigns/components/BuilderTools/components/PreviewTool/components/FormBuilderPreviewModal/FormBuilderPreviewModal.component';
import TasksContext from '../../providers/Tasks/Tasks.context';

import { StyledDataCollection } from './DataCollection.styled';

const findModuleValue = (moduleName: string, value: Record<string, any>) => {
  return Object.entries(value).reduce((current, [name, value]) => {
    if (name === moduleName) return { ...current, [name]: value };
    else return current;
  }, {});
};

export type DataCollectionProps = {};

const DataCollection: FC<DataCollectionProps> = () => {
  const { showSnackBar } = useUtilities();

  const params = useParams<{ companyId: string; collectionId: string }>();
  const { activeCampaignId, activeReportNode } = useContext(TasksContext);

  const { request: submitReport } = api.useMutation(
    `/campaign-sessions/${params.companyId}/submit-form`
  );

  const { loadingForm, formData, modules, mutateSessionForm } = useFormModules({
    asAdmin: false,
    sessionId: params.companyId,
    collectionId: params.collectionId,
  });

  const [handleSaveForm] = useCallbackAsync({
    showSpinner: true,
    spinnerParent: '.esg-session-layout__content',
    callback: async (value: Record<string, any>) => {
      const reportData = {
        campaignId: activeCampaignId,
        dataCollectionFormId: modules[0].dataCollectionFormId,
        elements: modules.map(({ elementId, name, type }) => {
          const moduleValue = findModuleValue(name, value);

          const isValueDefined = !!Object.keys(moduleValue).length;
          return {
            type,
            elementId,
            ...(isValueDefined && {
              value: (moduleValue as Record<string, any>)[name],
            }),
          };
        }),
      } as DataCollectionForm;
      const data = await submitReport('POST', { data: reportData });

      mutateSessionForm((old: SessionTree) => ({
        ...old,
        progress: data.progress,
      }));

      showSnackBar({
        variant: 'success',
        text: `Successfully submitted data for ${activeReportNode?.name}.`,
        actionButtonText: 'Dismiss',
      });
    },
  });

  return (
    <StyledDataCollection
      className="esg-data-collection"
      initialData={formData}
      onSubmit={handleSaveForm}
    >
      <h3 className="esg-data-collection__title">{activeReportNode?.name}</h3>

      <BlockUI loading={loadingForm}>
        <Each
          of={modules}
          render={(module) => (
            <ModuleElement
              moduleMapper={moduleMapper}
              type={module.type}
              module={module}
              key={module.id}
            />
          )}
        />

        <FormFooter condition={!!modules.length} submitLabel="Save Form Data" />
      </BlockUI>
    </StyledDataCollection>
  );
};

export default DataCollection;
