import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledDropdownPreview = styled.div`
  ${flex('row', 'space-between', 'flex-start')};
  gap: ${theme.sizes.SIZE_20};

  .esg-dropdown-preview {
    &__title {
      ${flex('row', 'flex-start', 'center')};
      gap: ${theme.sizes.SIZE_12};

      ${fontSize(theme.fontSizes.FONT_14)};

      span {
        ${flex('row', 'center', 'center')};
        flex: 0 0 ${theme.sizes.SIZE_16};
      }
    }

    &__fields {
      ${flex('column')};
      gap: ${theme.sizes.SIZE_20};
    }
  }
`;

export { StyledDropdownPreview };
