import { FC } from 'react';

import QuickConfigPreview from '../QuickConfigPreview';
import { InputDataModule, InputModuleConfig } from 'models';
import { SimpleInputField } from '../SimpleInput/SimpleInput.component';

import { StyledEmailInput } from './EmailInput.styled';

export type EmailInputProps = InputDataModule;

const EmailInput: FC<EmailInputProps> = (props) => {
  return (
    <StyledEmailInput className="esg-email-input">
      <QuickConfigPreview {...props} />

      <SimpleInputField
        switchLabel="Tooltip helper text"
        configKey="helperText"
        placeholder="Helper text"
        config={props.config as InputModuleConfig}
      />

      <SimpleInputField
        switchLabel="Custom placeholder"
        configKey="placeholder"
        placeholder="Placeholder"
        config={props.config as InputModuleConfig}
      />
    </StyledEmailInput>
  );
};

export default EmailInput;
