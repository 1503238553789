import { phablet, size } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

const StyledMainLayout = styled.div`
  ${size('100%')};

  display: grid;
  grid-template-columns: auto 1fr;

  ${phablet(css`
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
  `)};
`;

export { StyledMainLayout };
