import { FC, PropsWithChildren } from 'react';

import { StyledEmptyFolder } from './EmptyFolder.styled';
import Icon from '../../Icon';

export type EmptyFolderProps = PropsWithChildren<{}>;

const EmptyFolder: FC<EmptyFolderProps> = (props) => {
  return (
    <StyledEmptyFolder className="esg-empty-folder">
      <Icon className="esg-empty-folder__icon" name="folder-open" />
      <p className="esg-empty-folder__title">This folder is empty</p>
    </StyledEmptyFolder>
  );
};

export default EmptyFolder;
