import { createContext, Dispatch, SetStateAction } from 'react';
import { CampaignItem, TreeNodeElement } from '../../../../../models';
import { SessionTree } from '../../../../../models/Session';

export type OrganisationContextProps = {
  loading: boolean;
  tree?: TreeNodeElement;
  session?: SessionTree;
  activeCampaignId?: string;
  activeReportNode?: CampaignItem | TreeNodeElement;
  setTree: Dispatch<SetStateAction<TreeNodeElement | undefined>>;
};

export default createContext<OrganisationContextProps>({
  tree: { id: '', name: '' },
  loading: false,
  activeCampaignId: '',
  setTree: () => {},
});
