import api from 'api';
import { useCampaignItem } from '../context/CampaignItem';
import { useUtilities } from '@faxi/web-component-library';
import { useCallback } from 'react';
import { KeyedMutator } from 'swr';
import { CampaignItem } from '../../../models';
import { useCampaignProvider } from '../../../store/CampaignProvider';

const useCampaignFormEntitySubmit = (
  campaignItemId: string,
  mutate: KeyedMutator<CampaignItem>,
  currentCampaignItem?: CampaignItem
) => {
  const {
    request,
    data: campaignItemData,
    isMutating: createMutating,
  } = api.useMutation<CampaignItem>(`/campaign-items/${campaignItemId}`);

  const {
    campaignItem,
    mutating: editMutating,
    editCampaignItem,
  } = useCampaignItem();

  const { mutateTree } = useCampaignProvider();

  const { showSnackBar } = useUtilities();

  const submitForm = useCallback(
    async (data: Record<string, any>) => {
      if (!currentCampaignItem) return;
      if (campaignItem?.id) {
        await editCampaignItem?.(data);

        if (campaignItem.id === campaignItemId) {
          mutate({
            ...currentCampaignItem,
            name: data.name,
            description: data.description,
          });

          return;
        }

        mutate({
          ...currentCampaignItem,
          children: currentCampaignItem.children?.map((el) =>
            el.id === campaignItem.id
              ? { ...el, description: data?.description }
              : el
          ),
        });
      } else {
        await request('POST', {
          data: { ...data, type: data?.type ?? 'topic' },
        });

        mutate({
          ...currentCampaignItem,
          ...(campaignItemData && {
            children: [campaignItemData],
          }),
        });

        mutateTree();
      }

      showSnackBar({
        text: `${data.name} successfully ${campaignItem?.id ? 'updated' : 'created'}.`,
        variant: 'success',
        actionButtonText: 'Dismiss',
      });
    },
    [
      campaignItem,
      campaignItemData,
      campaignItemId,
      currentCampaignItem,
      editCampaignItem,
      mutate,
      mutateTree,
      request,
      showSnackBar,
    ]
  );

  return {
    submitForm,
    mutating: createMutating || editMutating,
  };
};

export default useCampaignFormEntitySubmit;
