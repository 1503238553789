import { FC } from 'react';

import { CheckListDataModule } from 'models';

import { StyledModuleContainer } from '../../styles/ModuleContainer.styled';
import InlineEditable from '../../../../_molecules/InlineEditable';
import { InlineModuleElement } from '../ModuleElement/ModuleElement.component';

export type ChecklistCanvasModuleProps =
  InlineModuleElement<CheckListDataModule>;

const ChecklistCanvasModule: FC<ChecklistCanvasModuleProps> = (props) => {
  const { config, ...rest } = props;
  const { options } = config || {};

  return (
    <StyledModuleContainer className="esg-checklist-canvas-module">
      <InlineEditable
        data-no-dnd="true"
        placeholderText={'Click to enter/edit checklist label'}
        {...rest}
      />
      <p>{`(${options?.length ?? 0} elements)`}</p>
    </StyledModuleContainer>
  );
};

export default ChecklistCanvasModule;
