import { BaseCampaign, CheckStatus, TreeNodeElement } from 'models';
import { campaignPathCheckpoints } from 'pages/Campaigns/utils';

const mapCampaignToTreeNode = (
  { id, name, created, modified, children }: BaseCampaign,
  depth = 0,
  path = ''
): TreeNodeElement => ({
  id,
  name,
  created,
  modified,
  children:
    depth < 3
      ? children?.map((c) =>
          mapCampaignToTreeNode(
            c,
            depth + 1,
            `${path}/${campaignPathCheckpoints[depth]}/${id}`
          )
        )
      : undefined,
  iconName: children.length === 0 ? 'file' : 'folder',
});

const findCheckedNodes = (node?: TreeNodeElement) => {
  if (!node) return [];

  let checkedNodes: string[] = [];

  // if the current node is checked, add it to the list
  if (
    node.checked === 2 &&
    node.children === undefined &&
    node.iconName === 'file'
  ) {
    checkedNodes.push(node.id as string);
  }

  // if the node has children, recursively check each child
  if (node.children && node.children.length > 0) {
    for (const child of node.children) {
      checkedNodes = checkedNodes.concat(findCheckedNodes(child));
    }
  }

  return checkedNodes;
};

const mapTreeCheckedToArray = (tree: TreeNodeElement): CheckStatus[] => {
  // console.log({ id: tree.id, checked: tree.checked });
  // console.log({ tree });

  if (!tree?.checked) return [];

  const childrenChecked = tree.children
    ?.map((c) => mapTreeCheckedToArray(c))
    .flat();

  // console.log({ childrenChecked });

  return [tree?.checked, ...(childrenChecked || [])];
};

const compareArrays = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) return false;

  let same = true;

  for (let i = 0; i < arr1.length; ++i) {
    if (arr1[i] !== arr2[i]) {
      same = false;
    }
  }

  return same;
};

export {
  mapCampaignToTreeNode,
  findCheckedNodes,
  mapTreeCheckedToArray,
  compareArrays,
};
