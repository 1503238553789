import { AuthBody } from 'models/AuthBody';
import storageService from './storageService';

export default {
  get token(): string {
    return storageService.getItem<string>('SESSION_TOKEN');
  },

  get refreshToken(): string {
    return storageService.getItem<string>('REFRESH_TOKEN');
  },

  saveAuthBody({ token, refreshToken }: AuthBody) {
    storageService.setItem('SESSION_TOKEN', token);
    storageService.setItem('REFRESH_TOKEN', refreshToken);
  },

  removeAuthBody() {
    storageService.removeItem('SESSION_TOKEN');
    storageService.removeItem('REFRESH_TOKEN');
  },
};
