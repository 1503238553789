import { useCallback, useMemo } from 'react';
import { useUtilities } from '@faxi/web-component-library';
import { KeyedMutator } from 'swr';
import EntityFormModal, {
  EntityFormModalProps,
} from 'components/_forms/EntityFormModal';
import api from 'api';
import { Organisation } from 'models';
import { useLocation } from 'react-router-dom';

type CompanyFormType = Pick<Organisation, 'name' | 'description'>;

function CompanyModal<T extends Record<string, any>>(
  props: Omit<EntityFormModalProps<T>, 'onSubmit'> & {
    mutator: KeyedMutator<T>;
  }
) {
  const { initialData: company, mutator, ...rest } = props;

  const { showSnackBar } = useUtilities();

  const { pathname } = useLocation();

  const orgId = useMemo(() => pathname.split('/').at(-1), [pathname]);

  const showSnackMessage = useCallback(
    (data: Organisation) => {
      showSnackBar({
        text: `${data.name} ${company ? 'updated' : 'created'}.`,
        variant: 'success',
        actionButtonText: 'Dismiss',
      });
    },
    [company, showSnackBar]
  );

  const { request: updateCompany, isMutating: updateMutating } =
    api.useMutation(`/organisations/${company?.id}`, {
      onSuccess: showSnackMessage,
    });

  const { request: createCompany, isMutating: createMutating } =
    api.useMutation<Organisation>(`organisations/${orgId}`, {
      onSuccess: showSnackMessage,
    });

  const loading = useMemo(
    () => updateMutating || createMutating,
    [createMutating, updateMutating]
  );

  const handleSubmit = useCallback(
    async (data: CompanyFormType) => {
      const { name, description } = data;

      if (company) {
        await updateCompany('PATCH', {
          data: { description },
        });
      } else {
        await createCompany('POST', {
          data: { name, description },
        });
      }

      mutator();
    },
    [company, mutator, updateCompany, createCompany]
  );

  return (
    <EntityFormModal
      className="esg-company-modal"
      loading={loading}
      initialData={
        company
          ? {
              name: company.name,
              description: company.description,
            }
          : undefined
      }
      title={company ? `Edit ${company.name} company` : 'Add company'}
      onSubmit={handleSubmit}
      {...rest}
    />
  );
}

export default CompanyModal;
