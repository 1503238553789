import { ApprovalStatus } from 'models';
import { FC } from 'react';
import { BlockUI } from '../../../../helpers';
import useSessionDashboardGridCards from '../../hooks/useSessionDashboardGridCards';
import { StyledSessionDashboard } from './SessionDashboard.styled';
import { SessionDashboardCard } from './components';

export type SessionDashboardProps = {};
export type SessionDashboardData = {
  icon: string;
  name: string;
  description?: string;
  items: {
    icon: string;
    name: string;
    status: ApprovalStatus;
    currentStep: number;
    numberOfSteps: number;
  }[];
};

const SessionDashboard: FC<SessionDashboardProps> = () => {
  const { data: sessionDashboardData, isLoading } =
    useSessionDashboardGridCards();

  return (
    <StyledSessionDashboard
      title="Organisations"
      className="esg-session-dashboard"
      direction="column"
    >
      <BlockUI loading={isLoading} fallbackCondition={!sessionDashboardData}>
        <div className="esg-session-dashboard__content">
          {sessionDashboardData.map((data) => (
            <SessionDashboardCard key={data.name} {...data} />
          ))}
        </div>
      </BlockUI>
    </StyledSessionDashboard>
  );
};

export default SessionDashboard;
