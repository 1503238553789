import { RadioGroup } from '@faxi/web-component-library';
import { FormField } from '@faxi/web-form';
import { FC, useMemo } from 'react';

import { ChoiceDataModule, DataModuleEnum } from 'models';
import { generateOptionsByLabels, generatePreviewFieldName } from 'utils';

import { StyledChoicePreview } from './ChoicePreview.styled';

export type ChoicePreviewProps = ChoiceDataModule;

const ChoicePreview: FC<ChoicePreviewProps> = ({ title, id, config }) => {
  const finalOptions = useMemo(
    () => generateOptionsByLabels(config?.options as { label: string }[]),
    [config]
  );

  return (
    <StyledChoicePreview className="esg-choice-preview">
      <p>{title || 'Choose Between'}</p>
      <FormField
        name={generatePreviewFieldName(id, DataModuleEnum.CHOICE)}
        component={RadioGroup}
        orientation="column"
        options={finalOptions}
      />
    </StyledChoicePreview>
  );
};

export default ChoicePreview;
