import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledCheckboxListField = styled.div`
  ${flex('column')};
  gap: ${theme.sizes.SIZE_16};

  > div {
    ${flex('row', 'flex-start', 'center')};
    gap: ${theme.sizes.SIZE_16};
    height: ${theme.sizes.SIZE_32};
  }

  > small {
    color: var(--RED-D5);
  }
`;

export { StyledCheckboxListField };
