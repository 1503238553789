import styled from 'styled-components';
import { flex } from '@faxi/web-css-utilities';
import { theme } from '@faxi/web-component-library';
import { BaseLayout } from '../../../../../layouts';

const StyledTasks = styled(BaseLayout)`
  ${flex('column')};

  .esg-base-layout__content {
    gap: ${theme.sizes.SIZE_20};
  }

  .esg-tasks {
    &__search-input {
      max-width: ${theme.sizes.SIZE_320};
      margin: 0 0 ${theme.sizes.SIZE_48};
    }
  }
`;

export { StyledTasks };
