import { BreadcrumbsProps } from '@faxi/web-component-library/build/components/_molecules/Breadcrumbs/Breadcrumbs.component';
import { FC, PropsWithChildren } from 'react';

import { Breadcrumbs, Divider, GlowScroll } from '@faxi/web-component-library';
import { Outlet } from 'react-router-dom';
import { AssignedContributor, CollapsibleSidePanel } from '../../components';
import { AssignedContributorProps } from '../../components/_molecules/AssignedContributor/AssignedContributor.component';
import BasicTreeView, {
  BasicTreeViewProps,
} from '../../components/_molecules/BasicTreeView/BasicTreeView.component';
import { BlockUI } from '../../helpers';

import { StyledSessionLayout } from './SessionLayout.styled';

export type SessionLayoutProps = PropsWithChildren<
  {
    pageTitle: string;
    sessionTitle?: string;
    treeNodeTitle?: string;
    treeViewTitle?: string;
    loadingTree: boolean;
    showContributors?: boolean;
    contributor: AssignedContributorProps;
  } & Pick<BreadcrumbsProps, 'crumbs'> &
    Pick<
      BasicTreeViewProps,
      | 'data'
      | 'withMenu'
      | 'activeNodeId'
      | 'onCheck'
      | 'onDelete'
      | 'onDuplicate'
    >
>;

const SessionLayout: FC<SessionLayoutProps> = (props) => {
  const {
    children,
    pageTitle,
    sessionTitle,
    data,
    withMenu,
    activeNodeId,
    crumbs,
    loadingTree,
    contributor,
    ...rest
  } = props;

  return (
    <StyledSessionLayout
      className="esg-session-layout"
      title={pageTitle}
      padding={0}
    >
      <CollapsibleSidePanel
        expandDirection="right"
        className="esg-session-layout__tree-view"
      >
        <BlockUI loading={loadingTree} fallbackCondition={!data}>
          <Breadcrumbs splitter="/" crumbs={crumbs} />
          <h2 className="esg-session-layout__session-title">{sessionTitle}</h2>
          <Divider />

          <BasicTreeView
            data={data}
            withMenu={withMenu}
            activeNodeId={activeNodeId}
            className="esg-sessions-layout__basic-tree"
            {...rest}
          />
        </BlockUI>
      </CollapsibleSidePanel>

      <GlowScroll variant="gray">
        <div className="esg-session-layout__content">
          <Outlet />
        </div>
      </GlowScroll>

      <CollapsibleSidePanel
        expandDirection="left"
        className="esg-session-layout__tree-view"
      >
        <p className="esg-session-layout__assigned-to">Assigned to</p>
        <AssignedContributor {...contributor} />
        <Divider />

        {children}
      </CollapsibleSidePanel>
    </StyledSessionLayout>
  );
};

export default SessionLayout;
