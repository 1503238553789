import { Expander, focusStyles, theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledDataModulesTool = styled(Expander)`
  &.esg-data-modules-tool--wrapper {
    height: unset;
  }

  .expander {
    &__trigger {
      width: 100%;

      &__title {
        ${flex('row', 'space-between', 'center')};
      }
    }

    &__container {
      width: 100%;
    }
  }
  .esg-data-modules-tool {
    ${flex('column')};

    padding-top: ${theme.sizes.SIZE_32};
    gap: ${theme.sizes.SIZE_32};

    > button {
      width: 100%;
    }

    &__list {
      list-style: none;
      padding: 0;
      margin: 0;
      ${flex('column')};

      gap: ${theme.sizes.SIZE_8};

      &__li {
        ${flex('row', 'flex-start', 'center')};
        ${fontSize('14px', '20px')};
        ${focusStyles};

        gap: ${theme.sizes.SIZE_12};
        padding: ${theme.sizes.SIZE_12};
        border-radius: ${theme.sizes.SIZE_8};
        border: 1px solid var(--GRAY-C1);
        background: white;
        cursor: pointer;
        min-height: ${theme.sizes.SIZE_56};

        > i {
          ${size('24px')}
        }

        > p {
          margin: 0;
        }

        &--active {
          background: var(--GRAY-C1);
        }
      }
    }
  }
`;

export { StyledDataModulesTool };
