import useSWR from 'swr';
import { Campaign } from 'models';

const useGetCampaign = (id: string) => {
  const { data, error, isLoading, mutate } = useSWR<Campaign>(
    `/campaigns/${id}`
  );

  return {
    campaign: data,
    isLoading,
    isError: error,
    mutate,
  };
};

export default useGetCampaign;
