import { FC, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CampaignEntity from '../CampaignEntity';
import { APP_URI } from '../../../../config';
import useGetGampaignItem from '../../../../api/hooks/useGetCampaignItem';
import useCampaignFormEntitySubmit from '../../hooks/useCampaignFormEntitySubmit';
import { campaignItemTypeSelectOptions } from '../../constants/campaignItemType';
import { GlowScroll } from '@faxi/web-component-library';
import authBus from '../../../../modules/authBus';

const SubTopic: FC = () => {
  const {
    topic = '',
    campaign = '',
    subtopic = '',
  } = useParams<{
    topic: string;
    campaign: string;
    subtopic: string;
  }>();

  const { campaignItem, isLoading, mutate } = useGetGampaignItem(subtopic);

  const { mutating, submitForm } = useCampaignFormEntitySubmit(
    subtopic,
    mutate,
    campaignItem
  );

  const navigateTo = useCallback(
    (name: string) =>
      APP_URI.CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC.replace(
        ':campaign',
        campaign
      )
        .replace(':topic', topic)
        .replace(':subtopic', subtopic)
        .replace(':subsubtopic', name),
    [campaign, subtopic, topic]
  );

  useEffect(() => {
    authBus.addEventListener('delete_campaign_entity', ({ id }) => {
      campaignItem?.children.some((item) => item?.id === id) && mutate();
    });
  }, [campaignItem, mutate]);

  return (
    <GlowScroll variant="gray">
      <CampaignEntity
        campaignItem={campaignItem}
        navigateTo={navigateTo}
        entityType="subsubtopic"
        isLoading={isLoading}
        onSubmit={submitForm}
        modalProps={{
          chooseType: true,
          loading: mutating,
          fieldProps: {
            type: {
              options: campaignItemTypeSelectOptions,
            },
          },
        }}
        onMutate={mutate}
      />
    </GlowScroll>
  );
};

export default SubTopic;
