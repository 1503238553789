import { FC } from 'react';

import TasksLayout from './TasksLayout.component';
import TasksProvider from '../../providers/Tasks/Tasks.provider';

const TasksWrapper: FC = () => {
  return (
    <TasksProvider>
      <TasksLayout />
    </TasksProvider>
  );
};

export default TasksWrapper;
