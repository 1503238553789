// api.ts
import axios, {
  AxiosInstance,
  InternalAxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from 'axios';
import env from 'env';
import credentialsService from '../services/credentialsService';
import authBus from '../modules/authBus';

const api: AxiosInstance = axios.create({
  baseURL: env.VITE_API_ENDPOINT,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const token = credentialsService.token;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response) {
      if (error.response.status === 401) {
        // Handle Unauthorized access (logout)
      }
    }
    authBus.broadcastEvent('show_snackbar', {
      props: {
        text:
          (error.response?.data as { message: string })?.message ||
          'An error occurred, please try again later.',
        variant: 'error',
        actionButtonText: 'Dismiss',
      },
    });
    return Promise.reject(error);
  }
);

export default api;
