import dayjs, { Dayjs } from 'dayjs';
import { FC, useMemo, useState } from 'react';

import { CalendarField } from 'components';
import { ExtractComponentProps } from 'types';
import { InputPreviewProps } from '../../InputPreview.component';
import { CalendarFieldProps } from '../../../../../../../_fields/CalendarField/CalendarField.component';

export type DateFieldProps = ExtractComponentProps<typeof CalendarField> &
  InputPreviewProps &
  CalendarFieldProps;

const DateField: FC<DateFieldProps> = (props) => {
  const { config, renderFormField = true } = props;

  const [date, setDate] = useState<Dayjs>();

  const calendarProps: any = useMemo(
    () =>
      renderFormField
        ? { ...props, value: props.value && dayjs(props.value) }
        : { ...props, value: dayjs(date), onChange: setDate },
    [date, renderFormField, props]
  );

  return (
    <CalendarField
      placeholder={config?.placeholder}
      leftLimitDate={config?.minDate ? dayjs(config.minDate) : undefined}
      rightLimitDate={config?.maxDate ? dayjs(config.maxDate) : undefined}
      {...calendarProps}
    />
  );
};

export default DateField;
