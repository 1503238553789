import { TreeNodeElement } from 'models';

export const DEFAULT_TREE_NODE_DATA_WITHOUT_PARENTS: TreeNodeElement = {
  id: '1',
  name: 'Environment',
  children: [
    {
      id: '2',
      name: 'Climate change',
      children: [
        {
          id: '3',
          name: 'Climate Change Adaptation',
          children: [
            {
              id: '4',
              name: 'badge',
              iconName: 'bars-progress',
              color: 'var(--LABEL_TEXT_1_1)',
              created: '',
              modified: '',
            },
          ],
        },
      ],
    },
    {
      id: '5',
      name: 'Renewable Energy',
      children: [
        {
          id: '6',
          name: 'Solar Power',
          children: [
            {
              id: '7',
              name: 'chart-simple',
              iconName: 'chart-simple',
              color: 'var(--BROWN-A3)',
              created: '',
              modified: '',
            },

            {
              id: '10',
              name: 'wave-pulse',
              iconName: 'wave-pulse',
              color: 'red',
              created: '',
              modified: '',
            },
          ],
        },
        {
          id: '8',
          name: 'Wind Energy',
          children: [
            {
              id: '9',
              name: 'grip',
              iconName: 'grip-solid',
              color: 'var(--ALERT_SUCCESS_1_1)',
              created: '',
              modified: '',
            },
            {
              id: '11',
              name: 'magnifying-glass-chart',
              iconName: 'magnifying-glass-chart',
              color: 'var(--BLUE-00)',
              created: '',
              modified: '',
            },
          ],
        },
      ],
    },
  ],
};

export const createTreeWithParentReferences = (
  node: TreeNodeElement,
  parent: TreeNodeElement | null = null,
  defaultChecked: number
): TreeNodeElement => {
  node.parent = parent || null;
  node.checked = defaultChecked || 0;
  node.children = node.children?.map((child) =>
    createTreeWithParentReferences(child, node, defaultChecked)
  );

  return node;
};

const DEFAULT_TREE_NODE_DATA = DEFAULT_TREE_NODE_DATA_WITHOUT_PARENTS;

export { DEFAULT_TREE_NODE_DATA };
