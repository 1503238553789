import { FC, useCallback } from 'react';
import { FormField } from '@faxi/web-form';
import { Link } from 'react-router-dom';

import { useValidations } from 'hooks';
import { InputField, FormFooter } from 'components';

import { StyledForgotPassword } from './ForgotPassword.styled';

const ForgotPassword: FC = () => {
  const { validations } = useValidations();

  const handleSubmit = useCallback(async () => {}, []);

  return (
    <StyledForgotPassword
      className="esg-forgot-password"
      onSubmit={handleSubmit}
    >
      <h2 className="esg-forgot-password__password-title">Forgot password</h2>
      <p className="esg-forgot-password__password-content">
        Enter your email and we will send you a reset link
      </p>

      <FormField
        name="email"
        type="text"
        placeholder="Email"
        component={InputField}
        validate={validations.email}
      />

      <FormFooter submitLabel="Send" />

      <Link to="../" className="auth-text">
        I remembered it
      </Link>
    </StyledForgotPassword>
  );
};

export default ForgotPassword;
