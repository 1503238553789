import { useUtilities } from '@faxi/web-component-library';
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  NavigateFunction,
  Outlet,
  useNavigate,
  useParams,
} from 'react-router-dom';

import api from 'api';
import Icon from 'components/Icon';
import { Organisation, TreeNodeElement } from 'models';
import { findNode } from 'utils';
import { APP_URI } from '../../../../../config';
import { ORGANISATION_PATH_CHECKPOINTS } from '../../utils';
import OrganisationContext, {
  OrganisationContextProps,
} from './Organisation.context';

type OrganisationProviderProps = PropsWithChildren<{}>;

const mapOrganisationToTreeNodeElement = (
  { id, name, description, created, modified, children }: Organisation,
  depth: number,
  path: string,
  navigate: NavigateFunction
): TreeNodeElement => ({
  id,
  name,
  description,
  created,
  modified,
  children: children?.map((child) =>
    mapOrganisationToTreeNodeElement(
      child,
      depth + 1,
      `${path}/${ORGANISATION_PATH_CHECKPOINTS[depth]}/${id}`,
      navigate
    )
  ),
  iconName: 'folder',
  to: `${path}/${ORGANISATION_PATH_CHECKPOINTS[depth]}/${id}`,
});

const OrganisationProvider: FC<OrganisationProviderProps> = () => {
  const navigate = useNavigate();
  const { prompts, showOverlay, hideOverlay } = useUtilities();

  const params = useParams() as {
    organisation: string;
  };

  const [deletingOrgId, setDeletingOrgId] = useState<string>();
  const { request: deleteOrg, isMutating } = api.useMutation(
    `/organisations/${deletingOrgId}`
  );

  const {
    data: organisations,
    isLoading,
    mutate: mutateOrganisations,
  } = api.useOrganisationsSWR(
    (orgId) => `${APP_URI.ORGANISATIONS}/${orgId}/tree`
  );

  const loading = isLoading || isMutating;

  const currentOrganisationId = useMemo(
    () => Object.values(params).pop() as string,
    [params]
  );

  const activeOrganisation = useMemo(
    () =>
      organisations
        ? findNode<Organisation>(
            currentOrganisationId,
            Array(organisations) as Organisation[]
          )
        : undefined,
    [currentOrganisationId, organisations]
  );

  const deleteOrganisation = useCallback(
    async (
      org: Organisation,
      trigger?: HTMLButtonElement,
      callback?: () => void
    ) => {
      setDeletingOrgId(org.id);

      const proceed = await prompts.delete({
        type: 'delete',
        title: `Remove ${org?.name}`,
        content: `Are you sure you want to remove ${org?.name} from ${activeOrganisation?.name}?`,
        submitBtnText: 'Remove',
        cancelBtnText: 'Do not remove',
        btnIcon: 'trash-can',
        iconPosition: 'left',
        submitBtnVariant: 'delete-ghost',
        triggerRef: trigger as HTMLButtonElement,

        titleIcon: <Icon name="triangle-exclamation" />,
      });

      if (!proceed) return;
      showOverlay('body');

      await deleteOrg('DELETE', {});
      await mutateOrganisations();
      callback?.();

      setDeletingOrgId(undefined);
      hideOverlay('body');
    },
    [
      prompts,
      activeOrganisation,
      showOverlay,
      deleteOrg,
      hideOverlay,
      mutateOrganisations,
    ]
  );

  const tree = useMemo(
    () =>
      organisations &&
      mapOrganisationToTreeNodeElement(organisations, 0, '', navigate),
    [organisations, navigate]
  );

  return (
    <OrganisationContext.Provider
      value={{
        tree,
        loading,
        activeOrganisation,
        deleteOrganisation,
        mutateOrganisations,
      }}
    >
      <Outlet />
    </OrganisationContext.Provider>
  );
};

const useOrganisationProvider = (): OrganisationContextProps =>
  useContext(OrganisationContext);

export { OrganisationProvider, useOrganisationProvider };
