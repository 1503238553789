import { FC, memo } from 'react';

import Icon from 'components/Icon';
import { TreeNodeElement } from 'models';

export type FileIconProps = {
  node: TreeNodeElement;
};

const FileIcon: FC<FileIconProps> = ({ node }) => (
  <Icon color={node.color} name={node.iconName || 'file'} />
);

export default memo(FileIcon);
