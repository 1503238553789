import {
  UtilitiesProvider,
  GlobalStyle as WCLGlobalStyle,
} from '@faxi/web-component-library';
import swrConfig from 'api/swrConfig';
import { SWRConfig } from 'swr';

import { Router } from 'routes';
import { GlobalStyle } from './globalStyles';
import { useEffect } from 'react';
import storageService from './services/storageService';
import { USERS_MOCK } from './store/data';

const App = () => {
  useEffect(() => {
    const usersParsed = JSON.parse(storageService.getItem('USERS_MOCK'));

    if (!usersParsed) {
      storageService.setItem('USERS_MOCK', JSON.stringify(USERS_MOCK));
    }
  }, []);

  return (
    <>
      <WCLGlobalStyle />
      <GlobalStyle />
      <UtilitiesProvider>
        <SWRConfig value={swrConfig}>
          <Router />
        </SWRConfig>
      </UtilitiesProvider>
    </>
  );
};

export default App;
