import { ObjectValues } from '../types';
import { BaseModel } from './BaseModel';

export const USER_ROLE = {
  REGULAR_USER: 'user',
  ORGANISATION_ADMIN: 'admin',
  SUPER_ADMIN: 'super-admin',
} as const;

export type UserRole = ObjectValues<typeof USER_ROLE>;

export enum Permission {
  CREATE_ORGANISATION,
  CREATE_COMPANY,
  CREATE_REPORT,
  CREATE_TOPIC,
  CREATE_CATEGORY,
  CREATE_TASK,
  SUPERVISE_ACTIVITY,
  HANDLE_REPORT, // APPROVE OR REJECT,
  CHECK_REPORT_STATUS,
  COLLECT_DATA,
  ENTER_DATA,
}

export type User = BaseModel & {
  first_name: string;
  last_name: string;
  email: string;
  role: UserRole;
  // IF PERMISSIONS ARE ON ENTITY-LEVEL, WHERE AN ENTITY MAY BE AN ORGANISATION/COMPANY/REPORT...
  // permissions: { permission: Permission; entityId: number };
};
