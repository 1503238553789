import { User } from '../models';

export const USERS_MOCK: User[] = [
  {
    first_name: 'Chehin',
    last_name: 'Toumi',
    email: 'chehin.toumi@faxi.co.uk',
    id: 'chehin',
    role: 'super-admin',
  },
  {
    first_name: 'Andrija',
    last_name: 'Cvetkovic',
    email: 'andrija@ncoded.solutions',
    id: 'andrija',
    role: 'super-admin',
  },
  {
    first_name: 'Daniele',
    last_name: 'Quartu',
    email: 'daniele.quartu@kintojoin.io',
    id: 'daniele',
    role: 'super-admin',
  },
  {
    first_name: 'Nemanja',
    last_name: 'Stankovic',
    email: 'apox@ncoded.solutions',
    id: 'apox',
    role: 'super-admin',
  },
  {
    first_name: 'Aleksandar',
    last_name: 'Stojkovic',
    email: 'aleksandar@kintojoin.io',
    id: 'aleksandar',
    role: 'super-admin',
  },
  {
    first_name: 'Daniele',
    last_name: 'Quartu',
    email: 'daniele.quartu@kintojoin.io',
    id: 'daniele',
    role: 'super-admin',
  },
  {
    first_name: 'Nemanja',
    last_name: 'Stojanovic',
    email: 'nemanja@ncoded.solutions',
    id: 'nemanja',
    role: 'admin',
  },
  {
    first_name: 'Dusan',
    last_name: 'Simijonovic',
    email: 'dusan@ncoded.solutions',
    id: 'dusan',
    role: 'user',
  },
  {
    first_name: 'Jovan',
    last_name: 'Aritonovic',
    email: 'jovan@ncoded.solutions',
    id: 'jovan',
    role: 'user',
  },
  {
    first_name: 'Mateja',
    last_name: 'Zlatanovic',
    email: 'mateja@ncoded.solutions',
    id: 'mateja',
    role: 'user',
  },
];
