import { createContext } from 'react';

import { SessionData } from 'models';
import { RunSessionData } from './SessionsData.provider';

export type SessionsDataContextProps = {
  sessions: SessionData[];
  search: string;
  addSession: (
    newSession: Pick<SessionData, 'name' | 'description'>
  ) => Promise<void>;
  removeSession: (sessionName: string) => void;
  runSession: (sessionName: string, data: RunSessionData) => void;
  setSearch: (value: string) => void;
  isLoading: boolean;
  isRunMutating: boolean;
  isAddSessionMutating: boolean;
};

export default createContext<SessionsDataContextProps>({
  sessions: [],
  search: '',
  addSession: () => Promise.resolve(),
  removeSession: () => {},
  runSession: () => {},
  setSearch: () => {},
  isLoading: false,
  isRunMutating: false,
  isAddSessionMutating: false,
});
