import { FC, PropsWithChildren, MouseEvent } from 'react';

import { StyledFolderDescription } from './FolderDescription.styled';
import { Button, Icon } from '@faxi/web-component-library';
import OverflowTooltipText from '../OverflowTooltipText';

export type FolderDescriptionProps = PropsWithChildren<{
  btnText?: string;
  description?: string;
  hasActions?: boolean;
  onChange?: (e: MouseEvent) => void;
}>;

const CampaignDescription: FC<FolderDescriptionProps> = (props) => {
  const {
    children,
    description,
    btnText = 'Add description',
    hasActions = true,
    onChange,
  } = props;

  return (
    <StyledFolderDescription className="esg-folder-description">
      {description ? (
        !hasActions ? (
          <OverflowTooltipText text={description} />
        ) : (
          <OverflowTooltipText text={description}>
            <Button
              variant="ghost"
              icon={<Icon name="pen" />}
              onClick={(e) => {
                e.stopPropagation();
                onChange?.(e);
              }}
            />
          </OverflowTooltipText>
        )
      ) : (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onChange?.(e);
          }}
          variant="ghost"
          icon={<Icon name="plus" />}
        >
          {btnText}
        </Button>
      )}

      {children}
    </StyledFolderDescription>
  );
};

export default CampaignDescription;
