import { ModalProps, ModalRef } from '@faxi/web-component-library';
import { Form } from '@faxi/web-form';
import { FC, PropsWithChildren, useCallback, useMemo, useRef } from 'react';

import { FormFooter } from 'components';
import Icon from 'components/Icon';
import { DataModuleEnum, ModuleConfig, ModuleConfigType } from 'models';
import { configurableModules } from '../../configs';
import { mapTypeToIcon, mapTypeToTitle } from '../../utils';

import dayjs from 'dayjs';
import { StyledModuleConfiguration } from './ModuleConfiguration.styled';

const CONFIG_MODULE_TEXT: Partial<Record<DataModuleEnum, string>> = {
  [DataModuleEnum.INPUT]: 'Please set up input field configuration',
  [DataModuleEnum.CHOICE]: 'Please set up choices with options',
  [DataModuleEnum.CHECKLIST]: 'Please set up checklist with options',
  [DataModuleEnum.DROPDOWN]: 'Please set up options for dropdown',
  [DataModuleEnum.SWITCH]: 'Please set up options for switch',
  [DataModuleEnum.INTEGRATION]: 'Please set up external integration',
};

export type ModuleConfigurationProps<
  T,
  S extends DataModuleEnum,
> = PropsWithChildren<ModalProps> & {
  moduleConfig: ModuleConfig<T, S>;
  onSubmit?: (config: ModuleConfig<ModuleConfigType, S>) => void;
};

function ModuleConfiguration<
  T extends Record<string, any>,
  S extends DataModuleEnum,
>(props: ModuleConfigurationProps<T, S>) {
  const { moduleConfig, onSubmit, ...rest } = props;

  const { type } = moduleConfig;
  const modalRef = useRef<ModalRef>(null);

  const initialData = useMemo(
    () => ({
      ...moduleConfig.config,
      ...(moduleConfig?.config?.minDate && {
        minDate: dayjs(moduleConfig.config.minDate),
      }),
      ...(moduleConfig?.config?.maxDate && {
        maxDate: dayjs(moduleConfig.config.maxDate),
      }),
    }),
    [moduleConfig]
  );

  const formWrapper = useCallback(
    ({ children, className }: PropsWithChildren<{ className: string }>) => (
      <Form
        children={children}
        className={className}
        initialData={initialData}
        onSubmit={async (config: ModuleConfigType) => {
          onSubmit?.({ ...moduleConfig, config });
          modalRef.current?.close();
        }}
      />
    ),
    [initialData, moduleConfig, onSubmit]
  );

  const ModuleConfig = useMemo(
    () => configurableModules[type] as FC<ModuleConfig<T, typeof type>>,
    [type]
  );

  return (
    <StyledModuleConfiguration
      ref={modalRef}
      hasCloseButton={false}
      title={mapTypeToTitle[type]}
      icon={<Icon name={mapTypeToIcon[type]} />}
      className="esg-module-configuration"
      childrenWrapper={formWrapper}
      footer={
        <FormFooter
          modal={modalRef}
          submitIcon="check"
          submitLabel="Done"
          cancelLabel="Cancel"
        />
      }
      {...rest}
    >
      <p className="esg-module-configuration__title">
        {CONFIG_MODULE_TEXT[type]}
      </p>

      <ModuleConfig {...moduleConfig} />
    </StyledModuleConfiguration>
  );
}

export default ModuleConfiguration;
