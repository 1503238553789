import useSWR from 'swr';
import { CampaignItem } from 'models/Campaign';

const useGetGampaignItem = (id: string) => {
  const { data, error, isLoading, mutate } = useSWR<CampaignItem>(
    `/campaign-items/${id}`
  );

  return {
    campaignItem: data,
    isLoading,
    isError: error,
    mutate,
  };
};

export default useGetGampaignItem;
