import { RouteObject } from 'react-router-dom';
import { APP_URI } from 'config';
import { Campaign, Topic } from '../../../pages/Campaigns/components';
import SubSubTopic from '../../../pages/Campaigns/components/SubSubTopic';
import SubTopic from '../../../pages/Campaigns/components/SubTopic';
import Campaigns from '../../../pages/Campaigns';

export default [
  { index: true, element: <Campaigns /> },
  {
    path: APP_URI.CAMPAIGNS_CAMPAIGN,
    element: <Campaign />,
  },
  {
    path: APP_URI.CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC,
    element: <Topic />,
  },
  {
    path: APP_URI.CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC,
    element: <SubTopic />,
  },
  {
    path: APP_URI.CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC,
    element: <SubSubTopic />,
  },
] as RouteObject[];
