import { RouteObject, Navigate } from 'react-router-dom';

import { APP_URI } from 'config';
import { Organisations, Organisation, Company } from './pages';
import FolderWrapper from './layouts/FolderWrapper';

export default [
  {
    path: '',
    element: <Organisations />,
  },
  {
    path: '',
    element: <FolderWrapper />,
    children: [
      {
        path: APP_URI.ORGANISATIONS_ORGANISATION,
        element: <Organisation />,
      },
      {
        path: APP_URI.ORGANISATIONS_ORGANISATION_COMPANY,
        element: <Company />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="" />,
  },
] as RouteObject[];
