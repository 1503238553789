import { TouchSensor } from '@dnd-kit/core';
import { handler } from '../utils';

export class SmartTouchSensor extends TouchSensor {
  static activators = [
    {
      eventName: 'onTouchStart',
      handler,
    },
  ] as (typeof TouchSensor)['activators'];
}
