import { Heading, Image } from '@faxi/web-component-library';
import { FC } from 'react';

import { ApprovalProgressBar } from 'components';
import Icon, { INameExtended } from 'components/Icon';
import { checkIsIcon } from 'utils';

import { SessionDashboardData } from '../../SessionDashboard.page';

import classNames from 'classnames';
import { StyledSessionDashboardCard } from './SessionDashboardCard.styled';

export type SessionDashboardCardProps = {
  className?: string;
} & SessionDashboardData;

const MAIN_IMAGE_PROPS = { alt: '', height: '26', width: '26' } as const;
const ITEM_IMAGE_PROPS = { alt: '', height: '18', width: '18' } as const;

const SessionDashboardCard: FC<SessionDashboardCardProps> = ({
  className,
  name,
  icon,
  description,
  items,
}) => (
  <StyledSessionDashboardCard
    to={name}
    className={classNames(
      'esg-session-dashboard-card',
      'hover-effect',
      className
    )}
  >
    <div className="esg-session-dashboard-card__header">
      {checkIsIcon(icon) ? (
        <Icon name={icon as INameExtended} />
      ) : (
        <Image {...MAIN_IMAGE_PROPS} src={icon} />
      )}

      <Heading level="2">{name}</Heading>
    </div>
    {description && <small>{description}</small>}

    <div className="esg-session-dashboard-card__content">
      {items.map((item) => (
        <div
          className="esg-session-dashboard-card__content__item"
          key={item.name}
        >
          <div className="esg-session-dashboard-card__content__item__name">
            {checkIsIcon(item.icon) ? (
              <Icon name={item.icon as INameExtended} />
            ) : (
              <Image {...ITEM_IMAGE_PROPS} src={item.icon} />
            )}
            {item.name}
          </div>
          <ApprovalProgressBar {...item} />
        </div>
      ))}
    </div>
  </StyledSessionDashboardCard>
);

export default SessionDashboardCard;
