import { GlowScroll, useUtilities } from '@faxi/web-component-library';
import api from 'api';
import { APP_URI } from 'config';
import { BlockUI } from 'helpers';
import { FC, useCallback } from 'react';
import CampaignEntity from './components/CampaignEntity';
import { useCampaignItem } from './context/CampaignItem';

const Campaigns: FC = () => {
  const { campaigns, mutate, isLoading } = api.useGetCampaigns();
  const { request, isMutating } = api.useMutation('/campaign-items');

  const { campaignItem, mutating, editCampaignItem } = useCampaignItem();

  const { showSnackBar } = useUtilities();

  const navigateTo = useCallback(
    (id: string) => APP_URI.CAMPAIGNS_CAMPAIGN.replace(':campaign', id),
    []
  );

  const createCampaign = useCallback(
    async (data: Record<string, any>) => {
      if (campaignItem?.id) {
        await editCampaignItem?.({ description: data.description });

        mutate(
          campaigns?.map((el) =>
            el.id === campaignItem.id
              ? { ...el, desciption: data.description }
              : el
          )
        );
      } else {
        await request('POST', { data });
      }

      showSnackBar({
        text: `${data.name} campaign successfully ${campaignItem?.id ? 'updated' : 'created'}.`,
        variant: 'success',
        actionButtonText: 'Dismiss',
      });
    },
    [campaignItem, campaigns, editCampaignItem, mutate, request, showSnackBar]
  );

  return (
    <BlockUI loading={isLoading} fallbackCondition={!campaigns?.length}>
      <GlowScroll variant="gray">
        <CampaignEntity
          navigateTo={navigateTo}
          defaultItems={campaigns}
          entityType="campaign"
          modalProps={{ loading: mutating || isMutating }}
          onSubmit={createCampaign}
          onMutate={mutate}
        />
      </GlowScroll>
    </BlockUI>
  );
};

export default Campaigns;
