import { FC } from 'react';
import { SwitchDataModule } from 'models';
import InlineEditable from 'components/_molecules/InlineEditable';
import { Switch } from '@faxi/web-component-library';
import { StyledModuleContainer } from '../../styles/ModuleContainer.styled';
import { InlineModuleElement } from '../ModuleElement/ModuleElement.component';

export type SwitchCanvasModuleProps = InlineModuleElement<SwitchDataModule>;

const SwitchCanvasModule: FC<SwitchCanvasModuleProps> = (props) => {
  return (
    <StyledModuleContainer className="esg-switch-canvas-module">
      <InlineEditable
        data-no-dnd="true"
        placeholderText="Click to enter/edit switch label"
        {...props}
      />
      <Switch value={false} onChange={() => {}} />
    </StyledModuleContainer>
  );
};

export default SwitchCanvasModule;
