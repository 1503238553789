import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { PX_TO_MM } from 'config';

type ElementsStyleUpdate = {
  selector: string;
  attribute: 'display' | 'transition';
  style: string;
  pseudoElement?: 'before' | 'after' | undefined;
}[];

const STYLES_BEFORE_EXPORT: ElementsStyleUpdate = [
  {
    selector: '.wcl-switch__switch__slider',
    attribute: 'transition',
    style: 'none',
    pseudoElement: 'before',
  },
  {
    selector: 'input[type="checkbox"]',
    attribute: 'display',
    style: 'none',
  },
];

const STYLES_AFTER_EXPORT: ElementsStyleUpdate = [
  {
    selector: '.wcl-switch__switch__slider',
    attribute: 'transition',
    style: 'all 0.4s ease 0s',
    pseudoElement: 'before',
  },
  {
    selector: 'input[type="checkbox"]',
    attribute: 'display',
    style: '',
  },
];

/**
 * Updates the style of specified elements.
 *
 * @param elements - Array of elements with their selectors, attributes, styles, and optional pseudo-elements.
 */
const updateElementsStyle = (elements: ElementsStyleUpdate): void => {
  const styleElement = document.createElement('style');
  document.head.appendChild(styleElement);

  elements.forEach((element) => {
    if (element.pseudoElement) {
      const sheet = new CSSStyleSheet();
      sheet.replaceSync(
        `${element.selector}::${element.pseudoElement} { ${element.attribute}: ${element.style} !important; }`
      );
      document.adoptedStyleSheets = [...document.adoptedStyleSheets, sheet];
    } else {
      const htmlElements: NodeListOf<HTMLElement> = document.querySelectorAll(
        element.selector
      );

      htmlElements.forEach((htmlElement) => {
        htmlElement.style[element.attribute] = element.style;
      });
    }
  });
};

/**
 * Handles exporting the form preview to a PDF.
 *
 * @param elementsWrapperRef - Reference to the elements wrapper.
 * @param initialPreviewElementRef - Reference to the initial preview element.
 * @param setIsLoading - Function to set the loading state.
 */
export const handleExportPDF = async (
  elementsWrapperRef: React.RefObject<HTMLDivElement>,
  initialPreviewElementRef: React.RefObject<HTMLDivElement>,
  onLoading: (v: boolean) => void,
  padding: number,
  elementsGap: number
) => {
  if (!elementsWrapperRef.current || !initialPreviewElementRef.current) return;

  updateElementsStyle(STYLES_BEFORE_EXPORT);

  const pdf = new jsPDF('p', 'mm', 'a4');
  const gap = elementsGap * PX_TO_MM;
  let offset = padding;

  const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * padding;
  onLoading(true);

  try {
    const { imgData: initialImgData, pdfHeight: initialPdfHeight } =
      await captureElementAsImage(initialPreviewElementRef.current, pdfWidth);
    offset =
      addImageToPDF(
        pdf,
        initialImgData,
        padding,
        offset,
        pdfWidth,
        initialPdfHeight
      ) + gap;

    const children = Array.from(
      elementsWrapperRef.current.children
    ) as HTMLElement[];
    await processChildElements(pdf, children, padding, gap, pdfWidth, offset);

    pdf.save('form-preview.pdf');
  } catch (e) {
    console.error(e);
  } finally {
    updateElementsStyle(STYLES_AFTER_EXPORT);
    onLoading(false);
  }
};

const captureElementAsImage = async (
  element: HTMLElement,
  pdfWidth: number
): Promise<{ imgData: string; pdfHeight: number }> => {
  const canvas = await html2canvas(element, {
    useCORS: true,
    allowTaint: true,
    logging: true,
  });

  const imgData = canvas.toDataURL('image/png');
  const imgProps = new jsPDF().getImageProperties(imgData);
  const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

  return { imgData, pdfHeight };
};

const addImageToPDF = (
  pdf: jsPDF,
  imgData: string,
  padding: number,
  offset: number,
  pdfWidth: number,
  pdfHeight: number
): number => {
  pdf.addImage(
    imgData,
    'PNG',
    padding,
    offset,
    pdfWidth,
    pdfHeight,
    undefined,
    'FAST'
  );
  return offset + pdfHeight;
};

const processChildElements = async (
  pdf: jsPDF,
  children: HTMLElement[],
  padding: number,
  gap: number,
  pdfWidth: number,
  offset: number
): Promise<number> => {
  for (let i = 0; i < children.length; i++) {
    const child = children[i];

    if (
      child.tagName.toLowerCase() === 'hr' &&
      child.classList.contains('page-break')
    ) {
      pdf.addPage();
      offset = padding;
      continue;
    }

    const { imgData, pdfHeight } = await captureElementAsImage(child, pdfWidth);

    if (offset + pdfHeight > pdf.internal.pageSize.getHeight() - padding) {
      pdf.addPage();
      offset = padding;
    }

    offset =
      addImageToPDF(pdf, imgData, padding, offset, pdfWidth, pdfHeight) + gap;
  }

  return offset;
};
