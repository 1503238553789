import { theme } from '@faxi/web-component-library';
import styled from 'styled-components';

const StyledBasicTreeView = styled.div`
  .esg-folder-icon {
    flex: 0 0 ${theme.sizes.SIZE_24};
  }

  .esg-chavron-icon {
    width: ${theme.sizes.SIZE_20};
    padding: ${theme.sizes.SIZE_4};

    transition-duration: 100ms;
    transition-timing-function: ease-out;
    transform: rotate(0);

    &--open {
      transform: rotate(90deg);
      transition-property: transform;
    }
  }
`;

export { StyledBasicTreeView };
