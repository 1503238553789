import { theme } from '@faxi/web-component-library';
import { flex, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledIntegrationPreview = styled.div`
  ${flex('row', 'space-between', 'center')};

  .esg-integration-preview {
    &__content {
      ${flex('row', 'flex-end', 'center')};
      gap: ${theme.sizes.SIZE_20};

      > img {
        ${size('48px')};
        border-radius: ${theme.sizes.SIZE_8};
      }

      > div {
        flex: 0 0 ${theme.sizes.SIZE_120};
      }
    }
  }
`;

export { StyledIntegrationPreview };
