import { StatusElementStatus } from '@faxi/web-component-library';
import { INameExtended } from 'components/Icon';
import {
  DataModuleEnum,
  ModuleConfig,
  ModuleConfigType,
  SectionType,
} from 'models';

export const mappedSectionTypeToStatus: Record<
  SectionType,
  StatusElementStatus
> = {
  'data-collection': 'active',
  report: 'pending',
  folder: 'draft',
};

export const mappedSectionTypeToIcon: Record<SectionType, INameExtended> = {
  'data-collection': 'magnifying-glass-chart',
  report: 'chart-simple',
  folder: 'folder',
};

export const mappedSectionTypeToTitle: Record<SectionType, string> = {
  'data-collection': 'Data Collection',
  report: 'Report',
  'folder': 'Folder',
};

export const mapTypeToIcon: Record<DataModuleEnum, INameExtended> = {
  'heading': 'heading',
  'text-box': 'text',
  // 'data-graph': 'chart-simple',
  'switch': 'toggle-on',
  'upload': 'upload',
  'input': 'input-pipe',
  'choice': 'ballot-check',
  'divider': 'horizontal-rule',
  'checklist': 'square-check',
  'integration': 'gears',
  'dropdown': 'caret-down',
};

export const mapTypeToTitle: Partial<Record<DataModuleEnum, string>> = {
  'heading': 'Heading configuration',
  'text-box': 'Text configuration',
  // 'data-graph': 'Data graph configuration',
  'switch': 'Switch configuration',
  'upload': 'Upload document configuration',
  'input': 'Input Field configuration ',
  'choice': 'Choice configuration',
  'checklist': 'Checklist configuration',
  'dropdown': 'Dropdown configuration',
  'integration': 'Integration configuration',
};

export const campaignPathCheckpoints = [
  'campaigns',
  'topics',
  'subtopics',
  'subsubtopics',
];

export const generateCampaignItemCrumbs = (
  names: string[] = [],
  ids: string[] = [],
  id: string,
  name: string
) => {
  let path = '';

  const namesTemp = [...names, name];
  const idsTemp = [...ids, id];

  const rootCrumb = {
    text: 'Campaigns',
    href: `/${campaignPathCheckpoints[0]}`,
    id: 'campaigns-link',
  };

  return [
    rootCrumb,
    ...namesTemp.map((name, index) => {
      path = `${path}/${campaignPathCheckpoints[index]}/${idsTemp[index]}`;

      return {
        text: name,
        href: path,
        id: idsTemp[index],
      };
    }),
  ];
};

export const prepareCampaignFormObject = (
  modules: ModuleConfig<ModuleConfigType, DataModuleEnum>[]
) => ({
  form: {
    elements: modules.map((el, index) => ({
      index,
      id: el.id,
      type: el.type,
      title: el.title,
      ...(el.config && { config: el.config }),
    })),
  },
});
