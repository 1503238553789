import styled from 'styled-components';

import { BaseLayout } from 'layouts';
import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';

export const StyledUsers = styled(BaseLayout)`
  .esg-users {
    &__container {
      padding: ${theme.sizes.SIZE_32};
      width: 100%;
      height: fit-content;
      overflow: auto;

      &__cards {
        ${flex('column')};
        margin-top: ${theme.sizes.SIZE_48};
        gap: ${theme.sizes.SIZE_20};
        max-width: 50%;
      }
    }
  }
`;
