import useSWR from 'swr';
import { Organisation } from '../../models';

//Get one root organisation - Toyota
const useGetOrganisations = () => {
  const { data: organisation, ...rest } =
    useSWR<Organisation>(`/organisations`);

  return {
    organisation,
    ...rest,
  };
};

export { useGetOrganisations };
