function findNode<T extends Record<string, any>>(
  id: string,
  nodes: T[]
): T | undefined {
  for (const node of nodes) {
    if (node?.id === id) return node;
    else if (node.children?.length) {
      const founded = findNode(id, node.children) as T;
      if (founded) return founded;
    }
  }

  return undefined;
}

function findFile<T extends Record<string, any>>(nodes: T[]): T | undefined {
  if (!nodes) return;
  for (const node of nodes) {
    if (node?.children?.length === 0) return node;
    else if (node.children?.length) {
      const founded = findFile(node.children) as T;
      if (founded) return founded;
    }
  }

  return undefined;
}

export { findNode, findFile };
