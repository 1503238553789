import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';

import {
  BaseCampaign,
  CampaignItem,
  TreeNodeElement,
  SessionTree,
} from 'models';
import { findFile, findNode } from 'utils';
import { useGetCampaignSessionTree } from 'api';

import TasksContext from './Tasks.context';
import { useEffectOnceWhen } from '@faxi/web-component-library';

type TasksProviderProps = PropsWithChildren;

const mapSessionsToTreeNode = (
  {
    id,
    name,
    description,
    created,
    modified,
    children,
    companySessionId,
  }: BaseCampaign & { companySessionId: string },
  path: string,
  navigate: NavigateFunction,
  sessionId?: string
): TreeNodeElement & { path: string } => {
  return {
    id: sessionId && !children?.length ? `${sessionId}_${id}` : id,
    name,
    description,
    created,
    modified,
    path: '',
    iconName: 'file',
    children: children?.map((child: any) =>
      mapSessionsToTreeNode(
        child,
        path,
        navigate,
        companySessionId ?? sessionId
      )
    ),
    ...(!children?.length && {
      to: `${path}/company-session/${sessionId}/data-collection/${id}`,
    }),
  };
};

const TasksProvider: FC<TasksProviderProps> = (props) => {
  const { children } = props;

  const navigate = useNavigate();

  const params = useParams<{
    taskId: string;
    companyId: string;
    collectionId: string;
  }>();

  const [tree, setTree] = useState<TreeNodeElement>();

  const mapSessions = useCallback(
    (data: SessionTree) => {
      const tree = {
        id: data.company.id,
        name: data.company.name,
        description: data.company.description,
        children: data.children.map((child) => ({
          id: child.company.id,
          name: child.company.name,
          companySessionId: child.id,
          description: child.company.description,
          children: child.campaign.children,
        })),
      };

      setTree(
        mapSessionsToTreeNode(tree as any, `/tasks/${params.taskId}`, navigate)
      );
    },
    [params, navigate]
  );

  const { isLoading: loadingTree, data: session } = useGetCampaignSessionTree(
    params.taskId
  );

  const activeCampaignId = useMemo(
    () => session?.children[0]?.campaign?.id,
    [session]
  );

  const activeReportNode = useMemo(
    () =>
      params && tree
        ? findNode<CampaignItem>(
            `${params.companyId}_${params.collectionId}`,
            Array(tree) as any
          )
        : undefined,
    [params, tree]
  );

  // //navigate to first report file form
  useEffectOnceWhen(() => {
    const reportFile = findFile(Array(tree) as TreeNodeElement[]);
    if (reportFile) navigate(reportFile.to as string, { replace: true });
  }, !!tree && !params.collectionId);

  useEffect(() => {
    if (!session) return;

    mapSessions(session);
  }, [mapSessions, session]);

  return (
    <TasksContext.Provider
      value={{
        tree,
        session,
        activeReportNode,
        activeCampaignId,
        loading: loadingTree,
        setTree,
      }}
    >
      {children}
    </TasksContext.Provider>
  );
};

export default TasksProvider;
