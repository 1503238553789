import { Button, Divider, useUtilities } from '@faxi/web-component-library';
import {
  FieldArray,
  FieldArrayItem,
  FormField,
  useFormContextValues,
  validators,
} from '@faxi/web-form';
import { SelectField } from 'components';
import Icon from 'components/Icon';
import InlineEditableField from 'components/_fields/InlineEditableField';
import { ChoiceDataModule } from 'models';
import { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { generateUniqueId } from 'utils/generateUID';
import { SelectFieldProps } from '../../../../components/_fields/SelectField/SelectField.component';
import { ChoiceModuleConfig } from '../../../../models/DataModule';
import { SimpleInputField } from '../InputFieldConfiguration/components/SimpleInput/SimpleInput.component';
import { StyledChoiceFieldConfiguration } from './ChoiceFieldConfiguration.styled';

export type ChoiceFieldConfigurationProps = PropsWithChildren<ChoiceDataModule>;

const validations = {
  label: [validators.general.required('Label is required')],
  options: [validators.general.requiredArray('Required at least one')],
};

const ChoiceFieldConfiguration: FC<ChoiceFieldConfigurationProps> = (props) => {
  const { options } =
    (useFormContextValues('options') as {
      options: { label: string }[];
    }) || {};

  const baseClass = 'esg-choice-field-configuration';

  const { showSnackBar } = useUtilities();

  const selectDefaultValueOptions = useMemo(
    () =>
      options
        ? options
            ?.filter((el) => !!el?.label)
            ?.map((el) => ({ ...el, value: el?.label }))
        : [],
    [options]
  );

  const handleNoSave = useCallback(
    (index: number, remove: (index: number) => void) =>
      (text: string | undefined) => {
        if (!text) remove(index);
        else
          showSnackBar({
            text: `Can't create duplicate labels.`,
            variant: 'error',
            actionButtonText: 'Dismiss',
          });
      },
    [showSnackBar]
  );

  const shouldSave = useCallback(
    (fields: FieldArrayItem[]) => (text: string) => {
      if (!text) return false;

      return fields?.filter(({ value }) => text === value?.label).length <= 1;
    },
    []
  );

  return (
    <StyledChoiceFieldConfiguration className={baseClass}>
      <FieldArray name="options" validate={validations.options}>
        {({ fields, add, remove }) => (
          <div className={`${baseClass}__fields`}>
            {fields?.map(({ name, value }, index) => (
              <div
                className={`${baseClass}__fields__field`}
                key={`${value?.key}__${index}`}
              >
                <div className={`${baseClass}__fields__field__radio`} />
                <FormField
                  name={`${name}.label`}
                  component={InlineEditableField}
                  validate={validations.label}
                  placeholderText="Click here to edit label"
                  handleNoSave={handleNoSave(index, remove)}
                  shouldSave={shouldSave(fields)}
                />
                {fields?.length > 1 && (
                  <Button variant="ghost" onClick={() => remove(index)}>
                    Remove choice
                  </Button>
                )}
              </div>
            ))}
            <Button
              variant="ghost"
              icon={<Icon name="plus" />}
              iconPosition="left"
              className="esg-ghost-button-padded"
              onClick={() => add({ key: generateUniqueId() })}
            >
              Add option
            </Button>
          </div>
        )}
      </FieldArray>

      <Divider />

      <p>Here you can set default selected option</p>

      <SimpleInputField<ChoiceModuleConfig, SelectFieldProps>
        switchLabel="Default selected option"
        configKey="defaultValue"
        placeholder="Default value"
        renderAsPortal
        component={SelectField}
        options={selectDefaultValueOptions}
        config={props.config as ChoiceModuleConfig}
      />
    </StyledChoiceFieldConfiguration>
  );
};

export default ChoiceFieldConfiguration;
