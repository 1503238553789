import { theme } from '@faxi/web-component-library';
import { flex, flexGap } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledOrganisation = styled.div`
  ${flex('column')};
  ${flexGap(theme.sizes.SIZE_24, 'column')};

  flex: 1;

  .esg-organisation {
  }
`;

export { StyledOrganisation };
