import { GlowScroll, ModalRef } from '@faxi/web-component-library';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { BuilderCanvas, EntityFormModal } from 'components';
import { BlockUI } from 'helpers';
import { CampaignItem } from 'models';
import useGetGampaignItem from '../../../../api/hooks/useGetCampaignItem';
import { campaignItemTypeSelectOptions } from '../../constants/campaignItemType';
import { useCampaignItem } from '../../context/CampaignItem';
import useCampaignFormEntitySubmit from '../../hooks/useCampaignFormEntitySubmit';
import { generateCampaignItemCrumbs } from '../../utils';
import CampaignsHeader from '../CampaignsHeader';

import { StyledSubSubTopic } from './SubSubTopic.styled';

const SubSubTopic: FC = () => {
  const { subsubtopic = '' } = useParams<{
    topic: string;
    campaign: string;
    subtopic: string;
    subsubtopic: string;
  }>();

  const {
    setCampaignItem,
    campaignItem: activeCampaignItem,
    removeActiveCampaignItem,
  } = useCampaignItem();

  const modalRef = useRef<ModalRef>(null);

  const { campaignItem, isLoading, mutate } = useGetGampaignItem(subsubtopic);

  const { mutating, submitForm } = useCampaignFormEntitySubmit(
    subsubtopic,
    mutate,
    campaignItem
  );

  const openEditModal = useCallback(
    (item: CampaignItem) => {
      setCampaignItem(item);
      modalRef?.current?.open();
    },
    [setCampaignItem]
  );

  useEffect(() => {
    setCampaignItem(campaignItem);

    return () => {
      setCampaignItem(undefined);
    };
  }, [campaignItem, setCampaignItem]);

  const {
    path: { names = [], ids = [] } = {},
    name = '',
    id = '',
  } = campaignItem || {};

  const crumbs = useMemo(() => {
    return generateCampaignItemCrumbs(names, ids, id, name);
  }, [id, ids, name, names]);

  return (
    <GlowScroll variant="gray">
      <StyledSubSubTopic className="esg-sub-sub-topic">
        <BlockUI loading={isLoading}>
          {campaignItem && (
            <CampaignsHeader
              crumbs={crumbs}
              title={name}
              description={campaignItem.description}
              updateDescription={() => openEditModal(campaignItem)}
            />
          )}
          <BuilderCanvas />
          <EntityFormModal
            ref={modalRef}
            title={`Edit ${name}`}
            loading={mutating}
            onSubmit={submitForm}
            initialData={activeCampaignItem}
            onClose={removeActiveCampaignItem}
            fieldProps={{
              type: {
                options: campaignItemTypeSelectOptions,
              },
            }}
            fieldsConfiguration={{
              TYPE: true,
              NAME: true,
              DESCRIPTION: true,
              EMAIL: false,
              ROLE: false,
            }}
          />
        </BlockUI>
      </StyledSubSubTopic>
    </GlowScroll>
  );
};

export default SubSubTopic;
