import { FC } from 'react';
import { StyledBuilderTools } from './BuilderTools.styled';

import classNames from 'classnames';
import DataModulesTool from './components/DataModulesTool';
import PreviewTool from './components/PreviewTool';

const BuilderTools: FC<{ className?: string }> = ({ className }) => {
  return (
    <StyledBuilderTools className={classNames('esg-builder-tools', className)}>
      <DataModulesTool />
      <div className="esg-builder-tools__divider" />
      <PreviewTool />
    </StyledBuilderTools>
  );
};

export default BuilderTools;
