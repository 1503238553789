import { FC, createContext, useContext, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useSessionsData } from 'store/SessionsData';
import { SessionSidebar } from './components';
import { SessionData } from 'models';
import { StyledSessionLayout } from './SessionLayout.styled';
import { SessionsPageParams } from 'models/Session';
import { useGetSessionsProgress } from 'api';
import {
  convertProgressApiToProgress,
  mockProgressAndApproval,
} from '../../utils';

const SelectedSessionContext = createContext<SessionData | null>(null);

export const useSelectedSession = () => useContext(SelectedSessionContext);

const SessionLayout: FC = () => {
  const { sessionId } = useParams<SessionsPageParams>();
  const { sessions } = useSessionsData();
  const { data: progressArray } = useGetSessionsProgress();

  const selectedSession = useMemo(() => {
    const selectedSessionProgress = progressArray?.find(
      (progress) => progress?.id === sessionId
    );

    const session = sessions.find((session) => session.id === sessionId);
    const convertedProgress = convertProgressApiToProgress(
      selectedSessionProgress
    );

    return {
      ...session,
      progress: convertedProgress,
      approval: mockProgressAndApproval(),
    };
  }, [progressArray, sessions, sessionId]);

  if (!sessionId || !selectedSession) return <></>;

  return (
    <StyledSessionLayout className="esg-sessions-layout">
      <SelectedSessionContext.Provider value={selectedSession as SessionData}>
        <SessionSidebar />
        <div className="esg-sessions-layout__content">
          <Outlet />
        </div>
      </SelectedSessionContext.Provider>
    </StyledSessionLayout>
  );
};

export default SessionLayout;
