import { FC, PropsWithChildren, useCallback, useRef, useState } from 'react';

import {
  ButtonProps,
  MenuRef,
  SelectOption,
} from '@faxi/web-component-library';

import { Each } from 'helpers';
import { SelectField } from 'components';
import { DEFAULT_CONTRIBUTORS } from './data';
import Icon from 'components/Icon';
import {
  filterByName,
  getContributorsOptions,
  updateAdminByName,
} from './utils';
import AssignedContributor from '../AssignedContributor';

import { StyledAssignContributors } from './AssignContributors.styled';

export type AssignContributorsProps = PropsWithChildren<{}>;
export type Contributor = {
  name: string;
  src: string;
  isAdmin?: boolean;
};

const AssignContributors: FC<AssignContributorsProps> = () => {
  const [selectedContributors, setSelectedContributors] =
    useState<Contributor[]>(DEFAULT_CONTRIBUTORS);
  const menuRef = useRef<MenuRef>(null);

  const onSelectContributor = useCallback(
    (option: string | SelectOption<string>) => {
      const selectedContributor = DEFAULT_CONTRIBUTORS.find(
        ({ name }) => name === option
      );

      if (selectedContributor)
        setSelectedContributors((prev) => [...prev, selectedContributor]);
    },
    []
  );

  const nodeMenuItems = useCallback(
    (name: string, isAdmin?: boolean) =>
      [
        {
          children: isAdmin ? 'Remove admin' : 'Make admin',
          icon: <Icon color="var(--BLUE-06)" name="crown" />,
          variant: isAdmin ? 'delete-ghost' : 'ghost',
          onClick: () => {
            setSelectedContributors(updateAdminByName(name, isAdmin));
          },
        },
        {
          children: 'Remove',
          icon: <Icon color="var(--RED-EB)" name="trash-can" />,
          variant: 'delete-ghost',
          onClick: () => {
            setSelectedContributors(filterByName(name));
          },
        },
      ] as ButtonProps[],
    []
  );

  return (
    <StyledAssignContributors className="esg-assign-contributors">
      <div className="esg-assign-contributors__header">
        <Icon name="users-light" />
        <strong>Contributors</strong>
      </div>

      <div className="esg-assign-contributors__input">
        <SelectField
          name="assign"
          options={getContributorsOptions(selectedContributors)}
          searchable
          onChange={onSelectContributor}
        />
      </div>

      <div className="esg-assign-contributors__assignees">
        <small className="esg-assign-contributors__assignees__subtitle">
          Assignees
        </small>

        <Each
          of={selectedContributors}
          render={({ name, isAdmin = false, src }) => (
            <AssignedContributor
              key={name}
              src={src}
              name={name}
              isAdmin={isAdmin}
              menuRef={menuRef}
              menuItems={nodeMenuItems(name, isAdmin)}
              onClick={() => {}}
            />
          )}
        />
      </div>
    </StyledAssignContributors>
  );
};

export default AssignContributors;
