const APP_URI = {
  BASE: '/',

  AUTH: 'auth',
  LOGIN: 'login',
  SIGN_UP: 'signup',
  FORGOT_PASSWORD: 'forgot-password',

  ACCOUNT_SETTINGS: '/account-settings',

  DASHBOARD: '/dashboard',

  CAMPAIGNS: '/campaigns',
  CAMPAIGNS_CAMPAIGN: '/campaigns/:campaign',
  CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC: '/campaigns/:campaign/topics/:topic',
  CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC:
    '/campaigns/:campaign/topics/:topic/subtopics/:subtopic',
  CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC:
    '/campaigns/:campaign/topics/:topic/subtopics/:subtopic/subsubtopics/:subsubtopic',

  ORGANISATIONS: '/organisations',
  ORGANISATIONS_ORGANISATION: '/organisations/:organisation',
  ORGANISATIONS_ORGANISATION_COMPANY:
    '/organisations/:organisation/companies/:company',

  SESSIONS: '/sessions',
  SESSIONS_NAME: ':sessionId',
  SESSIONS_COMPANY: ':company',

  SESSION_RUN: 'run',
  SESSION_DASHBOARD: 'dash',
  SESSION_ACTIVITY: 'activity',
  SESSION_MEMBERS: 'members',
  SESSION_REPORT: 'report',
  SESSION_REPORT_COMPANY: 'companies/:company',
  SESSION_REPORT_COMPANY_TOPICS_TOPIC: 'companies/:company/topics/:topic',
  SESSION_REPORT_COMPANY_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC:
    'companies/:company/topics/:topic/subtopics/:subtopic',
  SESSION_REPORT_COMPANY_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC:
    'companies/:company/topics/:topic/subtopics/:subtopic/subsubtopics/:subsubtopic',
  USERS: '/users',
  ADMIN: '/admin',

  TASKS: '/tasks',
  TASKS_TASK: '/tasks/:taskId',
  TASKS_TASK_DATA_COLLECTION:
    '/tasks/:taskId/company-session/:companyId/data-collection/:collectionId',
};

export { APP_URI };
