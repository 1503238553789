import { DataModuleEnum } from '../../../../../../../models';

export const dataModulesTextMapper: Record<DataModuleEnum, string> = {
  'heading': 'Heading',
  'text-box': 'Text box',
  // 'data-graph': 'Data / Graph',
  'switch': 'Switch',
  'upload': 'Upload',
  'input': 'Input',
  'choice': 'Choice',
  'integration': 'Integration',
  'checklist': 'Checklist',
  'divider': 'Divider',
  'dropdown': 'Dropdown',
};
