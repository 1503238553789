import { useMemo } from 'react';
import { validationRegexes, validators } from '@faxi/web-form';

import { equalField } from 'validators';

export default function useValidations() {
  const validations = useMemo(
    () => ({
      email: [
        validators.general.required('Email is required'),
        validators.general.regex(
          validationRegexes.workEmail,
          'Please enter a valid email address'
        ),
      ],
      password: [validators.general.required('Please enter your password')],
      repeatPassword: [
        validators.general.required('Please confirm your password'),
        equalField('Password must match', 'password'),
      ],
      name: [
        validators.general.required('Name is required'),
        validators.general.regex(
          /^[^#\\/]*$/,
          'Characters # \\ / are not allowed.'
        ),
        validators.general.regex(
          /^\s+$/,
          'You may not enter only space characters.',
          true
        ),
      ],
      description: [
        validators.general.maxLength(
          150,
          'Description can only have a maximum of 150 characters.'
        ),
        validators.general.regex(
          /^\s+$/,
          'You may not enter only space characters.',
          true
        ),
      ],
      type: validators.general.required('Type is required'),
      role: validators.general.required('Role is required'),
    }),
    []
  );

  return { validations };
}
