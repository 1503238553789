import { INameExtended } from '../components/Icon';
import { ObjectValues } from '../types';
import { BaseModelExtended } from './BaseModel';
import { CampaignItem } from './Campaign';
import { Company, CompanyChild } from './Company';
import { Organisation } from './Organisation';

export type SessionAPI = {
  name: 'string';
  companyId: 'string';
  campaignId: 'string';
  selectedElementsIds: ['string'];
};

export type Session = BaseModelExtended & {
  progress: number;
  approval: number;
  started: boolean;
  organisation: Organisation;
  // ONCE ADDED IN A TASK, ORGANISATION COMPANIES MAY OR MAY NOT BE INCLUDED, MAP BY ID
  companies?: {
    company: Company;
    includedMap: Record<number, boolean>;
  }[];
};

export const FORM_STATUS = {
  COMPLETED: 'Completed',
  IN_PROGRESS: 'In Progress',
  IN_REVIEW: 'In Review',
} as const;

export type FormStatus = ObjectValues<typeof FORM_STATUS>;

export const FORM_APPROVAL = {
  TO_REVIEW: 'To Review',
  APPROVED: 'Approved',
} as const;

export type FormApproval = ObjectValues<typeof FORM_APPROVAL>;
export type ApprovalStatus = FormStatus | FormApproval;

export type ProgressData = {
  currentStep: number;
  numberOfSteps: number;
  status: ApprovalStatus;
};

export type SessionProgress = {
  completedElements: number;
  id: string;
  progress: number;
  sessionDescription: string;
  sessionName: string;
  totalElements: number;
  companyName: string;
};

export type SessionData = {
  id: string;
  status: CampaignSessionStatus;
  name: string;
  description: string;
  approval: ProgressData;
  progress: ProgressData;
  deadline: string;
  startedAt: string;
};

export enum CampaignSessionStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export type CampaignSessionTreeNode = {
  id: string;
  organisationSessionId: string;
  name: string;
  companySessionId?: string;
  status: CampaignSessionStatus;
  deadline?: string;
  description: string;
  path: {
    ids: string[];
    names: string[];
  };
  company: Company;
  campaign?: CampaignItem;
  parent?: {
    id: string;
    name: string;
  };
  iconName?: INameExtended;
  children?: CampaignSessionTreeNode[];
};

export type SessionsPageParams = { sessionId: string };

export type SessionTree = BaseModelExtended & {
  campaign: CampaignItem;
  children: SessionTree[];
  company: CompanyChild;
  status: CampaignSessionStatus;
  organisationSessionId: string;
};
