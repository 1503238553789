import useSWR, { BareFetcher, SWRConfiguration, SWRResponse } from 'swr';

import { APP_URI } from 'config';
import { OrganisationAPI } from 'models';

/**
 * This hook supports dependent queries where the new query relies on the 
 * organisation data. 
 * 
 * Example usage: 
 *  const { data, isLoading, error } = api.useOrganisationsSWR(
 *    (organisationsId: string) => `/campaign-sessions/${organisationsId}/tree`
    );
 */
const useOrganisationsSWR = <TData = any, TError = Error>(
  generateKey: (organisationId: string) => string | boolean,
  config?: SWRConfiguration<TData, TError, BareFetcher<TData>>,
  shouldFetch = true
): SWRResponse<TData, TError> => {
  const {
    isLoading: organisationsLoading,
    error: organisationsError,
    data: organisations,
  } = useSWR<OrganisationAPI>(shouldFetch && APP_URI.ORGANISATIONS);

  const { isLoading, error, ...rest } = useSWR<TData, TError>(
    () => organisations?.id && generateKey(organisations.id),
    config
  );

  return {
    isLoading: organisationsLoading || isLoading,
    error: organisationsError || error,
    ...rest,
  };
};

export default useOrganisationsSWR;
