import { FC, useMemo } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import {
  SidebarItem,
  Sidebar as WCLSidebar,
} from '@faxi/web-component-library';
import { SidebarItemsConfig } from '@faxi/web-component-library/build/components/_organisms/Sidebar/Sidebar.component';

import { APP_URI } from 'config';
import { UserRole } from 'models';
import { AnimatedLogo } from 'components/_atoms';
import { INameExtended } from 'components/Icon';
import {
  globalLogout,
  rolePermission,
} from '../../routes/Router/Router.component';
import routes, { ExtendRouteObject } from '../../routes/Routes';
import storageService from '../../services/storageService';

const SIDEBAR_ARIA_LABELS = {
  ariaDeleteLabel: 'Delete Label',
  ariaCloseMenu: 'Collapse sidebar',
  ariaOpenMenu: 'Expand sidebar',
  ariaExpand: 'Expand sidebar',
  ariaCollapse: 'Collapse sidebar',
  ariaLogoLabel: 'ESG Navigator logo',
};

const DEFAULT_SIDEBAR_PROPS = {
  searchPlaceholder: '',
  noResultsLabel: '',
  logoutText: 'Log out',
  topItemsTitle: '',
  AnimatedLogoComponent: AnimatedLogo,
  initialExpanded: false,
};

/**
 * Function will check main routes list to find matching roles for
 * passed route path. This function is used for filtering main sidebar items.
 * @param {string} Path route path
 */
const checkUserRouteRole = (path?: string, routes?: ExtendRouteObject[]) => {
  if (!routes) return;

  const route = routes
    .find((route) => route.path === APP_URI.BASE)
    ?.children?.find((route) => route.path === path) as ExtendRouteObject;

  return !route?.roles
    ? false
    : !rolePermission(
        storageService.getItem('ROLE'),
        route.roles as UserRole[]
      );
};

const SIDEBAR_MAIN_ITEMS: SidebarItem<INameExtended>[] = [
  {
    id: '1',
    name: 'Sessions',
    url: APP_URI.SESSIONS,
    iconName: 'chart-bar',
  },
  {
    id: '2',
    name: 'Organisations',
    url: APP_URI.ORGANISATIONS,
    iconName: 'shield',
  },
  {
    id: '3',
    name: 'Campaigns',
    url: APP_URI.CAMPAIGNS,
    iconName: 'leaf',
  },
  {
    id: '4',
    name: 'Users',
    url: APP_URI.USERS,
    iconName: 'users',
  },
  {
    id: '5',
    name: 'Tasks',
    url: APP_URI.TASKS,
    iconName: 'bars-progress',
  },
];

const SIDEBAR_BOTTOM_ITEMS: SidebarItem<INameExtended>[] = [
  {
    name: 'Account settings',
    url: APP_URI.ACCOUNT_SETTINGS,
    id: 'account-settings',
  },
];

export type SidebarProps = { className?: string };

const Sidebar: FC<SidebarProps> = ({ className }) => {
  const navigate = useNavigate();

  const finalSidebarRoutes = useMemo(
    () =>
      Array<SidebarItemsConfig<string>>({
        items: SIDEBAR_MAIN_ITEMS.filter(
          (item) => !checkUserRouteRole(item.url, routes)
        ),
      }),
    []
  );

  return (
    <WCLSidebar
      className={classNames('esg-sidebar', className)}
      logoUrl="/assets/svg/logo.svg"
      logoAlt="ESG Navigator logo"
      ariaLabels={SIDEBAR_ARIA_LABELS}
      mainItems={finalSidebarRoutes}
      bottomItems={SIDEBAR_BOTTOM_ITEMS}
      logoTo={APP_URI.BASE}
      user={{ name: 'Testing User', image_url: '/assets/images/avatar.png' }}
      onClickLogout={async () => {
        await globalLogout();
        navigate('/auth');
      }}
      {...DEFAULT_SIDEBAR_PROPS}
    />
  );
};

export default Sidebar;
