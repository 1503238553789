import { createContext } from 'react';
import { CampaignItem } from 'models';

export type CampaignItemContextProps = {
  mutating?: boolean;
  campaignItem?: CampaignItem;
  removeActiveCampaignItem?: () => void;
  setCampaignItem: (data?: CampaignItem) => void;
  editCampaignItem?: (data: Partial<CampaignItem>) => Promise<void>;
};

export default createContext<CampaignItemContextProps>({
  setCampaignItem: () => {},
});
