import { Input } from '@faxi/web-component-library';
import { FC, useCallback, useMemo, useState } from 'react';

import Icon from 'components/Icon';
import { DEFAULT_TREE_NODE_DATA } from 'mock/treeViewData';
import { TreeNodeElement } from 'models';

import CheckboxTreeView from '../CheckboxTreeView';

import classNames from 'classnames';
import { StyledSearchTreeView } from './SearchTreeView.styled';
import cloneDeep from 'lodash.clonedeep';

/**
 * This approach allows each TreeView to maintain its own independent state.
 */
export type SearchTreeViewProps = {
  data?: TreeNodeElement | null;
  className?: string;
  noCheck?: boolean;
};

/**
 * A search-enabled tree view component that allows filtering of nodes based on a search query.
 */
const SearchTreeView: FC<SearchTreeViewProps> = ({
  data = DEFAULT_TREE_NODE_DATA,
  className,
  noCheck,
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  const [tree, setTree] = useState(data);

  const filterTree = useCallback(
    (node: TreeNodeElement | null, query: string): TreeNodeElement | null => {
      if (!node) return null;

      const isNodeMatchingQuery = node.name
        ?.toLowerCase()
        .includes(query.toLowerCase());

      if (isNodeMatchingQuery) return node;

      if (node.children) {
        const filteredChildren = node.children
          .map((child) => filterTree(child, query))
          .filter((child) => child !== null) as TreeNodeElement[];

        if (filteredChildren.length > 0)
          return { ...node, children: filteredChildren };
      }

      return null;
    },
    []
  );

  const filteredData = useMemo(
    () => (searchQuery ? filterTree(tree, searchQuery) : tree),
    [filterTree, searchQuery, tree]
  );

  return (
    <StyledSearchTreeView
      className={classNames('esg-search-tree-view', className)}
    >
      <Input
        type="text"
        prefixIcon={<Icon name="magnifying-glass" />}
        value={searchQuery}
        onChange={setSearchQuery}
        placeholder="Search..."
      />
      {filteredData ? (
        <CheckboxTreeView
          filteredData={filteredData}
          onCheck={noCheck ? undefined : () => setTree(cloneDeep(tree))}
        />
      ) : (
        <small>No matches found</small>
      )}
    </StyledSearchTreeView>
  );
};

export default SearchTreeView;
