import {
  Button,
  GlowScroll,
  ModalRef,
  useModalUtilities,
  useUtilities,
} from '@faxi/web-component-library';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { EntityFormModal, SectionCard } from 'components';
import Icon from 'components/Icon';

import { User, UserRole } from '../../models';
import storageService from '../../services/storageService';
import { generateUniqueId } from '../../utils/generateUID';
import { StyledUsers } from './Users.styled';

export type NewSessionForm = {
  name: string;
  description: string;
};

const Users: FC = () => {
  const { open, openModal, closeModal } = useModalUtilities();

  const { prompts, showOverlay, hideOverlay, showSnackBar } = useUtilities();

  const [users, setUsers] = useState<User[]>();
  const [selectedUser, setSelectedUser] = useState<User>();

  const modalRef = useRef<ModalRef>(null);

  const onSubmit = useCallback(
    async (data: Record<string, string>) => {
      const usersParsed = JSON.parse(
        storageService.getItem('USERS_MOCK')
      ) as User[];

      showOverlay('.esg-users');

      if (
        !selectedUser &&
        usersParsed.find(({ email }) => email === data.email)
      ) {
        showSnackBar({
          text: 'A user with the desired email already exists.',
          actionButtonText: 'Dismiss',
        });
      } else {
        const [first_name, last_name] = data.name.split(' ');

        if (selectedUser) {
          const user = usersParsed.find((user) => user.id === selectedUser.id);

          if (user) {
            user.first_name = first_name;
            user.last_name = last_name;
            user.email = data.email;
            user.role = data.role as UserRole;
          }
        } else {
          usersParsed.push({
            first_name,
            last_name: last_name || '',
            email: data.email,
            role: data.role as UserRole,
            id: generateUniqueId(),
          });
        }

        storageService.setItem('USERS_MOCK', JSON.stringify(usersParsed));

        setUsers(usersParsed);

        showSnackBar({
          text: selectedUser
            ? `Successfully updated ${selectedUser.first_name} ${selectedUser.last_name}.`
            : `Successfully created ${first_name} ${last_name || ''}.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
      }

      setTimeout(() => {
        hideOverlay('.esg-users');
        const container = document.getElementsByClassName(
          'esg-users__container'
        )[0];

        container.scrollTo({ top: container.scrollHeight, behavior: 'smooth' });
      }, 1000);

      modalRef?.current?.close();
      setSelectedUser(undefined);
    },
    [hideOverlay, selectedUser, showOverlay, showSnackBar]
  );

  useEffect(() => {
    const usersParsed = JSON.parse(storageService.getItem('USERS_MOCK'));

    setUsers(usersParsed);
  }, []);

  return (
    <StyledUsers
      title="Users"
      direction="column"
      className="esg-users"
      padding={0}
    >
      <GlowScroll variant="gray">
        <div className="esg-users__container">
          <Button
            icon={<Icon name="plus" />}
            variant="outline"
            onClick={openModal}
          >
            Create a new user
          </Button>

          {open && (
            <EntityFormModal
              ref={modalRef}
              renderAsPortal
              fieldProps={{
                role: {
                  options: [
                    {
                      value: 'user',
                      label: 'User',
                    },
                    {
                      value: 'admin',
                      label: 'Admin',
                    },
                    {
                      value: 'super-admin',
                      label: 'Super admin',
                    },
                  ],
                },
              }}
              fieldsConfiguration={{
                NAME: true,
                EMAIL: true,
                DESCRIPTION: false,
                TYPE: false,
                ROLE: true,
              }}
              title={
                selectedUser
                  ? `Edit ${selectedUser.first_name} ${selectedUser.last_name}`
                  : 'New user'
              }
              initialData={
                selectedUser
                  ? {
                      name: `${selectedUser.first_name} ${selectedUser.last_name}`,
                      email: selectedUser.email,
                      role: selectedUser.role,
                    }
                  : undefined
              }
              conditionallyControlled
              onClose={() => {
                closeModal();
                setSelectedUser(undefined);
              }}
              onSubmit={onSubmit}
            />
          )}

          <div className="esg-users__container__cards">
            {users?.map((user) => (
              <SectionCard
                key={user.id}
                title={`${user.first_name} ${user.last_name} [${user.role}]`}
                description={user.email}
                prefixElement={<Icon name="user" />}
                hasActions={false}
                withMenu
                onEdit={() => {
                  setSelectedUser(user);
                  openModal();
                }}
                onDelete={async (e) =>
                  await prompts.delete({
                    type: 'delete',
                    submitBtnText: 'Delete',
                    cancelBtnText: 'Do not delete',
                    title: `Delete ${user.first_name} ${user.last_name}`,
                    content: `Are you sure you want to delete ${user.first_name} ${user.last_name} from users?`,
                    btnIcon: 'trash-can',
                    iconPosition: 'left',
                    submitBtnVariant: 'delete-ghost',
                    titleIcon: <Icon name="triangle-exclamation" />,
                    triggerRef: e.target as HTMLButtonElement,
                    onSubmit: async () => {
                      showOverlay('.esg-users');

                      const usersParsed = JSON.parse(
                        storageService.getItem('USERS_MOCK')
                      ) as User[];

                      const usersFiltered = usersParsed.filter(
                        (u) => u.id !== user.id
                      );

                      storageService.setItem(
                        'USERS_MOCK',
                        JSON.stringify(usersFiltered)
                      );

                      setUsers(usersFiltered);

                      setTimeout(() => {
                        hideOverlay('.esg-users');
                      }, 1000);
                    },
                  })
                }
              />
            ))}
          </div>
        </div>
      </GlowScroll>
    </StyledUsers>
  );
};

export default Users;
