import { Location } from 'react-router-dom';

import { CheckStatus, TreeNodeElement } from 'models';

export const sessionPathCheckpoints = [
  '',
  'companies',
  'topics',
  'subtopics',
  'subsubtopics',
  'reports',
];

type SessionAPINode = {
  id: string;
  name: string;
  children?: SessionAPINode[];
  companySessionId: string;
  type?: string;
};

type SessionAPINodeExtended = {
  iconName: string;
  onClick: () => void;
  path: string;
};

export type SessionTreeNodeElement = SessionAPINode &
  TreeNodeElement &
  Partial<SessionAPINodeExtended>;

export const mapAPISessionToTreeNode = (
  node: SessionTreeNodeElement,
  path: string,
  location: Location,
  depth = 0,
  parent?: SessionTreeNodeElement | null
): SessionTreeNodeElement => {
  const newPath =
    depth === 0 ? path : `${path}/${sessionPathCheckpoints[depth]}/${node.id}`;

  const newDepth = depth + 1;

  let children = undefined;

  if (depth < 4 && node.children)
    children = node.children.map((c) => {
      return mapAPISessionToTreeNode(c, newPath, location, newDepth, node);
    });

  node.children = children;
  node.iconName = depth < 4 ? 'folder' : 'file';
  node.to = newPath;
  node.checked = CheckStatus.Unchecked;
  node.parent = parent;

  return node;
};
