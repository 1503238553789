import { FC } from 'react';
import { Breadcrumbs } from '@faxi/web-component-library';
import { Breadcrumb } from '@faxi/web-component-library/build/components/_molecules/Breadcrumbs/Breadcrumbs.component';

import Icon from 'components/Icon';
import { FolderDescription } from 'components';

import { StyledCampaignsHeader } from './CampaignsHeader.styled';

export type CampaignsHeaderProps = {
  crumbs: Breadcrumb[];
  title: string;
  updateDescription?: () => void;
  description?: string;
};

const CampaignsHeader: FC<CampaignsHeaderProps> = (props) => {
  const { crumbs, title, description, updateDescription } = props;

  return (
    <StyledCampaignsHeader className="esg-campaigns-header">
      <Breadcrumbs
        className="esg-campaigns-header__breadcrumbs"
        crumbs={crumbs}
        splitter="/"
      />

      <div className="esg-campaigns-header__header">
        <Icon name="folder" />
        <h2>{title}</h2>
      </div>

      <FolderDescription
        description={description}
        onChange={updateDescription}
      />
    </StyledCampaignsHeader>
  );
};

export default CampaignsHeader;
