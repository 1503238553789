import {
  CheckboxProps,
  RadioGroupOption,
  SelectOption,
} from '@faxi/web-component-library';

import { Dayjs } from 'dayjs';
import { BaseModel } from './BaseModel';

export enum DataModuleEnum {
  HEADING = 'heading',
  TEXT_BOX = 'text-box',
  // DATA_GRAPH = 'data-graph',
  SWITCH = 'switch',
  UPLOAD = 'upload',
  INPUT = 'input',
  CHOICE = 'choice',
  CHECKLIST = 'checklist',
  DIVIDER = 'divider',
  INTEGRATION = 'integration',
  DROPDOWN = 'dropdown',
}

export interface IDataModule<T = DataModuleEnum> extends BaseModel {
  type: T;
  title: string;
  index: number;
  campaignId?: string;
  companyId?: string;
  elementId?: string;
  dataCollectionFormId?: string;
  sessionId?: string;
  topic?: string;
}

export type ModuleConfig<T, S> = IDataModule<S> & {
  config?: T;
  value?: any;
};

export type CanvasModuleType = Omit<IDataModule, 'created_by'>;

//non-configurable
export type DividerDataModule = IDataModule<DataModuleEnum.DIVIDER>;

//non-configurable
export type HeadingDataModule = IDataModule<DataModuleEnum.HEADING>;

//non-configurable
export type TextDataModule = IDataModule<DataModuleEnum.TEXT_BOX>;

//non-configurable
export type SwitchDataModule = IDataModule<DataModuleEnum.SWITCH>;

//non-configurable
export type UploadDataModule = IDataModule<DataModuleEnum.UPLOAD> & {
  file?: File;
};

// //non-configurable
// export type DataGraphDataModule = IDataModule<DataModuleEnum.DATA_GRAPH> & {
//   title?: string;
// };

/* ------ */

export enum InputFieldTypes {
  SIMPLE_FIELD = 'simple-field',
  NUMERIC_FIELD = 'numeric-field',
  EMAIL_FIELD = 'email-field',
  DATE_FIELD = 'date-field',
}

export type InputModuleConfig = {
  type: InputFieldTypes;
  unit?: string;
  helperText?: string;
  placeholder?: string;
  minDate?: Dayjs | string;
  maxDate?: Dayjs | string;
};

//configurable
export type InputDataModule = ModuleConfig<
  InputModuleConfig,
  DataModuleEnum.INPUT
>;

/* ------ */

export type ChoiceModuleConfig = {
  defaultValue?: string;
  options: Array<Pick<RadioGroupOption, 'label' | 'value'>>;
};

//configurable
export type ChoiceDataModule = ModuleConfig<
  ChoiceModuleConfig,
  DataModuleEnum.CHOICE
>;

/* ------ */

export type CheckListModuleConfig = {
  options: Array<Pick<CheckboxProps, 'label' | 'value'>>;
  defaultValues: Array<Pick<CheckboxProps, 'label' | 'value'>>;
};

//configurable
export type CheckListDataModule = ModuleConfig<
  CheckListModuleConfig,
  DataModuleEnum.CHECKLIST
>;

/* ------ */

export type DropdownModuleConfig = {
  other?: boolean;
  placeholder?: string;
  helperText?: string;
  defaultValue?: string;
  options: Array<SelectOption<string>>;
};

//configurable
export type DropdownDataModule = ModuleConfig<
  DropdownModuleConfig,
  DataModuleEnum.DROPDOWN
>;

/* ------ */

export type IntegrationTypes = 'kinto' | 'jira' | 'slack';

export type IntegrationModuleConfig = {
  value: string;
  integration: IntegrationTypes;
};

//configurable
export type IntegrationDataModule = ModuleConfig<
  IntegrationModuleConfig,
  DataModuleEnum.INTEGRATION
>;

/* ------ */

export type ModuleConfigType =
  | InputModuleConfig
  | DropdownModuleConfig
  | ChoiceModuleConfig
  | CheckListModuleConfig
  | IntegrationModuleConfig;

export type DataCollectionForm = {
  campaignId: string;
  dataCollectionFormId: string;
  elements: Pick<
    ModuleConfig<ModuleConfigType, DataModuleEnum>,
    'elementId' | 'value' | 'type'
  >[];
};
