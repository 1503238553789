import {
  elevate_l,
  elevate_xxl,
  focusStyles,
  theme,
} from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledCanvasModule = styled.div`
  ${elevate_l};
  ${flex('row', 'flex-start', 'center')};
  flex-wrap: wrap;
  padding: ${theme.sizes.SIZE_10} ${theme.sizes.SIZE_16};
  border-radius: ${theme.sizes.SIZE_16};
  gap: ${theme.sizes.SIZE_20};
  background-color: var(--BACKGROUND_1_1);
  cursor: grab;
  touch-action: none;
  ${focusStyles};

  .wcl-dropdown__content {
    width: ${theme.sizes.SIZE_248};
    padding-left: ${theme.sizes.SIZE_12};
  }

  .esg-canvas-module {
    &__grip {
      cursor: grab;
      padding: ${theme.sizes.SIZE_8} !important;
      width: ${theme.sizes.SIZE_32};

      svg {
        color: var(--GRAY-C1);
      }
    }

    &__type {
      width: ${theme.sizes.SIZE_46};
      background-color: var(--GRAY-EF);
      padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_12};
      border-radius: ${theme.sizes.SIZE_8};
    }

    &__text {
      color: var(--GRAY-98);
    }

    &__menu {
      margin-left: auto;
    }
  }

  &.esg-canvas-module {
    &--is-dragging {
      cursor: grabbing;
    }

    &:hover {
      ${elevate_xxl};
    }
  }
`;

export { StyledCanvasModule };
