import { theme } from '@faxi/web-component-library';
import { fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledHeadingPreview = styled.div`
  ${fontSize(theme.fontSizes.FONT_18)};
  font-weight: 600;
`;

export { StyledHeadingPreview };
