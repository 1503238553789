import { DataModuleEnum, IDataModule } from 'models';
import HeadingCanvasModule from '../components/HeadingCanvasModule';
import { FC } from 'react';
import TextCanvasModule from '../components/TextCanvasModule';
import DividerCanvasModule from '../components/DividerCanvasModule';
import InputCanvasModule from '../components/InputCanvasModule';
// import DataGraphCanvasModule from '../components/DataGraphCanvasModule';
import SwitchCanvasModule from '../components/SwitchCanvasModule';
import UploadCanvasModule from '../components/UploadCanvasModule';
import ChoiceCanvasModule from '../components/ChoiceCanvasModule';
import ChecklistCanvasModule from '../components/ChecklistCanvasModule';
import IntegrationCanvasModule from '../components/IntegrationCanvasModule';
import DropdownCanvasModule from '../components/DropdownCanvasModule';
import { InlineEditableProps } from '../../../_molecules/InlineEditable/InlineEditable.component';

export type ModuleMapperType = {
  [K in DataModuleEnum]: FC<
    IDataModule<K> & Pick<InlineEditableProps, 'onSave'>
  >;
};

export const moduleMapper: ModuleMapperType = {
  [DataModuleEnum.HEADING]: HeadingCanvasModule,
  [DataModuleEnum.TEXT_BOX]: TextCanvasModule,
  // [DataModuleEnum.DATA_GRAPH]: DataGraphCanvasModule,
  [DataModuleEnum.SWITCH]: SwitchCanvasModule,
  [DataModuleEnum.UPLOAD]: UploadCanvasModule,
  [DataModuleEnum.INPUT]: InputCanvasModule,
  [DataModuleEnum.CHOICE]: ChoiceCanvasModule,
  [DataModuleEnum.CHECKLIST]: ChecklistCanvasModule,
  [DataModuleEnum.DIVIDER]: DividerCanvasModule,
  [DataModuleEnum.INTEGRATION]: IntegrationCanvasModule,
  [DataModuleEnum.DROPDOWN]: DropdownCanvasModule,
};
