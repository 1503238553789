import { FC, useState } from 'react';
import { Button, useModalUtilities } from '@faxi/web-component-library';
import { useNavigate } from 'react-router-dom';

import Icon from 'components/Icon';
import CompanyModal from '../../modals/CompanyModal';
import FoldersPreview from 'components/_molecules/FoldersPreview';
import { useOrganisationProvider } from '../../providers/Organisation';
import { Organisation as OrganisationType, TreeNodeElement } from 'models';

import { StyledOrganisation } from './Organisation.styled';

const Organisation: FC = () => {
  const navigate = useNavigate();

  const { activeOrganisation, mutateOrganisations, deleteOrganisation } =
    useOrganisationProvider();

  const [activeCompany, setActiveCompany] = useState<TreeNodeElement>();

  const { open, triggerRef, openModal, closeModal } = useModalUtilities();
  const buttonTrigger = triggerRef.current as HTMLButtonElement;

  return (
    <StyledOrganisation className="esg-organisation">
      <Button
        className="esg-organisation__create-btn"
        variant="outline"
        iconPosition="left"
        icon={<Icon name="plus" />}
        onClick={openModal}
      >
        Add company
      </Button>

      <FoldersPreview
        folder={activeOrganisation}
        onSelect={(f) => navigate(`companies/${f.id}`)}
        onEdit={(c) => {
          setActiveCompany(c);
          openModal();
        }}
        onDelete={(org, e) =>
          deleteOrganisation(
            org as OrganisationType,
            e.target as HTMLButtonElement
          )
        }
      />

      {open && (
        <CompanyModal<OrganisationType>
          renderAsPortal
          initialData={activeCompany}
          conditionallyControlled
          triggerRef={buttonTrigger}
          mutator={mutateOrganisations}
          onClose={() => {
            closeModal();
            setActiveCompany(undefined);
          }}
          fieldsConfiguration={{
            NAME: true,
            DESCRIPTION: true,
            EMAIL: false,
            ROLE: false,
            TYPE: false,
          }}
        />
      )}
    </StyledOrganisation>
  );
};

export default Organisation;
