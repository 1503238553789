import { FC, PropsWithChildren, useState } from 'react';

import { DataModuleEnum, IDataModule } from '../../../../../models';
import {
  ModuleMapperType,
  moduleMapper as defaultModuleMapper,
} from '../../constants/formBuilderMapper';
import { InlineEditableProps } from '../../../../_molecules/InlineEditable/InlineEditable.component';

export type InlineModuleElement<T> = T &
  Pick<InlineEditableProps, 'value' | 'onSave' | 'onChange' | 'className'>;

export type ModuleElementProps<T extends DataModuleEnum> = PropsWithChildren<{
  type: T;
  className?: string;
  module: IDataModule<T>;
  moduleMapper?: ModuleMapperType;
  onTitleSave?: (module: IDataModule<T>) => void;
}>;

const ModuleElement: FC<ModuleElementProps<DataModuleEnum>> = (props) => {
  const {
    className,
    type,
    module,
    moduleMapper = defaultModuleMapper,
    onTitleSave,
  } = props;

  const Module = moduleMapper[type] as FC<
    InlineModuleElement<IDataModule<typeof type>>
  >;

  const [value, setValue] = useState(module.title);

  return (
    <Module
      {...module}
      value={value}
      onChange={setValue}
      className={className}
      onSave={(title?: string) => {
        onTitleSave?.({ ...module, title: title as string });
      }}
    />
  );
};

export default ModuleElement;
