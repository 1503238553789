import { theme } from '@faxi/web-component-library';
import styled from 'styled-components';

const StyledIntegrationFieldConfiguration = styled.div`
  .wcl-select {
    max-width: ${theme.sizes.SIZE_240};
  }

  .esg-integration-field-configuration {
  }
`;

export { StyledIntegrationFieldConfiguration };
