import { Navigate, RouteObject } from 'react-router-dom';

import { APP_URI } from '../../../config';
import NoData from '../../../styles/NoData';
import TasksLayout from './layouts/TasksLayout';
import { DataCollection, Tasks } from './pages';

export default [
  {
    path: '',
    element: <Tasks />,
  },
  {
    path: '',
    element: <TasksLayout />,
    children: [
      {
        path: APP_URI.TASKS_TASK,
        element: <NoData>No Attached Company Campaigns.</NoData>,
      },
      {
        path: APP_URI.TASKS_TASK_DATA_COLLECTION,
        element: <DataCollection />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="" />,
  },
] as RouteObject[];
