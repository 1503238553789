import { FC } from 'react';

import { InputField } from 'components';

import { ExtractComponentProps } from 'types';
import { InputPreviewProps } from '../../InputPreview.component';

export type NumericFieldProps = ExtractComponentProps<typeof InputField> &
  InputPreviewProps;

const NumericField: FC<NumericFieldProps> = (props) => {
  const { config } = props;

  return (
    <InputField
      unit={config?.unit}
      placeholder={config?.placeholder}
      {...props}
    />
  );
};

export default NumericField;
