import Icon from 'components/Icon';

export const campaignItemTypeSelectOptions = [
  {
    value: 'data_collection_element',
    label: 'Data collection',
    icon: <Icon color="#0066D5" name="magnifying-glass-chart" />,
  },
  // {
  //   value: 'Report',
  //   label: 'Report',
  //   icon: <Icon color="#A35501" name="chart-simple" />,
  // },
];
