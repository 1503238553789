export const ORGANISATION_PATH_CHECKPOINTS = ['organisations', 'companies'];

export const generateOrganisationItemCrumbs = (
  names = [] as string[],
  ids = [] as string[],
  id: string,
  name: string
) => {
  let path = '';

  const namesTemp = [...names, name];
  const idsTemp = [...ids, id];

  const rootCrumb = {
    text: 'Organisations',
    href: `/${ORGANISATION_PATH_CHECKPOINTS[0]}`,
    id: 'campaigns-link',
  };

  return [
    rootCrumb,
    ...namesTemp.map((name, index) => {
      path = `${path}/${ORGANISATION_PATH_CHECKPOINTS[index]}/${idsTemp[index]}`;

      return {
        text: name,
        href: path,
        id: idsTemp[index],
      };
    }),
  ];
};
